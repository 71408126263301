@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,300,400,700);

/* You can add global styles to this file, and also import other style files */
.cdk-overlay-container,.cdk-global-overlay-wrapper{pointer-events:none;top:0;left:0;height:100%;width:100%}
.cdk-overlay-container{position:fixed;z-index:1000}
.cdk-overlay-container:empty{display:none}
.cdk-global-overlay-wrapper{display:flex;position:absolute;z-index:1000}
.cdk-overlay-pane{position:absolute;pointer-events:auto;box-sizing:border-box;z-index:1000;display:flex;max-width:100%;max-height:100%}
.cdk-overlay-backdrop{position:absolute;top:0;bottom:0;left:0;right:0;z-index:1000;pointer-events:auto;-webkit-tap-highlight-color:transparent;transition:opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);opacity:0}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{opacity:1}
@media screen and (-ms-high-contrast: active){.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{opacity:0.6}}
.cdk-overlay-dark-backdrop{background:rgba(0,0,0,0.288)}
.cdk-overlay-transparent-backdrop,.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing{opacity:0}
.cdk-overlay-connected-position-bounding-box{position:absolute;z-index:1000;display:flex;flex-direction:column;min-width:1px;min-height:1px}
.cdk-global-scrollblock{position:fixed;width:100%;overflow-y:scroll}
.owl-dialog-container{position:relative;pointer-events:auto;box-sizing:border-box;display:block;padding:1.5em;box-shadow:0 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);border-radius:2px;overflow:auto;background:#fff;color:rgba(0,0,0,0.87);width:100%;height:100%;outline:none}
.owl-dt-container,.owl-dt-container *{box-sizing:border-box}
.owl-dt-container{display:block;font-size:1rem;background:#ffffff;pointer-events:auto;z-index:1000}
.owl-dt-container-row{border-bottom:1px solid rgba(0,0,0,0.12)}
.owl-dt-container-row:last-child{border-bottom:none}
.owl-dt-calendar{display:flex;flex-direction:column;width:100%}
.owl-dt-calendar-control{display:flex;align-items:center;font-size:1em;width:100%;padding:.5em;color:#000000}
.owl-dt-calendar-control .owl-dt-calendar-control-content{flex:1 1 auto;display:flex;justify-content:center;align-items:center}
.owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button{padding:0 .8em}
.owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button:hover{background-color:rgba(0,0,0,0.12)}
.owl-dt-calendar-main{display:flex;flex-direction:column;flex:1 1 auto;padding:0 .5em .5em;outline:0}
.owl-dt-calendar-view{display:block;flex:1 1 auto}
.owl-dt-calendar-multi-year-view{display:flex;align-items:center}
.owl-dt-calendar-multi-year-view .owl-dt-calendar-table{width:calc(100% - 3em)}
.owl-dt-calendar-multi-year-view .owl-dt-calendar-table .owl-dt-calendar-header th{padding-bottom:.25em}
.owl-dt-calendar-table{width:100%;border-collapse:collapse;border-spacing:0}
.owl-dt-calendar-table .owl-dt-calendar-header{color:rgba(0,0,0,0.4)}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th{font-size:.7em;font-weight:400;text-align:center;padding-bottom:1em}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider{position:relative;height:1px;padding-bottom:.5em}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider:after{content:'';position:absolute;top:0;left:-.5em;right:-.5em;height:1px;background:rgba(0,0,0,0.12)}
.owl-dt-calendar-table .owl-dt-calendar-cell{position:relative;height:0;line-height:0;text-align:center;outline:0;color:rgba(0,0,0,0.85);-webkit-appearance:none;-webkit-tap-highlight-color:rgba(0,0,0,0);-webkit-tap-highlight-color:transparent}
.owl-dt-calendar-table .owl-dt-calendar-cell-content{position:absolute;top:5%;left:5%;display:flex;align-items:center;justify-content:center;box-sizing:border-box;width:90%;height:90%;font-size:.8em;line-height:1;border:1px solid transparent;border-radius:999px;color:inherit;cursor:pointer}
.owl-dt-calendar-table .owl-dt-calendar-cell-out{opacity:.2}
.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected){border-color:rgba(0,0,0,0.4)}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected{color:rgba(255,255,255,0.85);background-color:#3f51b5}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today{box-shadow:inset 0 0 0 1px rgba(255,255,255,0.85)}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled{cursor:default}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected){color:rgba(0,0,0,0.4)}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected{opacity:.4}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected){border-color:rgba(0,0,0,0.2)}
.owl-dt-calendar-table .owl-dt-calendar-cell-active:focus>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),.owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected){background-color:rgba(0,0,0,0.04)}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range{background:rgba(63,81,181,0.2)}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-from{border-top-left-radius:999px;border-bottom-left-radius:999px}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-to{border-top-right-radius:999px;border-bottom-right-radius:999px}
.owl-dt-timer{display:flex;justify-content:center;width:100%;height:7em;padding:.5em;outline:none}
.owl-dt-timer-box{position:relative;display:inline-flex;flex-direction:column;align-items:center;width:25%;height:100%}
.owl-dt-timer-content{flex:1 1 auto;display:flex;justify-content:center;align-items:center;width:100%;margin:.2em 0}
.owl-dt-timer-content .owl-dt-timer-input{display:block;width:2em;text-align:center;border:1px solid rgba(0,0,0,0.5);border-radius:3px;outline:medium none;font-size:1.2em;padding:.2em}
.owl-dt-timer-divider{display:inline-block;align-self:flex-end;position:absolute;width:.6em;height:100%;left:-.3em}
.owl-dt-timer-divider:before,.owl-dt-timer-divider:after{content:'';display:inline-block;width:.35em;height:.35em;position:absolute;left:50%;border-radius:50%;transform:translateX(-50%);background-color:currentColor}
.owl-dt-timer-divider:before{top:35%}
.owl-dt-timer-divider:after{bottom:35%}
.owl-dt-control-button{-webkit-user-select:none;-moz-user-select:none;user-select:none;cursor:pointer;outline:none;border:none;-webkit-tap-highlight-color:transparent;display:inline-block;white-space:nowrap;text-decoration:none;vertical-align:baseline;margin:0;padding:0;background-color:transparent;font-size:1em;color:inherit}
.owl-dt-control-button .owl-dt-control-button-content{position:relative;display:inline-flex;justify-content:center;align-items:center;outline:none}
.owl-dt-control-period-button .owl-dt-control-button-content{height:1.5em;padding:0 .5em;border-radius:3px;transition:background-color 100ms linear}
.owl-dt-control-period-button:hover>.owl-dt-control-button-content{background-color:rgba(0,0,0,0.12)}
.owl-dt-control-period-button .owl-dt-control-button-arrow{display:flex;justify-content:center;align-items:center;width:1em;height:1em;margin:.1em;transition:transform 200ms ease}
.owl-dt-control-arrow-button .owl-dt-control-button-content{padding:0;border-radius:50%;width:1.5em;height:1.5em}
.owl-dt-control-arrow-button[disabled]{color:rgba(0,0,0,0.4);cursor:default}
.owl-dt-control-arrow-button svg{width:50%;height:50%;fill:currentColor}
.owl-dt-inline-container,.owl-dt-popup-container{position:relative;width:18.5em;box-shadow:0 5px 5px -3px rgba(0,0,0,0.2),0 8px 10px 1px rgba(0,0,0,0.14),0 3px 14px 2px rgba(0,0,0,0.12)}
.owl-dt-inline-container .owl-dt-calendar,.owl-dt-inline-container .owl-dt-timer,.owl-dt-popup-container .owl-dt-calendar,.owl-dt-popup-container .owl-dt-timer{width:100%}
.owl-dt-inline-container .owl-dt-calendar,.owl-dt-popup-container .owl-dt-calendar{height:20.25em}
.owl-dt-dialog-container{max-height:95vh;margin:-1.5em}
.owl-dt-dialog-container .owl-dt-calendar{min-width:250px;min-height:330px;max-width:750px;max-height:750px}
.owl-dt-dialog-container .owl-dt-timer{min-width:250px;max-width:750px}
@media all and (orientation: landscape){.owl-dt-dialog-container .owl-dt-calendar{width:58vh;height:62vh}.owl-dt-dialog-container .owl-dt-timer{width:58vh}}
@media all and (orientation: portrait){.owl-dt-dialog-container .owl-dt-calendar{width:80vw;height:80vw}.owl-dt-dialog-container .owl-dt-timer{width:80vw}}
.owl-dt-container-buttons{display:flex;width:100%;height:2em;color:#3f51b5}
.owl-dt-container-control-button{font-size:1em;width:50%;height:100%;border-radius:0}
.owl-dt-container-control-button .owl-dt-control-button-content{height:100%;width:100%;transition:background-color 100ms linear}
.owl-dt-container-control-button:hover .owl-dt-control-button-content{background-color:rgba(0,0,0,0.1)}
.owl-dt-container-info{padding:0 .5em;cursor:pointer;-webkit-tap-highlight-color:transparent}
.owl-dt-container-info .owl-dt-container-range{outline:none}
.owl-dt-container-info .owl-dt-container-range .owl-dt-container-range-content{display:flex;justify-content:space-between;padding:.5em 0;font-size:.8em}
.owl-dt-container-info .owl-dt-container-range:last-child{border-top:1px solid rgba(0,0,0,0.12)}
.owl-dt-container-info .owl-dt-container-info-active{color:#3f51b5}
.owl-dt-container-disabled,.owl-dt-trigger-disabled{opacity:0.35;filter:Alpha(Opacity=35);background-image:none;cursor:default !important}
.owl-dt-timer-hour12{display:flex;justify-content:center;align-items:center;color:#3f51b5}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box{border:1px solid currentColor;border-radius:2px;transition:background 200ms ease}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box .owl-dt-control-button-content{width:100%;height:100%;padding:.5em}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:focus .owl-dt-control-button-content,.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:hover .owl-dt-control-button-content{background:#3f51b5;color:#ffffff}
.owl-dt-calendar-only-current-month .owl-dt-calendar-cell-out{visibility:hidden;cursor:default}
.owl-dt-inline{display:inline-block}
.owl-dt-control{outline:none;cursor:pointer}
.owl-dt-control .owl-dt-control-content{outline:none}
.owl-dt-control:focus>.owl-dt-control-content{background-color:rgba(0,0,0,0.12)}
.owl-dt-control:not(:-moz-focusring):focus>.owl-dt-control-content{box-shadow:none}
.owl-hidden-accessible{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}
.ng-select.ng-select-opened>.ng-select-container{background:#fff;border-color:#b3b3b3 #ccc #d9d9d9}
.ng-select.ng-select-opened>.ng-select-container:hover{box-shadow:none}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow{top:-2px;border-color:transparent transparent #999;border-width:0 5px 5px}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover{border-color:transparent transparent #333}
.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container{border-bottom-right-radius:0;border-bottom-left-radius:0}
.ng-select.ng-select-opened.ng-select-top>.ng-select-container{border-top-right-radius:0;border-top-left-radius:0}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{border-color:#007eff;box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 0 3px rgba(0,126,255,0.1)}
.ng-select.ng-select-disabled>.ng-select-container{background-color:#f9f9f9}
.ng-select .ng-has-value .ng-placeholder{display:none}
.ng-select .ng-select-container{color:#333;background-color:#fff;border-radius:4px;border:1px solid #ccc;min-height:36px;align-items:center}
.ng-select .ng-select-container:hover{box-shadow:0 1px 0 rgba(0,0,0,0.06)}
.ng-select .ng-select-container .ng-value-container{align-items:center;padding-left:10px}
[dir="rtl"] .ng-select .ng-select-container .ng-value-container{padding-right:10px;padding-left:0}
.ng-select .ng-select-container .ng-value-container .ng-placeholder{color:#999}
.ng-select.ng-select-single .ng-select-container{height:36px}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{top:5px;left:0;padding-left:10px;padding-right:50px}
[dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{padding-right:10px;padding-left:50px}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value{background-color:#f9f9f9;border:1px solid #e6e6e6}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label{padding:0 5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container{padding-top:5px;padding-left:7px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container{padding-right:7px;padding-left:0}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{font-size:.9em;margin-bottom:5px;background-color:#ebf5ff;border-radius:2px;margin-right:5px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{margin-right:0;margin-left:5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled{background-color:#f9f9f9}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label{padding-left:5px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label{padding-left:0;padding-right:5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label{display:inline-block;padding:1px 5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon{display:inline-block;padding:1px 5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover{background-color:#d1e8ff}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{border-right:1px solid #b8dbff}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{border-left:1px solid #b8dbff;border-right:none}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right{border-left:1px solid #b8dbff}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right{border-left:0;border-right:1px solid #b8dbff}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{padding:0 0 3px 3px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{padding:0 3px 3px 0}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{top:5px;padding-bottom:5px;padding-left:3px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{padding-right:3px;padding-left:0}
.ng-select .ng-clear-wrapper{color:#999}
.ng-select .ng-clear-wrapper:hover .ng-clear{color:#D0021B}
.ng-select .ng-spinner-zone{padding:5px 5px 0 0}
[dir="rtl"] .ng-select .ng-spinner-zone{padding:5px 0 0 5px}
.ng-select .ng-arrow-wrapper{width:25px;padding-right:5px}
[dir="rtl"] .ng-select .ng-arrow-wrapper{padding-left:5px;padding-right:0}
.ng-select .ng-arrow-wrapper:hover .ng-arrow{border-top-color:#666}
.ng-select .ng-arrow-wrapper .ng-arrow{border-color:#999 transparent transparent;border-style:solid;border-width:5px 5px 2.5px}
.ng-dropdown-panel{background-color:#fff;border:1px solid #ccc;box-shadow:0 1px 0 rgba(0,0,0,0.06);left:0}
.ng-dropdown-panel.ng-select-bottom{top:100%;border-bottom-right-radius:4px;border-bottom-left-radius:4px;border-top-color:#e6e6e6;margin-top:-1px}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child{border-bottom-right-radius:4px;border-bottom-left-radius:4px}
.ng-dropdown-panel.ng-select-top{bottom:100%;border-top-right-radius:4px;border-top-left-radius:4px;border-bottom-color:#e6e6e6;margin-bottom:-1px}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child{border-top-right-radius:4px;border-top-left-radius:4px}
.ng-dropdown-panel .ng-dropdown-header{border-bottom:1px solid #ccc;padding:5px 7px}
.ng-dropdown-panel .ng-dropdown-footer{border-top:1px solid #ccc;padding:5px 7px}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup{-webkit-user-select:none;-moz-user-select:none;user-select:none;padding:8px 10px;font-weight:500;color:rgba(0,0,0,0.54);cursor:pointer}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled{cursor:default}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked{background-color:#f5faff}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked{background-color:#ebf5ff;font-weight:600}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{background-color:#fff;color:rgba(0,0,0,0.87);padding:8px 10px}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked{color:#333;background-color:#ebf5ff}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label{font-weight:600}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{background-color:#f5faff;color:#333}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled{color:#ccc}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{padding-left:22px}
[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{padding-right:22px;padding-left:0}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{font-size:80%;font-weight:400;padding-right:5px}
[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{padding-left:5px;padding-right:0}
[dir="rtl"] .ng-dropdown-panel{direction:rtl;text-align:right}
:root {
  --theme-color-1: #8B9635;
  --header-color:rgba(183, 235, 0, 0.4);
  --logo-color:transparent;
  --hovertext-color: #ffffff;
  --text-color: #111;
  --info-color: #8B9635;
  --filter: brightness(0) saturate(100%) ;
}
/* #GOOGLE FONT */
body:not(.menu-on-top).desktop-detected {
  min-height: auto !important;
  overflow: hidden;
}
body {
  background: unset;
  background-color: #202124;
}
#content {
  padding: 20px !important;
}
.page-footer {
  /* padding-left: 0px; */
  z-index: 100;
  border-top: none;
  position: fixed;
}
#logo {
  background-repeat: no-repeat;
  background-size: 100%;
  height: 49px;
  width: 135px;
  display: block;
  margin-top: 10px;
  margin-left: 36px;
}
.setnavBackgroundImg {
  position: relative;
  z-index: 2;
  max-width: none;
  width: 90px;
  height: 90px;
  cursor: pointer;
  background-repeat: no-repeat;
  display: flex;
  margin: 0 auto;
  height: 110px;
  width: 110px;
}
.col-xs-10 {
  width: 83.33333333% !important;
}
.col-xs-2 {
  width: 16.66666667% !important;
}
.col-xs-3 {
  width: 25% !important;
}
.col-xs-7 {
  width: 58.33333333% !important;
}
.col-xs-8 {
  width: 66.66666667% !important;
}
.col-xs-6 {
  width: 50% !important;
}
.col-xs-1 {
  width: 8.33333333% !important;
}
.col-xs-4 {
  width: 33.33333333% !important;
}
.col-xs-5 {
  width: 41.66666667% !important;
}
#extr-page #header #logo {
  margin-left: 75px !important;
  margin-top: 10px !important;
}
#extr-page #header #logo img {
  width: 200px;
}
.notification-body .unread {
  background: #1E1E1E;
}
.notification-body li span {
  background: #0C1214 !important;
}
.li-footer__copy-text {
  color: #8B91A0;
}
.loader-outer {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}
.loader > span {
  width: 15px;
  height: 15px;
  display: block;
  background: var(--theme-color-1);
  border-radius: 50%;
  position: relative;
  margin: 0 5px;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dot-1 {
  -webkit-animation: anim 1s linear 0s infinite;
          animation: anim 1s linear 0s infinite;
}
.dot-2 {
  -webkit-animation: anim 1s linear 0.25s infinite;
          animation: anim 1s linear 0.25s infinite;
}
.dot-3 {
  -webkit-animation: anim 1s linear 0.5s infinite;
          animation: anim 1s linear 0.5s infinite;
}
.dot-4 {
  -webkit-animation: anim 1s linear 0.75s infinite;
          animation: anim 1s linear 0.75s infinite;
}
@-webkit-keyframes anim {
  0% {
    top: 0;
  }
  50% {
    top: 15px;
  }
  100% {
    top: 0;
  }
}
@keyframes anim {
  0% {
    top: 0;
  }
  50% {
    top: 15px;
  }
  100% {
    top: 0;
  }
}
.dataTables_info .select-item {
  padding: 5px;
}
@media (max-width: 1161px) and (min-width: 300px) {
  #extr-page #header #logo img {
    width: 150px;
  }

  #extr-page #header #logo {
    margin-left: 10px !important;
    margin-top: 20px !important;
  }
}
@media only screen and (max-width: 280px) {
  #header > :first-child,
aside {
    width: 150px;
  }

  #extr-page #header #logo img {
    width: 125px;
  }

  #extr-page #header #logo {
    margin-left: 10px !important;
    margin-top: 20px !important;
  }
}
@media (max-width: 992px) {
  #content {
    max-height: calc(100vh - 130px) !important;
  }
}
@media (max-width: 576px) {
  #content {
    max-height: calc(100vh - 180px) !important;
  }
}
.cdk-overlay-container {
  z-index: 9999 !important;
}
.owl-dt-inline {
  width: 100% !important;
}
.owl-dt-inline-container,
.owl-dt-popup-container {
  width: 100% !important;
}
.owl-dt-container {
  font-size: 18px !important;
}
.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
  height: 500px !important;
}
.cdk-overlay-container {
  z-index: 9999 !important;
}
.owl-dt-inline {
  width: 100% !important;
}
.owl-dt-inline-container,
.owl-dt-popup-container {
  width: 100% !important;
}
.owl-dt-container {
  font-size: 18px !important;
}
.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
  height: 500px !important;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-active:focus > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
.owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  background-color: #666666 !important;
  color: #fff !important;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  background-color: #202124 !important;
  border: 1px solid #666666 !important;
  color: #fff !important;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none !important;
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
  margin-top: 3px;
}
.checkbox i {
  position: absolute;
  top: 3px;
  left: 0;
  display: block;
  width: 17px;
  height: 17px;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  background: transparent !important;
  border: 2px solid #666666;
  border-radius: 5px;
}
.smart-form .checkbox input + i:after {
  color: var(--theme-color-1);
}
.smart-form .checkbox i {
  border-color: #BDBDBD;
  border: 2px solid #666666;
  border-radius: 5px;
}
.smart-form .checkbox:hover i {
  border-color: #666666;
}
.fuelux .wizard ul li.complete:hover {
  cursor: pointer;
  background: none;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--theme-color-1) !important;
  background-color: var(--theme-color-1) !important;
}
.fuelux .wizard ul li .chevron {
  position: absolute;
  top: 0;
  right: -14px;
  z-index: 1;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
}
.checkbox i {
  position: absolute;
  top: 3px;
  left: 0;
  display: block;
  width: 17px;
  height: 17px;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  background: transparent !important;
  border: 2px solid #666666;
  border-radius: 5px;
}
.smart-form .checkbox input + i:after {
  color: var(--theme-color-1);
}
.smart-form .checkbox i {
  border-color: #BDBDBD;
  border: 2px solid #666666;
  border-radius: 5px;
}
.smart-form .checkbox:hover i {
  border-color: #666666;
}
/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-1 #logo-group {
  background: unset !important;
}
.smart-style-1 #logo {
  background-image: url(/assets/img/svgImages/logo.svg);
}
.smart-style-1 .btn-header:first-child a {
  background: unset;
  border: 1px solid #3B424C;
}
.smart-style-1 #header {
  background-color: #171717;
}
.smart-style-1 aside {
  background-color: #171819;
  border-right: 1px solid #333 !important;
}
.smart-style-1 .page-footer {
  background-color: #303030;
}
.smart-style-1 #ribbon {
  background: #707070;
}
.smart-style-1 .ajax-dropdown {
  background-color: #0C1214 !important;
}
.smart-style-1 .ajax-notifications {
  background-color: #0C1214 !important;
}
.smart-style-1 .header-dropdown-list a:hover {
  color: #fff !important;
}
.smart-style-1 .setnavBackgroundImg {
  background-image: url(/assets/img/svgImages/Group348.svg);
}
.smart-style-1 .ng-select .ng-select-container {
  background: transparent;
  color: #fff;
  font-size: 13px;
  border-radius: 10px !important;
  border: unset !important;
}
.smart-style-1 .ng-select.ng-select-opened > .ng-select-container {
  background: transparent !important;
}
.smart-style-1 .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #202124 !important;
  color: #ffff;
  font-size: 13px;
  font-weight: none;
}
.smart-style-1 .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color: #ffff !important;
}
.smart-style-1 .ng-dropdown-panel.ng-select-bottom {
  border: 1px solid #666666 !important;
}
.smart-style-1 .smart-form .checkbox input:checked + i {
  border: 2px solid #666666;
  border-radius: 5px;
}
.smart-style-1 .fuelux .wizard ul li .chevron {
  border-left: 14px solid #3B424C;
}
.smart-style-1 .fuelux .wizard ul li.complete:hover .chevron:before {
  border-left: 14px solid #171717;
}
.smart-style-1 .smart-form .checkbox input:checked + i {
  border: 2px solid #666666;
  border-radius: 5px;
}
/*
---------------------------------------------------------------------------------------
    End Dark theme style
---------------------------------------------------------------------------------------
*/
/*
---------------------------------------------------------------------------------------
    Default White theme style
---------------------------------------------------------------------------------------
*/
.smart-style-2 #logo {
  margin-top: 10px;
}
.smart-style-2 .login-info {
  color: #212529;
}
.smart-style-2 aside {
  color: #fff !important;
  background: #ededed !important;
}
.smart-style-2 .alignscndRowcntntBorder {
  border-radius: 8px;
  border: 1px solid #dadce0;
  overflow: hidden;
}
.smart-style-2 nav ul .active > a {
  font-weight: 400;
}
.smart-style-2 .setnavBackgroundImg {
  background-image: url(/assets/img/svgImages/Group349.svg);
}
.smart-style-2 .ng-select .ng-select-container {
  background: transparent !important;
  border-radius: 10px !important;
  border: none;
}
.smart-style-2 .ng-select.ng-select-opened > .ng-select-container {
  background: transparent !important;
}
.smart-style-2 .companySelect .ng-select-container .ng-value-container .ng-value {
  font-size: 13px;
}
.smart-style-2 .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color: #0C1214 !important;
  background-color: #fff;
}
.smart-style-2 .smart-form .checkbox input:checked + i {
  border-color: #666666 !important;
}
.smart-style-2 .fuelux .wizard ul li .chevron {
  border-left: 14px solid #d4d4d4;
}
.smart-style-2 .smart-form .checkbox input:checked + i {
  border-color: #666666 !important;
}
/*
---------------------------------------------------------------------------------------
    White  theme style
---------------------------------------------------------------------------------------
*/
@charset "UTF-8";
:root {
  --theme-color-1: #8B9635;
  --header-color:rgba(183, 235, 0, 0.4);
  --logo-color:transparent;
  --hovertext-color: #ffffff;
  --text-color: #111;
  --info-color: #8B9635;
  --filter: brightness(0) saturate(100%) ;
}
#main {
  height: calc(100vh - 100px) !important;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  padding-bottom: 2px;
  /* Firefox */
}
table thead tr th {
  font-size: 14px;
  font-weight: 400;
}
#main::-webkit-scrollbar {
  display: none;
}
.headticket {
  opacity: 0.4 !important;
}
table {
  width: 100%;
  background-color: inherit !important;
  color: #fff;
  border-collapse: collapse;
}
.toggle-demo {
  position: absolute;
  top: 10px;
  right: -8px;
  display: block;
  width: 154px;
  height: auto;
  z-index: 99;
  opacity: 0.9;
}
.toggle-demo:hover {
  opacity: 1;
}
.toggle-demo .btn-group {
  width: 100px;
  float: left;
}
.toggle-demo span {
  display: inline-block;
  float: left;
  margin-right: 5px;
  line-height: 21px;
  color: #757575;
}
.demo-vertical-uislider {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.demo-vertical-uislider div {
  height: 200px;
  margin: 0 0 0 10px;
  display: inline-block;
}
.demo-vertical-uislider:first-child {
  margin-left: 15px;
}
.knobs-demo div {
  display: inline-block;
  margin-left: 30px;
}
.knobs-demo:first-child {
  margin-left: 0;
}
.demo {
  position: absolute;
  top: 5px;
  right: 0;
  width: 160px;
  z-index: 902;
  background: #F1DA91;
}
.demo label {
  margin-bottom: 4 !important;
}
.demo label input[type=checkbox].checkbox + span:before {
  border: 1px solid #928585;
}
.demo label input[type=radio].radiobox + span:before {
  border: 1px solid #5E5E5E;
}
.demo label:hover input[type=checkbox].checkbox + span:before {
  border-color: #E77317;
}
.demo .checkbox {
  padding-left: 22px;
}
.demo .checkbox i {
  border-color: #7A7A7A;
}
.demo .radio {
  padding-left: 22px;
}
.demo #demo-setting + form {
  display: none;
}
.demo span {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  height: 30px;
  width: 30px;
  border-radius: 5px 0 0 5px;
  background: #F1DA91;
  padding: 2px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
}
.demo span i {
  font-size: 17px;
}
.demo span:hover i {
  color: #333333 !important;
}
.demo form section {
  margin: 0 !important;
}
.demo .btn {
  font-size: 13px !important;
  line-height: 1.5 !important;
  border-radius: 3px !important;
  padding: 3px 7px !important;
  height: auto !important;
}
.demo.activate {
  right: 0 !important;
  box-shadow: -11px 12px 23px rgba(0, 0, 0, 0.2);
  padding: 5px 10px 10px;
}
.demo.activate #demo-setting + form {
  display: block;
}
.demo.activate span {
  left: -30px;
}
.demo.activate span i:before {
  content: "";
}
.demo-liveupdate-1 {
  font-size: 12px !important;
  position: absolute !important;
  left: 33px;
  top: 15px;
  display: block;
  z-index: 2;
  font-weight: 700;
  padding: 1px 61px 3px 7px;
}
.demo-btns {
  margin: 0;
  padding: 0;
  list-style: none;
}
.demo-btns li {
  display: inline-block;
  margin-bottom: 7px;
}
.demo-icon-font {
  font-size: 14px;
  margin-bottom: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.demo-icon-font:hover {
  background-color: rgba(86, 61, 124, 0.1);
  text-decoration: none;
}
.demo-modal {
  width: auto;
  max-width: 600px;
  z-index: 1;
}
.demo-red {
  color: #DF413E;
}
.demo-orange {
  color: #DAA03E;
}
.demo-border {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.nav-demo-btn {
  display: none;
}
body:not(.menu-on-top) .nav-demo-btn {
  display: block;
  padding: 6px 5px;
  margin: 5px 10px;
  width: auto;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  font-size: 12px;
  white-space: normal;
}
.fixed-navigation .nav-demo-btn {
  display: none !important;
}
.minified .nav-demo-btn,
.minified .ac_ConnectionSection {
  display: none !important;
}
.smart-rtl .demo .margin-right-5 {
  margin-right: 0 !important;
}
.applicationIcon {
  background-repeat: no-repeat;
  background-size: 100% 71%;
  top: 15px;
  position: relative;
}
.setArrowOrange {
  color: #E8833A !important;
}
.processtablLbl {
  background-color: var(--theme-color-1);
  color: #fff;
  border-radius: 15px;
  padding: 5px;
  font-size: 10px;
  display: block;
  text-align: center;
}
.inactiveLbl {
  background-color: #EA2027;
  color: #fff;
  border-radius: 15px;
  padding: 5px;
  font-size: 10px;
  display: block;
}
.activeLbl {
  background-color: #00B894;
  color: #fff;
  border-radius: 15px;
  padding: 5px;
  font-size: 10px;
}
.holdLbl {
  background-color: #535353;
  color: #fff;
  border-radius: 15px;
  padding: 5px;
  font-size: 10px;
  display: block;
}
.scroll-dashboard::-webkit-scrollbar {
  display: none;
}
.scroll-dashboard {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.scroll-content::-webkit-scrollbar {
  display: none;
}
.scroll-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
nav ul li a:hover {
  background: #707070 !important;
}
.jarviswidget > header h2 {
  font-size: 16px !important;
}
.jarviswidget-ctrls .button-icon {
  color: #000;
}
#header .menu-item {
  margin: 0px 0px 2px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  cursor: pointer;
}
#header .menu-item_pading {
  padding: 0 10px;
}
#header .menu-left-item {
  padding: 0 10px;
}
#header .company-title {
  font: normal normal bold 12px/19px Open Sans;
  text-transform: uppercase;
}
#header .company-name {
  font: normal normal normal 14px/19px Open Sans;
}
#header #fullscreen .svg-icon {
  background-repeat: no-repeat;
  background-position: center;
  width: 60px !important;
  height: 45px !important;
  margin-top: -3px;
}
#header .notification-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 60px !important;
  height: 45px !important;
}
#header #activity.activity-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 60px !important;
  height: 45px !important;
}
#header .collapseMenu .collaspe-menu-svg {
  background-repeat: no-repeat;
  background-position: center;
  height: 30px !important;
}
#header .collapseMenu .collaspe-menu-sub-svg {
  background-repeat: no-repeat;
  width: 33px !important;
  height: 45px !important;
}
#header .header-btn:hover {
  border-color: var(--theme-color-1) !important;
  background: var(--theme-color-1) !important;
}
#header .dropdown-icon-menu ul li .btn:hover {
  border-color: var(--theme-color-1) !important;
  background: var(--theme-color-1) !important;
}
.header-menu {
  font: normal normal normal 14px/19px Open Sans;
}
.notification-body > li {
  border: 1px solid #e3e3e3 !important;
}
.iom_btn {
  border-radius: 15px;
  background: transparent;
  border: 2px solid var(--theme-color-1);
  font-size: 13px;
  padding: 3px 15px;
  line-height: 20px;
  font-weight: 400;
}
.iom_btn:hover {
  border-color: var(--theme-color-1) !important;
  background: var(--theme-color-1) !important;
}
/*----------------- Header ----------------- */
.header-dropdown-list .dropdown-menu {
  padding: 4px;
  overflow: auto;
  max-height: 200px;
  border-radius: 10px;
}
.header-dropdown-list .dropdown-menu li a {
  border-radius: 0 !important;
  font-size: 14px;
}
.header-dropdown-list .dropdown-menu::-webkit-scrollbar {
  width: 0px;
}
.header-dropdown-list a.dropdown-toggle {
  color: #fff;
  margin-top: 6px;
}
.activity.ajax-dropdown:first-child {
  margin: 0;
  padding: 0;
}
.activity .ajax-notifications {
  border-bottom: none;
  border-top: none;
}
.scroll-dashboard {
  overflow-y: scroll !important;
  z-index: 1;
}
.scroll-dashboard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #171717;
}
.scroll-dashboard::-webkit-scrollbar {
  width: 0px;
}
.scroll-dashboard::-webkit-scrollbar-thumb {
  background-color: #F5F5F5;
}
.nav-tabs > li > a {
  border-radius: 0;
  color: #0C1214;
  font: normal normal bold 13px/18px Open Sans;
}
.scroll-tb {
  border: 0px solid #fff !important;
  border-top: transparent !important;
  height: 734px !important;
  overflow-y: scroll !important;
  margin-bottom: -9px !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scroll-tb::-webkit-scrollbar {
  display: none;
}
.scroll-tbs {
  height: 170px !important;
  overflow-y: auto !important;
  margin-top: 10px;
  margin-left: -3px;
}
.scroll-tbs::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
.scroll-tbs::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
}
.scroll-tbs::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}
.scroll-tbs-pdf {
  height: 170px !important;
  overflow-y: auto !important;
  margin-top: 10px;
  margin-left: -3px;
}
.scroll-tbs-pdf::-webkit-scrollbar {
  width: 0px !important;
  height: 5px !important;
}
.scroll-tbs-pdf::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
}
.scroll-tbs-pdf::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}
.dashHeadLocation {
  color: #fff !important;
  display: flex;
  list-style: none;
  padding: 0;
}
.listitems {
  font-size: 16px;
  color: #fff;
}
.fixed-head {
  top: 0;
  z-index: 100;
}
.table thead:first-child tr:first-child th {
  border-bottom: none !important;
}
.rightAlign {
  text-align: right;
}
.big-spin-loader {
  background: url('spin-loader.a01fb811724c25c2dd1e.svg') no-repeat;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 5% auto;
  transform: translate(0, -50%);
  top: 50%;
}
.big-spin-page-loader {
  background: url('spin-loader.a01fb811724c25c2dd1e.svg') no-repeat;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 5% auto;
  transform: translate(0, -50%);
  top: 50%;
  z-index: 100;
}
table.dataTable tbody tr.selected {
  background-color: var(--theme-color-1) !important;
  border-color: #CCCCCC;
  color: #000 !important;
}
table.dataTable tbody tr.selected:hover {
  background-color: var(--theme-color-1) !important;
  border-color: #CCCCCC;
}
tr.selected .btn-primary {
  color: #000 !important;
}
a.btn.disabled {
  cursor: not-allowed !important;
}
nav ul li.active a:before {
  right: -15px !important;
  content: none !important;
}
.minified #help_text {
  display: none;
}
.minified #help_text1 {
  display: none;
}
.minified #help_text_div {
  display: none;
}
.minified #help_text_btn {
  display: none;
}
.minified #clearCache_text,
.minified .module-name {
  display: none;
}
.minified #hide-menu {
  display: block;
}
.minified .footer-menu {
  padding: 12px 0px;
}
.minified .tutorial-popup {
  width: 43px !important;
  padding: 3px 12px;
}
.minified .module {
  padding: 5px 5px 5px 10px !important;
}
.minified .module .module-img {
  width: 26px !important;
}
.minified .chatIcon {
  margin-left: 8px;
}
.minified #clearCache .iom_btn {
  padding: 3px 13px !important;
}
.minified .ebill-icon {
  width: 26px !important;
}
.minified .hrColor {
  display: none;
}
.minified .ebill-nav {
  padding: 5px 8px !important;
}
.minified #left-panel {
  padding-top: 0px !important;
}
.fixed-navigation nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.fixed-navigation nav::-webkit-scrollbar {
  display: none;
}
.hidden-menu .page-footer {
  padding-left: 20px;
}
.default-dropdown .input-group .form-control {
  padding-right: 5px !important;
}
.header-formbtn {
  width: auto;
  margin-top: 11px !important;
  border: none;
  text-align: center !important;
  letter-spacing: 0;
  font-weight: bold;
  font: normal normal normal 13px/20px Open Sans !important;
}
.advace-header-formbtn {
  height: 35px;
  margin-top: 26px !important;
  width: 100px;
  border: none;
  text-align: center !important;
  letter-spacing: 0;
  font-weight: bold;
  font: normal normal normal 13px/20px Open Sans !important;
  border-radius: 30px;
}
.colon-symbol label {
  position: relative;
}
.colon-symbol label:after {
  content: ":";
  position: initial;
  right: -12px;
  top: 0px;
}
formly-field .colon-symbol label {
  display: inline-block;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
  font-size: 12px;
  height: 35px;
}
.form-group .form-control {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 0;
  font-size: 12px;
  height: 40px;
}
.form-group textarea.form-control {
  height: unset !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.stepsinout-form.formly .input-group-text, .fileuploadform-form .businessconnect-form.formly .input-group-text {
  font-size: 13px;
  font-weight: 400;
  color: #0C1214;
  text-align: center;
  border: unset !important;
}
.blacktooltip {
  text-align: left;
  z-index: 1000;
  position: fixed;
  width: 150px;
  height: 86px;
  border-radius: 5px;
  font: normal normal normal 13px/18px Open Sans;
}
.blacktooltip .insideDivUl {
  list-style: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
}
.blacktooltip .insideDivUl li {
  height: 38px;
  line-height: 38px;
  cursor: default;
}
.tooltip-text {
  text-align: left;
  z-index: 1000;
  position: absolute;
  border-radius: 5px;
  font: normal normal normal 13px/18px Open Sans;
}
.idTooltip,
.btnTooltip {
  text-align: center;
  z-index: 99999;
  position: absolute;
  width: 170px;
  height: 38px;
  border-radius: 5px;
  font: normal normal normal 13px/18px Open Sans;
}
.idTooltip .insideDivUl-nohover,
.btnTooltip .insideDivUl-nohover {
  z-index: 99999;
  list-style: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.errorTooltip {
  z-index: 99999;
  position: absolute;
  min-width: 200px;
  height: auto;
  border-radius: 5px;
  max-width: 700px;
  word-break: break-word;
  text-align: left;
  padding: 5px;
  position: absolute;
  left: 20px;
  right: 20px;
  font: normal normal normal 13px/18px Open Sans;
}
.errorTooltip .insideDivUl-nohover {
  z-index: 99999;
  list-style: none;
}
.header-search-form.formly.horizontal {
  display: block;
}
.header-search-form.formly.horizontal .form-control {
  font-size: 13px;
  font-weight: 400;
}
.header-search-form.formly.horizontal formly-field {
  height: 40px !important;
  line-height: 40px;
}
.header-search-form.formly.horizontal formly-field-input {
  width: calc(100% - 110px);
  float: right;
}
.header-search-form.formly.horizontal formly-field-input input {
  height: 40px !important;
}
.header-search-form.formly.horizontal formly-wrapper-addons {
  width: calc(100% - 110px);
  float: right;
}
.header-search-form.formly.horizontal formly-wrapper-addons formly-field-input {
  width: 100%;
}
.header-search-form.formly.horizontal formly-field-textarea {
  width: calc(100% - 110px);
  float: right;
}
.header-search-form.formly.horizontal formly-field-select {
  width: calc(100% - 110px);
  float: right;
}
.header-search-form.formly.horizontal formly-field-select select {
  height: 40px !important;
}
.header-search-form.formly.horizontal header {
  display: block;
}
.header-search-form.formly.horizontal .default-dropdown formly-field-select select {
  padding-right: 5px !important;
}
.header-search-form.formly.horizontal formly-wrapper-form-field .form-group label {
  width: 110px;
  text-align: center;
  color: #fff;
  font-size: 14px;
}
.header-search-form.formly .input-group-append {
  margin-left: 12px !important;
}
.header-search-form.formly .input-group-append .input-group-text {
  height: 36px;
  z-index: 100;
  margin-left: -40px;
  margin-top: 2px;
}
.default-dropdown .input-group .form-control {
  padding-right: 5px !important;
}
/* Hide scrollbar for IE, Edge and Firefox */
.scroll-tb-inbox {
  border: 0px solid #fff !important;
  border-top: transparent !important;
  height: 677px !important;
  overflow-y: scroll !important;
  margin-bottom: -9px !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scroll-tb-inbox::-webkit-scrollbar {
  display: none;
}
/* NEED TO CHANGE START */
.stepsinout-form .custom-checkbox .custom-control-label::before {
  width: 1.2rem;
  height: 1.3rem;
  top: 0.5rem;
}
.stepsinout-form .custom-checkbox .custom-control-label::after {
  top: 0.4rem;
  left: -1.7rem;
  width: 1.5rem;
  height: 1.5rem;
}
.fileExport .form-group {
  display: flex;
}
.stepsinout-form .fileExport formly-field-input {
  margin-left: 20px;
}
.stepsinout-form .custom-radio .custom-control-label {
  margin-right: 20px;
}
.stepsinout-form .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: none;
}
.stepsinout-form .custom-radio.custom-control {
  display: inline-block;
}
.form-control.ng-invalid.is-invalid {
  border-color: #dc3545 !important;
}
.form-control.is-invalid {
  background-image: none !important;
}
.was-validated .form-control:invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}
.form-group.has-error formly-wrapper-addons .input-group .input-group-append {
  border: 1px solid #dc3545 !important;
  border-left: none !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.form-group.has-error formly-wrapper-addons .input-group .input-addons {
  border: 1px solid #dc3545 !important;
  border-right: none !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.form-group.has-error formly-wrapper-addons .input-group .form-control.is-invalid {
  border: none !important;
}
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  color: #fff;
  background-color: #00B894;
  border-color: #00B894;
}
.businessconnect-form {
  margin-top: 45px;
}
.labelOr {
  text-align: center;
  margin-top: 30px;
}
.dataTables_processing {
  text-indent: -99999px;
  border: 0px !important;
  box-shadow: none !important;
  height: 55px;
  width: 55px;
  z-index: 999;
  margin-top: 100px !important;
  background-position: center !important;
}
.lstposTbale .dataTables_processing {
  display: block;
  text-indent: -99999px;
  border: 0px !important;
  box-shadow: none !important;
  z-index: 999;
  margin-top: 15px !important;
  background-position: center !important;
}
.lstposTbale .dt-toolbar {
  background: none !important;
  border: none !important;
  display: none;
}
.blur-foreground {
  filter: blur(5px);
}
.proccesLogTable {
  height: 141px !important;
  overflow-y: scroll;
}
.proccesLogTable::-webkit-scrollbar {
  width: 1px;
}
.dataTable tbody tr td {
  font-size: 13px;
}
/* For full screen */
#jarviswidget-fullscreen-mode .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.ui-datepicker {
  border-radius: 5px !important;
  z-index: 2000 !important;
}
.ui-datepicker select.ui-datepicker-month {
  margin: 0 2px;
  width: 47%;
}
.ui-datepicker select.ui-datepicker-year {
  margin: 0 2px;
  width: 47%;
}
.ui-datepicker td .ui-state-default {
  color: #fff;
}
.ui-datepicker .ui-datepicker-next span {
  background: none !important;
}
.ui-datepicker .ui-datepicker-prev span {
  background: none !important;
}
.ui-datepicker-prev span {
  background-image: none !important;
}
.ui-datepicker-next span {
  background-image: none !important;
}
.ui-datepicker-prev:before {
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 9;
  padding-top: 4px;
  content: "";
}
.ui-datepicker-next:before {
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 9;
  padding-top: 4px;
  content: "";
}
.flag.flag-en {
  background-position: -144px -154px;
}
.input-icons i {
  position: absolute;
}
.input-icons {
  width: 100%;
  margin-bottom: 10px;
}
.icon {
  padding: 10px;
  color: #fff;
  min-width: 50px;
  text-align: end;
  right: 15px;
  top: 8px;
  font-size: 14px;
}
.input-field {
  width: 100%;
  padding: 10px;
  text-align: left;
}
#ribbon ol.breadcrumb {
  font-size: 13px;
  font-weight: 600;
}
#left-panel {
  padding-top: 10px !important;
}
#left-panel .login-info {
  height: 41px !important;
}
.borderRightClr {
  height: 33px !important;
  padding: 6px 0px 0px 15px !important;
  border-radius: 0;
}
.minified .borderRightClr {
  padding: 0px !important;
  height: 40px !important;
}
.filter-form-select {
  border: 1px solid #707070 !important;
  border-radius: 5px !important;
}
.selectedBox {
  border: 1px solid #707070 !important;
  border-radius: 5px !important;
}
table.dataTable > tbody > tr.child ul.dtr-details {
  display: block;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.child > td:first-child {
  padding-left: 10px;
}
#divSmallBoxes {
  top: 30px !important;
}
.dashbaordlabel {
  margin-top: 4px;
}
.ebilllabel {
  margin-top: 9px;
}
.dropzone .dz-default.dz-message {
  margin: unset !important;
  width: unset !important;
}
.lstTransatiosectionHeadDiv .jarviswidget header h2 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}
.serviceHead {
  padding: 0 !important;
  margin: 5px 0 10px;
  color: #fff;
  font-size: 20px;
}
.sub-btn {
  font-size: 14px;
  border-radius: 15px;
  background-color: transparent !important;
  padding: 3px 15px;
}
.sub-btn:active {
  top: 0px !important;
  left: 0px !important;
}
@media (max-width: 280px) {
  .dropzone .dz-default.dz-message {
    margin-top: 5.5px !important;
  }
}
@media (max-width: 500px) {
  .header-formbtn,
.advace-header-formbtn {
    width: 100% !important;
  }
}
@media (min-width: 576px) {
  .dt-toolbar-footer .col-xs-12.col-sm-7 {
    float: right !important;
  }
}
@media screen and (max-width: 1545px) {
  .inbox-data-table + .dt-toolbar-footer {
    display: flex !important;
    flex-direction: column !important;
  }
  .inbox-data-table + .dt-toolbar-footer div:last-child {
    align-self: self-end !important;
  }

  .outbox-data-table + div.dt-toolbar-footer {
    display: flex !important;
    flex-direction: column !important;
  }
  .outbox-data-table + .dt-toolbar-footer div:last-child {
    align-self: self-end !important;
  }

  .scroll-tbs {
    height: 173px !important;
  }

  .scroll-tbs-pdf {
    height: 173px !important;
  }
}
@media screen and (max-width: 975px) {
  .dt-toolbar-footer {
    display: flex !important;
    flex-direction: column !important;
  }
  .dt-toolbar-footer div:last-child {
    align-self: self-end !important;
  }
}
@media (max-width: 576px) {
  .invoiceOverview ul.tabgrid {
    display: flex !important;
    flex-direction: column !important;
  }

  .nav-tabs li {
    width: 100% !important;
  }

  .joyride-step__container {
    width: auto;
  }

  textarea.in,
textarea.modal-in {
    width: auto;
  }
}
@media (max-width: 1199px) {
  .header-search-form.formly .input-group-append {
    margin-left: 0px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1375px) {
  .header-search-form.formly.horizontal formly-wrapper-form-field .form-group label {
    width: 75px !important;
  }
  .header-search-form.formly.horizontal formly-field-input {
    width: calc(100% - 75px);
  }
  .header-search-form.formly.horizontal formly-wrapper-addons {
    width: calc(100% - 75px);
  }
  .header-search-form.formly.horizontal formly-field-textarea {
    width: calc(100% - 75px);
  }
  .header-search-form.formly.horizontal formly-field-select {
    width: calc(100% - 75px);
  }

  .header-formbtn,
.advace-header-formbtn {
    width: 75px !important;
  }
}
@media (max-width: 767px) {
  .header-search-form.formly .input-group-append {
    margin-left: -4px !important;
  }
  .header-search-form.formly .input-group-append .input-group-text {
    margin-left: -23px;
  }
}
@media (max-width: 979px) {
  .page-footer {
    padding: 5px 14px 0;
  }
}
@media (min-width: 1196px) and (max-width: 1364px) {
  #main {
    height: calc(100vh - 50px) !important;
  }
}
#left-panel {
  position: absolute;
  z-index: 904;
  height: 100px !important;
  top: 49px;
}
.minified .userlabel {
  display: none !important;
}
.minified .body-image {
  width: 40px !important;
  height: 40px !important;
  margin: auto;
}
.MessageBoxButtonSection button {
  border-radius: 15px;
  background: transparent;
  border: 2px solid var(--theme-color-1) !important;
  font-size: 13px;
  padding: 3px 15px;
  color: #fff !important;
  font-weight: 400 !important;
}
.MessageBoxButtonSection button:hover {
  border-radius: 15px;
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1) !important;
  font-size: 13px;
  padding: 3px 15px;
  color: #000 !important;
}
.theme-header-color {
  color: #E2E2E2 !important;
}
.border-line-none {
  background: transparent !important;
  border: none;
}
.jarviswidget div {
  border: none;
}
/* .NextconfirmLabel {
	font: normal normal normal 13px/24px Open Sans;
	width: 193px;
	letter-spacing: 0px;
}
 */
.popover {
  display: none;
}
.editCommunication,
.deleteCommunication {
  padding: 2px 15px !important;
  border-radius: 13px !important;
}
.note-icon-caret {
  display: none;
}
.btn-primary:not(:disabled):not(.disabled):active {
  border-color: var(--theme-color-1) !important;
}
table.positionTable thead tr th:first-child {
  border-radius: 5px 0px 0px 0px;
}
table.positionTable thead tr th:last-child {
  border-radius: 0px 5px 0px 0px;
}
table.positionTable tbody tr:last-child td:first-child {
  border-radius: 0px 0px 0px 5px;
}
table.positionTable tbody tr:last-child td:last-child {
  border-radius: 0px 0px 5px 0px;
}
.fa-exclamation {
  color: red;
  font-size: x-large;
  padding: 0px 10px;
}
.MsgTitle .txt-color-logorangeDark {
  color: #a57225 !important;
}
.serviceTheme {
  border-radius: 10px !important;
}
.serviceTheme .chip_Section {
  border-radius: 10px !important;
}
.serviceTheme .serachService {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  height: 35px;
}
.serviceTheme .input-group-append .input-group-text {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.minified .profile-image-box {
  display: none;
}
.minified ul.navList {
  margin-top: 34px !important;
}
.minified .chatIcon img {
  margin-left: -8px;
}
.ui-dialog-titlebar-close:before {
  content: "";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
}
.ui-dialog .ui-dialog-titlebar-close {
  position: absolute;
  right: 0.3em;
  top: 50%;
  width: 19px;
  margin: -20px 0 0 0;
  padding: 1px;
  height: 18px;
  font-size: 20px;
  font-weight: 400;
  line-height: 19.5px;
  text-shadow: none;
}
.card {
  border: none !important;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 5px !important;
  border-top-left-radius: 5px !important;
}
.dt-toolbar-footer {
  padding: 5px 0px;
}
.nextBtn {
  font-size: 13px;
  background: transparent 0% 0% no-repeat padding-box !important;
  border-color: var(--theme-color-1) !important;
  letter-spacing: 0px;
  border: 2px solid var(--theme-color-1) !important;
  border-radius: 15px;
  color: #fff !important;
  padding: 3px 15px;
}
.nextBtn:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: #000 !important;
}
.btnAction,
.btnFile,
.btnSendinvit {
  font-size: 13px;
  background: transparent 0% 0% no-repeat padding-box !important;
  border-color: var(--theme-color-1) !important;
  letter-spacing: 0px;
  border: 2px solid var(--theme-color-1) !important;
  border-radius: 15px;
  color: #fff !important;
  padding: 3px 15px;
}
.btnAction:hover,
.btnFile:hover,
.btnSendinvit:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: #000 !important;
}
.btnUpload {
  font-size: 13px;
  background: transparent 0% 0% no-repeat padding-box !important;
  border-color: var(--theme-color-1) !important;
  letter-spacing: 0px;
  border: 2px solid var(--theme-color-1) !important;
  border-radius: 15px;
  color: #fff !important;
  padding: 3px 15px;
}
.btnUpload:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: #000 !important;
}
.filter-top {
  font-size: 16px !important;
  letter-spacing: 0px;
  font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
}
.filter-top .link {
  font-weight: 400;
  font-size: 14px !important;
}
.chatIcon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.footer-menu {
  padding: 12px 17px 12px 16px;
}
.settings-list {
  margin-top: 4px;
  min-width: 270px;
  font-family: Open Sans, Arial, Helvetica, Sans-Serif;
  position: absolute;
  z-index: 1000;
}
.settings-list .settings-list-items-contents {
  display: block;
  max-height: calc(100vh - 120px);
  overflow: overlay;
  max-width: 320px;
  border-radius: 10px;
}
.settings-list .settings-list-row {
  padding: 15px 0px;
  display: flex;
  flex-wrap: wrap;
}
.settings-list .settings-list-row .list-group-item {
  background-color: transparent !important;
  height: 35px;
  border: none !important;
  display: flex !important;
  flex-direction: row;
}
.settings-list .settings-list-row .list-group-item div.settings-item-div {
  width: 90% !important;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}
.settings-list .settings-list-row .list-group-item div.icon-div {
  padding: 0px 2px;
  display: flex;
  width: 25px !important;
}
.settings-list .settings-list-row .list-group-item div.icon-div i {
  margin: auto;
}
.settings-list .settings-list-row .list-group-item .count {
  border-radius: 50%;
  font-size: 13px;
  z-index: 10;
  background-color: var(--theme-color-1) !important;
  height: 20px;
  width: 20px;
  font-weight: bold;
  color: #000 !important;
  line-height: 9px;
  padding: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.settings-list .settings-list-box {
  width: 33.3333%;
  text-align: center;
  padding: 30px 20px 0;
  font-weight: 500;
}
.settings-list .border-bootm-green {
  border-bottom: 1px solid var(--theme-color-1);
}
.amount-div {
  width: 30%;
  float: right;
  height: 100px;
  padding: 10px;
  position: relative;
  top: 148px;
  border-radius: 5px;
}
.amount-div .total-amount {
  font-size: 16px;
  font-weight: 400;
}
.amount-div .amountDiv {
  float: right;
  font-size: 16px;
  text-align: right;
}
.CodeMirror-line {
  font-size: 13px;
}
#preview .modal-body {
  padding: 1rem 0px;
}
.joyride-step__holder {
  border-radius: 15px;
}
.joyride-step__container {
  border-radius: 15px;
  width: 600px !important;
  max-height: 300px !important;
}
.joyride-step__container .joyride-step__body {
  overflow-y: auto !important;
  overflow-x: hidden;
  z-index: 1;
}
.joyride-step__container .joyride-step__body::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
.joyride-step__container .joyride-step__body::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
}
.joyride-step__container .joyride-step__body::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}
.joyride-button {
  border-radius: 20px;
  background: transparent !important;
  border: 2px solid var(--theme-color-1) !important;
  padding: 3px 15px !important;
  border-radius: 15px !important;
  font: normal normal normal 13px/20px Open Sans !important;
}
.joyride-step__close {
  text-align: right;
  letter-spacing: 0px;
  color: var(--theme-color-1) !important;
  opacity: 1;
  font-size: 18px;
  font-weight: bold;
  right: 15px !important;
  top: 15px !important;
}
.joyride-step__close svg {
  display: none;
}
.joyride-step__close:before {
  content: "";
  font-family: "Font Awesome 5 Pro";
  left: 5px;
  position: absolute;
  top: -10px;
  font-size: 18px;
  font-weight: 900;
  text-align: right;
}
.infoIcon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  filter: var(--filter);
}
.infoFieldIcon {
  height: 14px;
  width: 14px;
  cursor: pointer;
  filter: var(--filter);
}
.infoServiceFieldIcon {
  height: 14px;
  width: 14px;
  cursor: pointer;
  filter: var(--filter);
}
.joyride-step__footer {
  justify-content: end !important;
  flex-direction: row-reverse !important;
  padding-top: 10px !important;
}
li.active a.error {
  color: #dc3545 !important;
}
.error_text {
  font-size: 13px;
}
button[disabled],
html input[disabled] {
  cursor: not-allowed;
}
.btn-save {
  display: none;
}
textarea.in {
  display: none;
  width: 570px !important;
  height: 173px !important;
  font-size: 13px;
  color: #ffff;
  background-clip: padding-box;
  background-color: unset !important;
  border: 1px solid #666666 !important;
  border-radius: 5px !important;
}
textarea.in::-webkit-scrollbar {
  width: 0px;
}
textarea.modal-in {
  min-width: 400px;
  height: 300px;
  font-size: 13px;
  color: #ffff;
  background-clip: padding-box;
  background-color: unset !important;
  border: 1px solid #666666 !important;
  border-radius: 10px !important;
}
.tour-title-input {
  border-radius: 5px !important;
  height: 35px;
  width: 100%;
  color: #ffff;
  background-clip: padding-box;
  background-color: unset !important;
  border: 1px solid #666666 !important;
  font-size: 13px;
  margin-top: 15px;
}
.modal-title-input {
  border-radius: 5px !important;
  height: 35px;
  width: 100%;
  color: #ffff;
  background-clip: padding-box;
  background-color: unset !important;
  border: 1px solid #666666 !important;
  font-size: 13px;
  margin-top: 15px;
  margin-right: -15px;
}
.joyride-step__counter-container {
  margin-right: 10px !important;
  margin-left: 5px !important;
}
.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
}
.green-border {
  border: 1px solid var(--theme-color-1) !important;
}
.blue-border {
  border: 1px solid var(--theme-color-1) !important;
}
.accordianXpand {
  transition: all 0.2s ease-in-out 0s !important;
}
.green-label {
  color: var(--theme-color-1) !important;
}
tr.selected span.green-label {
  color: #111 !important;
}
nav ul ul {
  padding: 0px;
}
nav ul ul li {
  padding-left: 43px;
}
nav ul ul li > a:hover {
  background: var(--theme-color-1) !important;
}
nav ul ul li > a:hover {
  background: #666 !important;
}
nav ul b .fa-plus-square-o:before {
  content: "" !important;
}
nav ul b .fa-minus-square-o:before {
  content: "" !important;
}
nav ul ul li a {
  padding-left: 43px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  color: #ffffff;
  font-size: 14px !important;
}
.textalignlabel {
  text-align: center;
}
.positionTable thead tr {
  position: sticky;
  top: 0px;
}
.contactUs div {
  font-size: 14px;
}
.ebillForm formly-field-select select option {
  background-color: #202124 !important;
}
.ebillForm .commmonWrap formly-group {
  width: 100% !important;
}
.commmonWrap formly-group {
  width: 100% !important;
}
.requiredSection {
  border-color: #dc3545 !important;
}
.requiredSector {
  color: #dc3545 !important;
  font-size: 13px !important;
}
.billerFormSection formly-wrapper-form-field div {
  font-size: 14px !important;
}
.biller_tabSection .nav-item {
  font-size: 14px !important;
}
.ebiller_tabSection {
  width: 100% !important;
}
.ebiller_tabSection .nav-item {
  font-size: 14px !important;
  width: 10%;
}
.ebiller_tabSection .nav-item a {
  text-align: center;
}
.companySelect {
  border: none;
  border-radius: 10px;
}
.company-select {
  border: none;
  border-radius: 10px;
  background-color: #0C1214 !important;
}
.communicationPanel {
  display: block !important;
}
.communicationPanel .card {
  margin: 0 10px;
}
.communicationPanel .card .card-header {
  border-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #ffff;
}
.communicationPanel .card .communicationBodyWrap {
  border: 1px solid #666;
  border-top: 0;
  margin: 0 1px 0 1px;
}
.includePdf formly-field-radio div {
  display: flex;
}
.includePdf formly-field-radio div .custom-radio {
  padding-left: 2.5rem;
}
.invoiceTbl .dt-toolbar,
.subscribersTbl .dt-toolbar {
  background-color: unset !important;
}
.invoiceTbl .sorting_disabled,
.subscribersTbl .sorting_disabled {
  background-color: #1E1E1E !important;
}
.common_wizardItemContainer .common_wizardItems {
  width: 35% !important;
}
.common_wizardItemContainer .wizard_format {
  width: 55% !important;
}
.detail_save_btn {
  border-radius: 15px;
  background: transparent;
  border: 2px solid var(--theme-color-1);
  font-size: 13px;
  padding: 3px 15px;
  line-height: 19.5px;
  font-weight: 400;
}
.detail_save_btn:hover {
  border-color: var(--theme-color-1) !important;
  background: var(--theme-color-1) !important;
  color: #000 !important;
}
.biller_tabSection li.active a {
  box-shadow: unset !important;
}
.interConnPopupForm small {
  font-size: 12px !important;
  color: red !important;
}
.header-settings .count {
  position: absolute;
  border-radius: 50%;
  font-size: 13px;
  top: 6%;
  right: 8px;
  z-index: 10;
  background-color: var(--theme-color-1) !important;
  height: 20px;
  width: 20px;
  font-weight: bold;
  color: #000 !important;
  line-height: 15px;
  padding: 2px;
}
.backdrop-container {
  z-index: 1100 !important;
}
.joyride-step__holder {
  z-index: 1100 !important;
}
.preview-div .preview-widget {
  display: flex;
  flex-direction: row;
}
.preview-div .preview-widget .left-side {
  flex: 0 0 100%;
  justify-content: center;
  align-items: center;
}
.preview-div .preview-widget .right-side {
  flex: 0 0 30%;
  padding: 5px 5px 5px 10px;
}
.preview-div .preview-widget .right-side .err-status {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  height: 100px;
  padding: 15px;
  font-size: 15px;
}
.preview-div .preview-widget .right-side .err-status .text-status {
  font-size: 14px;
  font-weight: bold;
}
.invoice-table-status td {
  padding: 5px 0;
}
.tooltipA {
  position: relative;
  display: inline-block;
}
/* Tooltip text */
.tooltipA .tooltiptext {
  visibility: hidden;
  min-width: 180px;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 100;
  top: -5px;
  right: 40%;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltipA:hover .tooltiptext {
  visibility: visible;
}
.error-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
}
.error-div div.error-div-div {
  width: 300px;
  width: 300px;
  height: 200px;
  border-radius: 8px;
  padding: 5px;
}
ul.invoice-tab > li.active > a {
  border: 1px solid var(--theme-color-1) !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  box-shadow: 0 -2px 0 var(--theme-color-1) !important;
}
.table tbody tr td {
  padding: 7px;
  line-height: 1.42857143;
  vertical-align: top;
}
.invoice-tab-header .nav-tabs > li {
  text-align: center;
}
.invoice-tab-header .nav-tabs > li > a {
  margin: auto;
}
.businessFields label {
  color: #fff !important;
  font-size: 14px;
}
.editCommmunication {
  display: flex !important;
  align-items: center !important;
  margin-bottom: -15px;
  justify-content: end;
}
.radioLbl {
  color: #fff;
  font-size: 18px;
}
.filePattern {
  color: #E2E2E2 !important;
}
.serviceForm formly-field .invalid-feedback formly-validation-message {
  font-size: 13px !important;
}
.inputSection small,
.selectSection small {
  font-size: 13px !important;
}
.dividerOr .alignOr {
  display: flex !important;
  justify-content: center !important;
  margin: 0px !important;
  padding-top: 10px !important;
  font-size: 14px;
}
.searchRecipientForm formly-field label {
  font-size: 13px;
}
.searchRecipientForm formly-field formly-field-input input {
  border-radius: 10px !important;
}
.searchRecipientForm formly-field formly-validation-message {
  font-size: 12px;
}
.file_export_filename formly-wrapper-form-field label {
  margin-bottom: 15px !important;
}
.file_export_filename formly-wrapper-form-field input {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.formatIcon {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.commonHead-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.commonHead-main h2 {
  margin-left: 3px !important;
}
.commonHead-main .right-side {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.tdd table {
  table-layout: fixed !important;
}
.tdd table td {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.tdd table td .tr-filename {
  word-break: break-all !important;
}
.failImg img {
  height: 75px;
  width: 75px;
}
.modal-body .text-danger.error {
  font-size: 14px;
}
.minified nav > ul > li > a > .menu-item-parent {
  top: 1px !important;
}
.app-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
.app-tut {
  width: 220px;
  height: 220px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  cursor: pointer;
}
.app-tut .app-inner-tut {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app-tut:first-child {
  margin-left: 0px !important;
}
.app-tut:last-child {
  margin-right: 0px !important;
}
.app-tut.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.app-tut.disabled .app-inner-tut {
  pointer-events: none !important;
}
.app-img {
  width: 100px;
  height: 100px;
}
.app-img img {
  width: 100px;
  height: 100px;
}
.app-title {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}
.approveBtn {
  border-radius: 15px;
  background: transparent;
  border: 2px solid var(--theme-color-1);
  font-size: 13px;
  padding: 3px 15px;
  line-height: 19.5px;
  font-weight: 400;
}
.approveBtn:hover {
  border-color: var(--theme-color-1) !important;
  background: var(--theme-color-1) !important;
  color: #000 !important;
}
.bulk-form {
  width: 100%;
}
.bulk-form label {
  font-size: 14px !important;
}
.bulk-form input {
  border-radius: 10px !important;
}
.invalid-feedback {
  font-size: 11px !important;
}
.defaultDot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}
.stepsinout-form select,
.businessconnect-form select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}
.stepsinout-form .input-group-append .input-group-text:before,
.businessconnect-form .input-group-append .input-group-text:before {
  margin-left: -6px;
  padding-left: 6px;
}
.stepsinout-form .stepsDown .form-group input {
  margin-top: -9px !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.btn-primary:focus {
  box-shadow: none;
}
.ticketPopupClass,
.summernoteEditor {
  z-index: 99999 !important;
}
@media (min-width: 980px) and (max-width: 1108px) {
  :not(.hidden-menu) .wizard-items {
    width: 100% !important;
    flex-basis: 100% !important;
  }
  :not(.hidden-menu) .wizard-billeritem {
    width: 100% !important;
    flex-basis: 100% !important;
  }
  :not(.hidden-menu) .line-1 {
    height: 50px;
    width: 3px;
    left: 50%;
  }
  :not(.hidden-menu) .wizardTextWidth {
    align-items: center !important;
  }
  :not(.hidden-menu) .wizard-container {
    flex-direction: column !important;
  }
  :not(.hidden-menu) .line {
    width: 100% !important;
  }
  :not(.hidden-menu) .wizardDiv {
    margin-left: 20px !important;
  }
}
.colorWhite {
  filter: none;
}
.smart-style-2.minified #left-panel {
  position: absolute;
  z-index: 904;
  padding-top: 0px !important;
  height: 100px !important;
}
.serviceTheme.steps .ui-dialog-titlebar {
  display: none;
}
.commonBorderRadius {
  border-radius: 10px;
}
.dz-filename {
  padding-top: 20px;
}
.toolTipHideUserRole {
  width: 340px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
.roleLabel {
  width: 340px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
}
.tooltipRole {
  position: relative;
  display: inline-block;
}
.tooltipRole:hover {
  cursor: pointer;
}
.tooltipRole:hover .tooltiptextRole {
  visibility: visible;
}
.tooltipRole:hover .tooltiptextRole .insideDivUl li:last-child {
  border-bottom: unset;
}
.tooltipRole .tooltiptextRole {
  visibility: hidden;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  word-break: break-all;
  position: fixed;
  z-index: 1;
  left: 38%;
  top: 0%;
  text-align: left;
  width: 150px;
  font: normal normal normal 13px/18px Open Sans;
  border-radius: 10px;
  height: auto;
}
.tooltipRole .tooltiptextRole .insideDivUl {
  list-style: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0rem;
}
.tooltipRole .tooltiptextRole .insideDivUl li {
  height: 38px;
  line-height: 38px;
  cursor: default;
}
.tooltipRole .toolTipRoleTitle {
  padding: 5px 5px !important;
  left: inherit !important;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 1000;
}
.toolTipHideUserRole {
  width: 340px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
.roleLabel {
  width: 340px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
}
.tooltipRole {
  position: relative;
  display: inline-block;
}
.tooltipRole:hover {
  cursor: pointer;
}
.tooltipRole:hover .tooltiptextRole {
  visibility: visible;
}
.tooltipRole:hover .tooltiptextRole .insideDivUl li:last-child {
  border-bottom: unset;
}
.tooltipRole .tooltiptextRole {
  visibility: hidden;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  word-break: break-all;
  position: fixed;
  z-index: 1;
  left: 38%;
  top: 0%;
  text-align: left;
  width: 150px;
  font: normal normal normal 13px/18px Open Sans;
  border-radius: 10px;
  height: auto;
}
.tooltipRole .tooltiptextRole .insideDivUl {
  list-style: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0rem;
}
.tooltipRole .tooltiptextRole .insideDivUl li {
  height: 38px;
  line-height: 38px;
  cursor: default;
}
.tooltipRole .toolTipRoleTitle {
  padding: 5px 5px !important;
  left: inherit !important;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 1000;
}
.CodeMirror-wrap pre {
  word-break: break-word;
}
.codePopup {
  width: 1500px !important;
}
.CodeMirror {
  height: 600px !important;
  font-size: 16px;
}
#wizard {
  border-radius: 15px;
  padding: 0px 20px;
}
#wizard .wizard-container {
  display: flex;
  flex-direction: row;
}
/* #wizard1{
	border-radius: 15px;
	padding: 10px 20px;
	.wizard-container{
		display: flex;
		flex-direction: row;
	}
} */
.line-1 {
  height: 1px;
  width: 100%;
  z-index: 200;
  position: relative;
  top: 50% !important;
}
.line {
  width: 5%;
  z-index: 1;
  position: relative;
}
.wizardDiv {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  font-size: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.wizard-billeritem {
  width: 30%;
  border-radius: 10px;
  z-index: 100;
  position: relative;
  min-height: 90px;
  display: flex;
}
.wizard-items {
  width: 30%;
  border-radius: 10px;
  z-index: 100;
  position: relative;
  min-height: 90px;
  display: flex;
}
.wizard-items:hover {
  cursor: pointer;
}
.wizard-items:first-of-type:before {
  display: none;
}
.wizard-items:last-of-type:after {
  display: none;
}
.wizard-items:last-child {
  margin-right: 0px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.tickImage {
  width: 25px;
}
.wizard .plusBtn {
  position: absolute;
  right: 20px;
  cursor: pointer;
  top: 13px;
}
.wizard#accordionExpandWizard {
  border-radius: 10px;
  padding: 20px 20px 0px;
}
.wizardTextWidth {
  width: 75%;
  flex: 1 0 75% !important;
  padding: 0px 10px;
  display: flex;
  align-items: center !important;
}
.wizardTextButton {
  width: 75%;
  flex: 1 0 75% !important;
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.wizardCircleWidth {
  width: 20%;
  flex: 1 0 20% !important;
}
b.badges {
  position: absolute;
  cursor: pointer;
  font-size: 10px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  padding: 2px 4px 3px;
  text-align: center;
  line-height: normal;
  content: "";
  top: -1px;
  right: -1px;
  transform: translate(25%, -25%);
  width: 25px;
  height: 25px;
  color: #111;
  background-color: var(--theme-color-1);
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-color-green {
  background-color: var(--theme-color-1) !important;
}
.bg-color-blue {
  background-color: var(--theme-color-1) !important;
}
@media (min-width: 980px) and (max-width: 1108px) {
  .wizard-container {
    flex-direction: column !important;
  }
}
@media (min-width: 1109px) and (max-width: 1170px) {
  .line {
    width: 100% !important;
  }
}
@media (min-width: 980px) and (max-width: 1170px) {
  :not(.hidden-menu) #wizard {
    flex-direction: column;
  }
  :not(.hidden-menu) #wizard1 {
    flex-direction: column;
  }
  :not(.hidden-menu) .wizard-items {
    width: 100% !important;
  }
  :not(.hidden-menu) .wizard-billeritem {
    width: 100% !important;
  }
  :not(.hidden-menu) .common_wizardItemContainer .common_wizardItems {
    width: 100% !important;
  }
  :not(.hidden-menu) .line-1 {
    height: 50px;
    width: 3px;
    left: 50%;
  }
  :not(.hidden-menu) .wizardDiv {
    margin-left: 20px !important;
  }

  .wizard-items {
    width: 100% !important;
  }

  .wizard-container {
    flex-direction: column !important;
  }

  .wizardTextWidth {
    width: 60%;
    flex: 1 0 80% !important;
    align-items: center !important;
  }

  .wizardCircleWidth {
    width: 15%;
    flex: 1 0 5% !important;
  }

  .wizardDiv {
    margin-left: 20px !important;
  }
}
@media (min-width: 1170px) and (max-width: 1339px) {
  .wizardDiv {
    margin-left: 0px !important;
  }
}
@media (min-width: 1109px) and (max-width: 1300px) {
  .wizardTextWidth {
    align-items: flex-start !important;
  }
}
@media (max-width: 900px) {
  .wizard-container {
    flex-direction: column !important;
  }

  .line {
    width: 100% !important;
  }

  .line11 {
    width: 100% !important;
  }
}
@media (max-width: 1600px) {
  .wizardTextWidth {
    width: 60%;
  }

  .wizardCircleWidth {
    width: 15%;
  }
}
@media (max-width: 900px) {
  .wizard-items {
    width: 100%;
    margin-right: 0px;
  }

  .line-1 {
    height: 50px;
    width: 3px;
    left: 50%;
    transform: rotate(0deg);
  }

  .wizardTextWidth {
    width: 60%;
    flex: 1 0 80% !important;
    align-items: center !important;
  }

  .wizardCircleWidth {
    width: 15%;
    flex: 1 0 5% !important;
  }

  .wizardDiv {
    margin-left: 20px !important;
  }
}
@media (max-width: 700px) {
  .wizardDiv {
    margin-left: 0px !important;
  }
}
@media (max-width: 500px) {
  .wizardDiv {
    margin-left: 0px !important;
  }

  .wizard-items .row {
    margin-top: 25px;
  }

  .wizardCircleWidth {
    justify-content: center;
  }

  .wizardTextWidth {
    flex: 1 0 100% !important;
    margin-top: 30px;
    text-align: center;
  }
}
.backdrop {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
}
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  top: 0;
  z-index: 999;
}
.disabledProp .input-group {
  cursor: not-allowed;
}
.disabledProp select {
  cursor: not-allowed;
  pointer-events: none;
}
.disabledProp .custom-radio {
  cursor: not-allowed;
  pointer-events: none;
}
.disableall .input-group {
  cursor: not-allowed;
}
.disableall select {
  cursor: not-allowed;
  pointer-events: none;
}
.disableall .custom-radio {
  cursor: not-allowed;
  pointer-events: none;
}
.disableall input {
  cursor: not-allowed;
  pointer-events: none;
}
.companySelect.ng-select.ng-select-focused:not(ng-select-opened) > .ng-select-container,
.company-select.ng-select.ng-select-focused:not(ng-select-opened) > .ng-select-container {
  border: none;
  box-shadow: none;
}
.ng-select-container .ng-value-container .ng-input {
  top: 10px !important;
}
.emailMessage {
  border-radius: 10px !important;
  height: 100px;
  padding: 15px 7px;
}
.receiveMail div:not(.emailMessage) {
  position: relative;
  bottom: 0px;
  margin-top: 25px;
  padding-right: 15px;
}
.ediEmailMessage {
  border-radius: 10px !important;
  padding: 7px;
}
.ediReceiveMail div:not(.emailMessage) {
  margin-top: 25px;
}
.panelSector .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  position: relative;
  top: 0px;
}
.panelSector .ng-select.ng-select-multiple.requiredSection .ng-select-container {
  border: 1px solid #dc3545 !important;
}
.panelSector .ng-select.ng-select-multiple.requiredSection .ng-select-container .ng-value-container .ng-placeholder {
  position: relative;
  top: 0px;
}
#header.colorChangeTest {
  background-color: var(--header-color) !important;
}
.ng-dropdown-panel.ng-select-bottom {
  overflow: auto;
}
@media (max-width: 1140px) {
  .preview-div .preview-widget {
    flex-direction: column-reverse !important;
    margin-bottom: 0.5rem;
  }

  .left-side {
    flex: 0 0 100%;
  }

  .right-side {
    padding: 0;
    flex: 0 0 0%;
  }
  .right-side .err-status {
    flex-direction: column !important;
  }

  .jarviswidget header h2 {
    width: auto !important;
  }
}
.cookie-text {
  font-size: 12px;
}
.show-consent {
  display: flex;
  display: -webkit-flex;
}
.cookie-consent {
  width: 100%;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: #d6d6d6;
  background-color: #3c404d;
  padding: 0.6em 1.8em;
  z-index: 9999;
  padding: 10px 0 0 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.consent-btn {
  color: #000;
  flex: 1 0 130px;
  background-color: var(--theme-color-1);
  border-color: transparent;
  padding: 0.2em 0.5em;
  font-size: 1.2em;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-weight: bolder;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  border-radius: 5px;
  min-height: 20px;
  margin: 0 30px 10px 10px;
  max-width: 100px;
}
a.cookie_anchor {
  color: var(--theme-color-1);
  text-decoration: none;
  font-weight: 500;
}
a.cookie_anchor:hover {
  color: var(--theme-color-1);
}
.invoice-table th {
  font-size: 14px;
}
.headers-fonts th {
  font-size: 14px;
}
.deleteBtnDiv {
  position: relative;
}
.deleteBtnDiv .btnTooltip {
  position: absolute;
  left: 90px;
  height: auto;
}
.disabledClass {
  pointer-events: none !important;
}
.disabledCursor {
  cursor: not-allowed !important;
}
.displayNone {
  display: none !important;
}
.ebill-draft {
  width: 18px;
  height: 18px;
}
.fa-check {
  background-color: var(--theme-color-1);
  padding: 4px 3px;
}
.fa-times {
  background-color: #fb3c4a;
  padding: 4px 5px;
}
.biller-status {
  font-size: 14px;
}
.biller-status i {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: black;
  font-size: 14px;
  margin-right: 5px;
}
.inner-divs {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
}
.snd-rcv {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}
.snd-rcv:first-child {
  margin-left: 0px !important;
}
.snd-rcv:last-child {
  margin-right: 0px !important;
}
.snd-rcv-div {
  border-radius: 10px;
  width: 250px;
  height: 220px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.snd-rcv-div .snd-rcv-img {
  width: 80px;
  height: 100px;
}
.snd-rcv-div .snd-rcv-img img {
  width: 100px;
  height: 100px;
}
.snd-rcv-div .snd-rcv-title {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0px;
}
.trapezium {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  width: 50%;
  line-height: 30px;
  border-bottom: 34px solid #3B424C;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  text-align: center;
  margin: auto 0;
  position: relative;
  /* Add position relative to the parent div */
}
.intrfce-drctn-div-text {
  position: absolute;
  /* Add absolute position to the child div */
  top: 50%;
  /* Set top position to 50% */
  left: 50%;
  /* Set left position to 50% */
  transform: translate(-50%, -50%);
  /* Translate the child div to center */
  line-height: 10px;
  margin-top: 15px;
  font-size: 12px;
  text-transform: uppercase;
}
@media (min-width: 350px) and (max-width: 991px) {
  .intrfce-drctn-div-text {
    line-height: 14px !important;
    font-size: 13px !important;
  }
}
@media (min-width: 992px) {
  .intrfce-drctn-div-text {
    font-size: 15px !important;
    line-height: 20px !important;
  }
}
.trapezium.active {
  border-bottom: 34px solid var(--theme-color-1) !important;
  color: #111 !important;
}
.steps-dir {
  display: flex;
}
.steps {
  gap: 15px;
}
.step {
  position: relative;
  width: 50px;
  max-width: 50px;
  text-align: center;
  height: 5px;
  border: 2px solid #FFF;
  background: #FFF;
  border-radius: 10px;
  margin-right: 10px;
}
.step.active {
  border: 2px solid var(--theme-color-1) !important;
  background: var(--theme-color-1) !important;
  color: var(--theme-color-1);
}
.font-13 {
  font-size: 13px !important;
}
table.bulkTable thead tr th:first-child {
  border-radius: 5px 0px 0px 0px;
}
table.bulkTable thead tr th:last-child {
  border-radius: 0px 5px 0px 0px !important;
}
.commonSelectRadius .form-control {
  border-radius: 10px !important;
}
.scroll-tb {
  border: 0px solid #eee !important;
  border-top: transparent !important;
  height: 425px !important;
  overflow-y: scroll !important;
}
.scanPattern {
  margin-left: -10px;
}
.bulkInviteModal .modal-footer > :not(:last-child) {
  margin-right: 0.25rem !important;
}
.nav-tabs li {
  float: left;
  margin-bottom: -1px;
  text-align: center;
  border-bottom: 1px solid #707070;
}
.sales-page .nav-tabs li {
  width: 34%;
}
.transaction li:first-child a {
  border-top-left-radius: 8px !important;
}
li.active .last-li-child {
  border-top-right-radius: 8px !important;
}
.tab-section {
  padding-bottom: 10px !important;
}
/* Width and height of the scrollbar track */
::-webkit-scrollbar {
  width: 2px;
}
/* Background color of the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #2e2e2e11;
}
@media (max-width: 657px) {
  .actions {
    display: contents !important;
  }

  .steps-container {
    display: flex !important;
  }
}
.wid_content .inboxtra {
  padding-top: 2px;
}
.archiveImport {
  border-radius: 15px;
  border: 2px solid var(--theme-color-1);
  font-size: 13px;
  padding: 3px 15px;
  line-height: 19.5px;
  font-weight: 400;
}
@media (max-height: 750px) {
  ul.navList.edi {
    height: 230px !important;
  }
}
@media (min-height: 751px) {
  ul.navList.edi {
    height: 450px !important;
  }
}
.repeate-btn-div {
  height: 45px;
  align-content: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
/* price - calculation */
.price_calcultation_form .multiselect-dropdown {
  width: 98.5% !important;
  height: 40px !important;
  padding: 4px 0px 0px;
  font: 14px/16px "Open Sans", Helvetica, Arial, sans-serif;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn {
  display: flex !important;
  border-radius: 10px !important;
  padding: 8px 12px !important;
  font-size: 13px !important;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn .dropdown-multiselect--active {
  padding-left: 10px;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn .selected-item-container {
  width: 90% !important;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: 1px solid var(--theme-color-1) !important;
  background: var(--theme-color-1) !important;
  color: #000 !important;
  max-width: 100% !important;
  padding: 0px 5px !important;
  border-radius: 20px !important;
  box-shadow: 1px 1px var(--theme-color-1) !important;
  width: auto !important;
  justify-content: center;
  display: flex;
  min-width: 100px;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item a {
  color: #000 !important;
  width: 8% !important;
  text-align: right;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  display: none !important;
}
.price_calcultation_form .multiselect-dropdown .filter-textbox input {
  background: #171717 !important;
}
.price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div {
  font-size: 12px !important;
}
.price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div:before {
  color: #fff !important;
  border: 2px solid #fff !important;
  border-radius: 5px;
}
.price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div:focus {
  color: #fff !important;
  border: 2px solid #fff !important;
}
.price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]:checked + div:before {
  background: transparent !important;
}
.price_calcultation_form .multiselect-dropdown .dropdown-multiselect__caret {
  top: 5px !important;
}
/* price - calculation */
.userEditTextBox {
  background: #fff 0% 0% no-repeat padding-box !important;
}
.ediPriceCalc ng-multiselect-dropdown .multiselect-dropdown .dropdown-list {
  width: auto;
}
.ediPriceCalc ng-multiselect-dropdown .multiselect-dropdown .dropdown-list[hidden] {
  position: absolute;
}
.invoice-div .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  border-radius: 10px;
  margin-left: -1px;
}
.invoice-div .paymentOptions {
  display: none;
  border-radius: 10px;
}
.PaySctn input[type=radio]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--theme-color-1);
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #666666;
  cursor: pointer;
}
.requestBody .NextconfirmLabel {
  font-size: 13px;
  letter-spacing: 0px;
  color: #fff;
  margin-right: 15px;
  padding: 5px 15px;
  border-radius: 20px;
}
.commonBasebg {
  background-color: var(--theme-color-1) !important;
}
.checkmark {
  position: absolute;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 7px;
  background-color: transparent;
}
/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: transparent;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
.companyTooltip {
  text-align: center;
  z-index: 1000;
  position: fixed;
  border-radius: 5px;
  font: normal normal normal 13px/18px Open Sans;
}
.companyTooltip .insideDivUl-nohover {
  list-style: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  word-break: break-all;
  height: auto;
}
/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-1 {
  /* #wizard1{
  	background-color: map.get($darktheme-colors, "commonbgcolor") !important;
  	// border: 1px solid #666666;
  	color: map.get($darktheme-colors, "textcolor");
  	.line-1{
  		background: #666666;
  	}
  	.wizard-items{
  		border: 1px solid #3B424C;
  		background: #202124 0% 0% no-repeat padding-box;
  		&:hover{
  			background: #171819 0% 0% no-repeat padding-box;
  		}
  		.wizardDiv{
  			border: 1px solid #404040;
  			background: #404040 0% 0% no-repeat padding-box;
  		}
  	}
  	b.badges{
  		border: 1px solid #202124;
  		box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
  	}
  	.wizard-billeritem{
  		border: 1px solid #3B424C;
  		background: #202124 0% 0% no-repeat padding-box;
  		.wizardDiv{
  			border: 1px solid #404040;
  			background: #404040 0% 0% no-repeat padding-box;
  		}
  	}
  } */
  /* price - calculation */
  /* price - calculation */
}
.smart-style-1 .CodeMirror {
  background: lightgrey;
}
.smart-style-1 .amount-div {
  background: #7c7b7b;
  color: #fff;
}
.smart-style-1 .clickread {
  color: #fff;
  font-weight: 500;
  font-size: 13px;
}
.smart-style-1 .clickread:hover {
  color: #000 !important;
}
.smart-style-1 .clickLbls:hover {
  color: #000 !important;
}
.smart-style-1 .jarviswidget-ctrls .button-icon {
  color: #E2E2E2;
}
.smart-style-1 .footerTextColor {
  color: #8B91A0 !important;
}
.smart-style-1 .setCircleactive {
  color: #00eba9;
  font-size: 10px !important;
}
.smart-style-1 .setCirclerej {
  color: #eb0042;
  font-size: 10px !important;
}
.smart-style-1 .setCircleTest {
  color: #F1DA91;
  font-size: 10px !important;
}
.smart-style-1 .wid_content {
  background-color: #202124 !important;
  border: none !important;
}
.smart-style-1 .setArrowBlue {
  color: #6558F5 !important;
}
.smart-style-1 .setFont {
  color: #fff;
}
.smart-style-1 .setblackFont {
  color: #0C1214;
}
.smart-style-1 .setwhiteFont {
  color: #fff;
}
.smart-style-1 .headerItems {
  color: #fff !important;
  font-size: 14px !important;
}
.smart-style-1 .commonHead {
  background-color: #202124 !important;
  color: #fff;
  font-size: 14px;
  border: unset !important;
}
.smart-style-1 .commonWidgetText {
  color: #fff;
  font-size: 13px;
}
.smart-style-1 .userWidgetText {
  color: #fff;
  font-size: 16px;
  display: inline;
}
.smart-style-1 .commonProgress {
  background-color: var(--theme-color-1) !important;
  border-color: #707070 !important;
  color: #707070 !important;
}
.smart-style-1 .blueHead {
  color: #fff;
}
.smart-style-1 .orangeHead {
  color: #fff;
}
.smart-style-1 .redHead {
  color: #fff;
}
.smart-style-1 .lstTrncnTbl thead tr th {
  background-color: #1E1E1E !important;
  color: #fff;
  border-color: #333333;
}
.smart-style-1 .lstTrncnTbl tbody tr {
  color: #fff;
  /*background-color: map.get($darktheme-colors, "bgcolor");*/
  border: 1px solid #707070 !important;
}
.smart-style-1 .lstTrncnTbl .table-bordered {
  border-color: #333333 !important;
}
.smart-style-1 .lstTrncnTbl .dt-toolbar-footer {
  background: none !important;
}
.smart-style-1 .lstTrncnTbl .dt-toolbar {
  background: none !important;
  border-bottom: none;
}
.smart-style-1 .table tbody tr td {
  border-top: 1px solid #333333;
  border-color: #333333;
}
.smart-style-1 .dt-toolbar-footer {
  background: none !important;
  font-size: none !important;
  overflow: hidden;
  padding: none !important;
  border-top: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important;
  width: 100%;
}
.smart-style-1 .lstposTbale thead tr th {
  background-color: #1E1E1E !important;
  color: #fff;
  border-color: #333333;
}
.smart-style-1 .lstposTbale tbody tr {
  color: #fff;
  border: 1px solid #333333 !important;
}
.smart-style-1 .lstposTbale .dt-toolbar-footer {
  background: none !important;
}
.smart-style-1 .lstposTbale .dataTables_processing {
  background: url('spin-loader_small_white.797d455d611e7389cd3b.svg') no-repeat !important;
}
.smart-style-1 .lstposTbale .dt-toolbar {
  background: none !important;
  border: none !important;
  display: none !important;
}
.smart-style-1 #header .menu-item:hover {
  background-color: #333333;
}
.smart-style-1 #header .theme-text-color {
  color: #fff !important;
}
.smart-style-1 #header .menu-item.no-hover:hover {
  background-color: #1E1E1E;
}
.smart-style-1 #header .collapseMenu:hover {
  background-color: #333333;
}
.smart-style-1 #header .collapseMenu .collaspe-menu-svg {
  background-image: url('Path 224.c485c714dd85f9b4f52d.svg');
}
.smart-style-1 #header .collapseMenu .collaspe-menu-sub-svg {
  background-image: url('Group224.649ca2f656deeb39e0a7.svg');
  filter: brightness(0) invert(1);
  cursor: pointer;
}
.smart-style-1 #header .graphsvgIcon {
  background-image: url('graph.0701364949bffb84c8c1.png');
  filter: brightness(100) invert(1);
}
.smart-style-1 #header .notification-svg {
  background-image: url('39-Notification.4feddd112452285fe534.svg');
}
.smart-style-1 #header #activity.activity-svg {
  background-image: url('Page-1.9f2dc138f1ca7885f995.svg');
}
.smart-style-1 #header .header-btn {
  background-color: #202124;
}
.smart-style-1 #header .header-btn-list a {
  background-color: #1E1E1E !important;
}
.smart-style-1 .dropdown-icon-menu > ul > li .btn,
.smart-style-1 .header-btn {
  background: #171717;
}
.smart-style-1 #fullscreen .svg-icon {
  background-image: url('Path222.737b381f1c9fbc85a137.svg');
}
.smart-style-1 .btn.btn-ribbon {
  background-color: #697486;
  margin-left: 4px;
}
.smart-style-1 .ajax-dropdown {
  border: 1px solid #313840 !important;
}
.smart-style-1 .notification-body li {
  border: 1px solid #252b31 !important;
}
.smart-style-1 .dropdown-menu {
  background-color: #0C1214;
  border: 1px solid #333333;
}
.smart-style-1 .dropdown-menu li a {
  color: #fff;
}
.smart-style-1 .header-dropdown-list .dropdown-menu li a:hover {
  background-color: #1E1E1E !important;
}
.smart-style-1 .header-dropdown-list .dropdown-menu .active a {
  background-color: #1E1E1E !important;
}
.smart-style-1 .documentLbl {
  background: #fff !important;
  text-align: center;
  font: normal normal normal 13px/18px Open Sans;
  color: #0C1214 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.smart-style-1 table.positionTable {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border: 1px solid #333333 !important;
  border-radius: 5px;
}
.smart-style-1 .gridBGclr {
  background: #0C1214 !important;
}
.smart-style-1 .borderClr {
  border: unset !important;
}
.smart-style-1 table.datatable {
  margin-top: 0px !important;
}
.smart-style-1 .tableHeadBGclr {
  background: #1E1E1E !important;
}
.smart-style-1 #inputState, .smart-style-1 .text-field {
  color: #fff !important;
}
.smart-style-1 .hoverTable tbody tr:hover {
  color: #E2E2E2 !important;
  background-color: #073E84 !important;
}
.smart-style-1 .tranhoverTable tbody tr:hover {
  background-color: #7c7b7b !important;
  cursor: pointer;
}
.smart-style-1 #ribbon .breadcrumb li {
  color: #fff !important;
}
.smart-style-1 #ribbon .breadcrumb li:last-child {
  color: #fff !important;
}
.smart-style-1 #ribbon .breadcrumb li:before {
  color: #fff !important;
}
.smart-style-1 .big-spin-loader {
  background: url('spin-loader_big_white.3c7bb61bb066252fe699.svg') no-repeat !important;
}
.smart-style-1 .big-spin-page-loader {
  background: url('spin-loader_big_white.3c7bb61bb066252fe699.svg') no-repeat !important;
}
.smart-style-1 .blacktooltip {
  background: #171717 0% 0% no-repeat padding-box;
  border: 1px solid #333333;
  color: #E2E2E2;
}
.smart-style-1 .blacktooltip .insideDivUl li:hover {
  background: #1E1E1E;
}
.smart-style-1 .tooltip-text {
  background: #171717 0% 0% no-repeat padding-box;
  border: 1px solid #333333;
  color: #E2E2E2;
}
.smart-style-1 .idTooltip,
.smart-style-1 .btnTooltip,
.smart-style-1 .errorTooltip {
  background: #171717 0% 0% no-repeat padding-box;
  border: 1px solid #333333;
  color: #E2E2E2;
}
.smart-style-1 .progress {
  font-size: 17px !important;
  vertical-align: 0 !important;
  border-radius: 0px !important;
}
.smart-style-1 nav ul li a i {
  font-size: 17px !important;
  vertical-align: 0 !important;
}
.smart-style-1 nav ul li.active {
  background-color: #262626 !important;
}
.smart-style-1 nav ul li a:hover {
  background-color: #262626 !important;
}
.smart-style-1 .form-control {
  color: #fff;
  background-color: #0C1214;
  border: 1px solid #333333;
}
.smart-style-1 .header-search-form.formly .input-group-append .input-group-text {
  background-color: #0C1214;
  color: #fff;
  border: 1px solid #0C1214;
}
.smart-style-1 .stepsinout-form .input-group-append .input-group-text,
.smart-style-1 .businessconnect-form .input-group-append .input-group-text {
  background-color: #0C1214;
  color: #fff;
  border: 1px solid #0C1214;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.smart-style-1 .stepsinout-form .input-group-append .input-group-text::before,
.smart-style-1 .businessconnect-form .input-group-append .input-group-text::before {
  border-left: 1px solid #fff;
}
.smart-style-1 .fileuploadform-form .input-group-append .input-group-text {
  background-color: #202124;
  color: #fff;
  border: 1px solid #333333;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.smart-style-1 #content {
  background: #202124 !important;
  opacity: 1;
}
.smart-style-1 #content .dashbaordlabel {
  background-color: unset !important;
  border: 1px solid #333333 !important;
  border-radius: 5px !important;
}
.smart-style-1 #content .header-formbtn,
.smart-style-1 #content .advace-header-formbtn {
  background-color: unset !important;
  border: 2px solid var(--theme-color-1) !important;
  color: #fff !important;
}
.smart-style-1 #content .header-formbtn:hover,
.smart-style-1 #content .advace-header-formbtn:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
}
.smart-style-1 #advanceSearch .dashbaordlabel {
  background-color: unset !important;
  border: 1px solid #333333 !important;
  border-radius: 5px !important;
}
.smart-style-1 .commonBackground option {
  background-color: #0C1214;
  color: #fff;
  height: 40px;
  border-color: #0C1214;
  font: normal normal normal 13px/18px Open Sans;
}
.smart-style-1 .invoiceOverview .nav-tabs li.active {
  border-top: 1px solid #707070 !important;
}
.smart-style-1 #left-panel nav ul.edi li {
  border-bottom: 1px solid var(--theme-color-1);
}
.smart-style-1 #left-panel nav ul.edi li:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
  border: 1px solid #3B3B3B33;
  border-bottom: 1px solid #3B3B3B33 !important;
}
.smart-style-1 #left-panel nav ul.ebill li {
  border-bottom: 1px solid var(--theme-color-1);
}
.smart-style-1 #left-panel nav ul.ebill li:hover {
  background: var(--theme-color-1) !important;
  color: #000 !important;
  border: 1px solid #3B3B3B33;
  border-bottom: 1px solid #3B3B3B33 !important;
}
.smart-style-1 #left-panel nav ul.ebill li.active {
  background-color: var(--theme-color-1) !important;
}
.smart-style-1 #left-panel nav ul.ebill li.active a {
  color: #202427 !important;
}
.smart-style-1 select:not([multiple]) option {
  background: #202124;
}
.smart-style-1 .login-info {
  background-color: #171717 !important;
}
.smart-style-1 .login-info span.borderRightClr {
  border: 1px solid #262626 !important;
}
.smart-style-1 .setFontlabel {
  color: #fff;
  font: normal normal bold 16px/22px Open Sans;
}
.smart-style-1 .setFontsub {
  color: #959595;
  font: normal normal bold 13px/18px Open Sans;
}
.smart-style-1 .setFonts {
  color: #959595;
}
.smart-style-1 .line1 {
  border: 1px solid #333333 !important;
}
.smart-style-1 .goButtons {
  top: 189px;
  left: 1808px;
  width: 86px;
  height: 40px;
  background-color: #fff !important;
  text-align: center;
  letter-spacing: 0px;
  color: #073E84 !important;
  font: normal normal normal 13px/18px Open Sans;
}
.smart-style-1 .processTbl thead tr th {
  color: #fff;
  border-top: 1px solid #707070;
}
.smart-style-1 .processTbl .table-bordered {
  border-color: #707070 !important;
}
.smart-style-1 table.borderline {
  border: 1px solid #707070 !important;
}
.smart-style-1 .inboxWidget {
  border: none;
}
.smart-style-1 .outboxWidget {
  background: #171717 0% 0% no-repeat padding-box !important;
  border: none;
}
.smart-style-1 .headClr {
  color: #fff !important;
}
.smart-style-1 .ui-datepicker {
  background: #202124 !important;
  border-color: #666666 !important;
  z-index: 2000 !important;
}
.smart-style-1 .ui-datepicker .ui-datepicker-header {
  background-color: #202124;
}
.smart-style-1 .ui-datepicker select.ui-datepicker-month {
  border-color: #303030;
  background: #202124 !important;
}
.smart-style-1 .ui-datepicker select.ui-datepicker-year {
  border-color: #303030;
  background: #202124 !important;
}
.smart-style-1 .ui-datepicker th {
  color: #fff !important;
}
.smart-style-1 .ui-datepicker td .ui-state-active {
  background: #707070;
}
.smart-style-1 .ui-datepicker-header a {
  color: #fff !important;
}
.smart-style-1 .ui-datepicker-month {
  color: #fff !important;
}
.smart-style-1 .ui-datepicker-year {
  color: #fff !important;
}
.smart-style-1 .ui-datepicker-prev:before {
  background: #202124 !important;
}
.smart-style-1 .ui-datepicker-next:before {
  background: #202124 !important;
}
.smart-style-1 .form-control {
  color: #fff !important;
  background-color: #202124;
}
.smart-style-1 .labelColor {
  color: #fff !important;
}
.smart-style-1 .stepsinout-form.formly .form-control {
  color: #fff !important;
  background-color: #0C1214 !important;
  border-color: #0C1214;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-1 .billTabSection .stepsinout-form.formly .form-control {
  color: #fff !important;
  background-color: #0C1214 !important;
  border-color: #0C1214;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  transition: border 0s ease-in-out 0s;
}
.smart-style-1 #accordionExpand .form-control {
  border-radius: 10px !important;
}
.smart-style-1 .businessconnect-form.formly .form-control {
  color: #fff !important;
  background-color: #0C1214 !important;
  border-color: #0C1214;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-1 .fileuploadform-form.formly .form-control {
  color: #fff !important;
  background-color: #0C1214 !important;
  border-color: #0C1214;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-1 .table tbody tr th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #333333;
  border-color: #333333;
}
.smart-style-1 .table tfoot tr td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #333333;
  border-color: #333333;
}
.smart-style-1 .table tfoot tr th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #333333;
  border-color: #333333;
}
.smart-style-1 .table thead tr td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #333333;
  border-color: #333333;
}
.smart-style-1 .table thead tr th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #333333;
  border-color: #333333;
}
.smart-style-1 .dt-toolbar .dataTables_filter {
  display: none !important;
}
.smart-style-1 .dt-toolbar .dataTables_length {
  display: none !important;
}
.smart-style-1 .minified nav ul li {
  border-top: none;
}
.smart-style-1 .dashboardHead .jarviswidget-ctrls .button-icon {
  border-left: unset !important;
}
.smart-style-1 nav ul li a {
  color: #fff !important;
  font-size: 14px !important;
}
.smart-style-1 nav ul li a:focus, .smart-style-1 nav ul li a:hover {
  background: var(--theme-color-1) !important;
  color: #000 !important;
}
.smart-style-1 tbody tr.child td {
  background-color: #171717;
  color: #E2E2E2;
}
.smart-style-1 .ui-datepicker td .ui-state-default {
  color: #fff;
}
.smart-style-1 .dataTables_processing {
  background: url('spin-loader_big_white.3c7bb61bb066252fe699.svg') no-repeat !important;
}
.smart-style-1 .errorOccured {
  border-color: #dc3545 !important;
}
.smart-style-1 .card-header {
  background-color: rgba(0, 0, 0, 0.5);
}
.smart-style-1 .panel {
  background: #1e1e1e;
}
.smart-style-1 .card {
  background-color: #202124;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.smart-style-1 .card-box {
  background-color: #171717 !important;
}
.smart-style-1 .box-header {
  color: #fff;
}
.smart-style-1 .panel-tag {
  border-left: 3px solid aliceblue;
  background: #000;
  color: #fff;
}
.smart-style-1 .service_modal .modal-content {
  background-color: #0C1214 !important;
  color: #fff;
}
.smart-style-1 .service_modal .modal-content .modal-header {
  border-bottom: #1B1E24 !important;
}
.smart-style-1 .service_modal .modal-content .servicePopUp_header_label {
  font-size: 14px;
  font-weight: 500;
}
.smart-style-1 .service_modal .modal-content .service_section {
  height: auto;
}
.smart-style-1 .service_modal .modal-content .service_details {
  height: auto;
}
.smart-style-1 .serviceTheme {
  background-color: #171717;
  border-radius: 0px;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar {
  background-color: #171717;
  border-bottom: none !important;
  width: 100% !important;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar .ui-dialog-title {
  width: 100% !important;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar .ui-dialog-title .widget-header {
  text-align: left;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar .ui-dialog-title .shortDescription {
  text-align: center;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar .ui-dialog-titlebar-close {
  position: absolute;
  top: 25px;
  right: 5px;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar h5 {
  color: #fff !important;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar h1 {
  color: #fff !important;
  padding: 0 21px;
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar .ui-button {
  color: var(--theme-color-1);
  opacity: 1;
}
.smart-style-1 .serviceTheme .ui-dialog-content {
  background-color: #171717 !important;
}
.smart-style-1 .serviceTheme .ui-dialog-content .service_modal {
  background-color: #171717 !important;
}
.smart-style-1 .serviceTheme .ui-dialog-content .service_modal .modal-content {
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
  border: unset !important;
  background-color: #171717 !important;
}
.smart-style-1 .serviceTheme .chip_Section {
  border: 1px solid #333333;
  color: #fff !important;
  margin-left: 13px !important;
}
.smart-style-1 .serviceTheme .serviceSection:hover {
  background-color: #303030 !important;
}
.smart-style-1 .serviceTheme .toggleMore {
  background-color: #707070 !important;
}
.smart-style-1 .serviceTheme .serachService {
  border: 1px solid #333333;
}
.smart-style-1 .select-label {
  color: #fff !important;
}
.smart-style-1 .menuSelected {
  background-color: #333333;
}
.smart-style-1 .model-7 .checkbox label:after {
  background: #000 !important;
}
.smart-style-1 .inboxHeads {
  background-color: #171717 !important;
  font: normal normal bold 13px/18px Open Sans;
  border: none;
}
.smart-style-1 .progress {
  background-color: #333333;
  box-shadow: none;
}
.smart-style-1 input:-webkit-autofill,
.smart-style-1 input:-webkit-autofill:hover,
.smart-style-1 input:-webkit-autofill:focus,
.smart-style-1 input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
  -webkit-box-shadow: 0 0 0 30px #0C1214 inset !important;
}
.smart-style-1 .theme-bg-colors-border {
  background-color: #202124 !important;
  box-shadow: unset !important;
}
.smart-style-1 .headerContent {
  border-radius: 10px;
  border: 1px solid #333333;
  background-color: #202124 !important;
}
.smart-style-1 .pagination > .active > a {
  background-color: var(--theme-color-1) !important;
  border-color: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
  border-color: #333333;
  z-index: 0;
}
.smart-style-1 .pagination > li > a {
  background-color: transparent !important;
  color: #fff;
  border-color: #333333;
}
.smart-style-1 .pagination > li:first-child > a,
.smart-style-1 .pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-color: transparent !important;
  color: #fff;
  border-color: #333333;
}
.smart-style-1 .pagination > li:last-child > a,
.smart-style-1 .pagination > li:last-child > span {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: transparent !important;
  border-color: #333333;
}
.smart-style-1 .setBackground {
  border-radius: 10px;
  border: 1px solid #333333;
}
.smart-style-1 .widgetBorder {
  border-radius: 10px;
  border: 1px solid #333333;
  overflow: hidden;
  background-color: #303030 !important;
}
.smart-style-1 .upgradebtn {
  color: #fff !important;
}
.smart-style-1 .algntblTranscation {
  border-radius: 8px;
  border: 1px solid #333333 !important;
  overflow: hidden;
  background: #202124 !important;
}
.smart-style-1 .headerContents {
  border-radius: 8px;
  border: 1px solid #333333 !important;
  overflow: hidden;
  background: #202124;
}
.smart-style-1 .modalBodyBgColor {
  background-color: #0C1214 !important;
}
.smart-style-1 .theme-icon {
  color: #fff !important;
}
.smart-style-1 .border-line-none {
  border: none;
  background-color: #202124 !important;
}
.smart-style-1 .setting-menu #header .settingsMenu {
  background: #333333 !important;
}
.smart-style-1 .note-table-popover,
.smart-style-1 .note-image-popover,
.smart-style-1 .note-link-popover {
  display: none;
}
.smart-style-1 .ticketPopupImportant {
  max-height: calc(100% - 100px);
  overflow-y: auto;
  border-radius: 10px;
}
.smart-style-1 .ticketPopupImportant::-webkit-scrollbar {
  width: 0px;
}
.smart-style-1 .btn-primary {
  background: transparent !important;
  border: 2px solid var(--theme-color-1);
  color: #fff !important;
  padding: 3px 15px;
  border-radius: 15px;
  font-size: 13px;
}
.smart-style-1 .btn-primary:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
  border: 2px solid var(--theme-color-1);
}
.smart-style-1 .ebill_btn {
  background: transparent !important;
  border: 2px solid var(--theme-color-1);
  color: #fff !important;
  padding: 3px 15px;
  border-radius: 15px;
  font-size: 13px;
  line-height: 20px;
}
.smart-style-1 .ebill_btn:hover {
  background: var(--theme-color-1) !important;
  color: #000 !important;
  border: 2px solid var(--theme-color-1);
}
.smart-style-1 .nav-pills > li.active > a {
  background: #202124 !important;
  border: 2px solid var(--theme-color-1);
  color: #fff;
  margin-top: 5px;
}
.smart-style-1 .nav-pills > li.active > a:hover {
  background: var(--theme-color-1) !important;
  color: #000 !important;
  border: 2px solid var(--theme-color-1);
}
.smart-style-1 .btnModalSave {
  float: right;
  background-color: #202124;
  border: none;
  color: #fff !important;
  border-radius: 15px;
  border: 2px solid var(--theme-color-1);
}
.smart-style-1 .btnModalClose {
  float: left;
  background-color: #202124;
  border: none;
  color: #fff !important;
  border-radius: 15px;
  border: 2px solid var(--theme-color-1) !important;
}
.smart-style-1 .emptyCol {
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  background-color: #202124 !important;
  border-right: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}
.smart-style-1 .currentPlan {
  background-color: #CCCCCC !important;
  color: #202528 !important;
}
.smart-style-1 .currentPlan:hover {
  background: #CCCCCC !important;
}
.smart-style-1 .currentButton {
  background-color: #404040 !important;
  color: #666666 !important;
  border-color: #333333 !important;
  cursor: not-allowed;
  z-index: -1;
}
.smart-style-1 .model-7 .checkbox label:after {
  background: #000 !important;
}
.smart-style-1 .selectButton {
  border: 2px solid var(--theme-color-1);
  border-radius: 15px;
  padding: 5px 20px;
  background-color: #202124;
  border-color: var(--theme-color-1);
  font-size: 14px;
  outline: none;
}
.smart-style-1 .selectButton:hover {
  background: var(--theme-color-1) !important;
  color: #000 !important;
  border: 1px solid #3B3B3B33;
}
.smart-style-1 .rightBorder {
  border-right: 2px solid var(--theme-color-1) !important;
  border-left: 2px solid var(--theme-color-1) !important;
}
.smart-style-1 .topBorder {
  border-top: 2px solid var(--theme-color-1) !important;
}
.smart-style-1 .bottomBorder {
  border-bottom: 2px solid var(--theme-color-1) !important;
}
.smart-style-1 .cancel {
  text-align: left;
  width: auto;
  border-radius: 15px;
  background: #202124 0% 0% no-repeat padding-box !important;
  border: 2px solid var(--theme-color-1) !important;
  font: normal normal normal 13px/24px Open Sans;
  padding: 3px 15px;
}
.smart-style-1 .TotalLabel {
  top: 275px;
  left: 560px;
  height: 49px;
  text-align: left;
  font: normal normal bold 31px/23px Open Sans;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  margin-left: -16px;
}
.smart-style-1 .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  background-color: #0C1214 !important;
  border-radius: 10px;
  margin-left: -1px;
}
.smart-style-1 .paymentOptions {
  display: none;
  background-color: #0C1214 !important;
  border-radius: 10px;
}
.smart-style-1 .lightBackgroundColor {
  border-radius: 10px;
  background-color: #202124 !important;
  border: 1px solid #333333;
  margin-left: 13px;
}
.smart-style-1 .pricingTable table {
  color: #fff !important;
}
.smart-style-1 .theme-bg-color {
  background-color: #202124 !important;
}
.smart-style-1 .theme-subtext-color {
  color: #959595 !important;
}
.smart-style-1 #content {
  background: #202124 !important;
}
.smart-style-1 .jarviswidget > div {
  background-color: #202124 !important;
  border: none;
}
.smart-style-1 .bootstrapWizard li.active .title,
.smart-style-1 .bootstrapWizard li.complete .title {
  color: #fff;
}
.smart-style-1 .bootstrapWizard li .step {
  background-color: #202124;
  border: 1px solid #333333;
  color: #eee;
}
.smart-style-1 .textColor {
  color: #fff !important;
}
.smart-style-1 .headData {
  background-color: #0C1214;
  color: #fff;
  margin-top: 10px;
  background-image: none;
}
.smart-style-1 .modal-body {
  background-color: #171717 !important;
}
.smart-style-1 .modal-content {
  background-color: #171717 !important;
  border: 1px solid #333333;
  color: #fff;
}
.smart-style-1 .interConnContent {
  background-color: #171717 !important;
}
.smart-style-1 .modal-footer {
  padding: 20px 0px 0px 0px;
  border-top: none;
}
.smart-style-1 .back-btn {
  background: #073E84 0% 0% no-repeat padding-box;
  border-color: #073E84;
  font-size: 13px;
}
.smart-style-1 .jarviswidget-color-darken > header {
  background: #202124 !important;
  border-color: #303030 !important;
}
.smart-style-1 .buy-addon .navigationList .nav.nav-tabs li a span {
  color: #fff !important;
}
.smart-style-1 .buy-addon .navigationList .nav.nav-tabs li a:hover {
  background-color: var(--theme-color-1) !important;
  border: 1px solid var(--theme-color-1) !important;
}
.smart-style-1 .buy-addon .navigationList .nav.nav-tabs li a:hover span {
  color: #000 !important;
}
.smart-style-1 .buy-addon .navigationList .nav.nav-tabs li.active a span {
  color: #000 !important;
}
.smart-style-1 .NextconfirmLabel {
  color: #fff;
  border: 1px solid #333333;
}
.smart-style-1 .buy-addon .checkout {
  background-color: #202528;
}
.smart-style-1 .table-headData {
  background-color: #0C1214;
  color: #fff;
  margin-top: 10px;
}
.smart-style-1 .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #202124 !important;
  border: 1px solid #333333;
  font-size: 14px;
  color: #fff !important;
}
.smart-style-1 .commoninputStyle textarea.form-control {
  height: unset !important;
}
.smart-style-1 .child .dtr-details .dtr-title {
  color: #E2E2E2 !important;
}
.smart-style-1 .child .dtr-details .dtr-data {
  color: #E2E2E2 !important;
}
.smart-style-1 .btn-primary:not(:disabled):not(.disabled):active {
  color: #fff !important;
}
.smart-style-1 .tileContainer .commonTile .userDash_tileHead,
.smart-style-1 .tileContainer .commonTile .clickLbl {
  color: #fff;
  font-weight: 500;
  font-size: 13px;
}
.smart-style-1 .tileContainer .commonTile .userDash_tileHeadgreen,
.smart-style-1 .tileContainer .commonTile .clickLblgreen {
  color: #fff;
  font-weight: 500;
}
.smart-style-1 .lstTransatiosectionHead {
  color: #fff;
}
.smart-style-1 .fistTileNote,
.smart-style-1 .secondTileNote,
.smart-style-1 .ediSectionHead,
.smart-style-1 .sampleediSectionHead {
  color: #fff;
  font-weight: 600;
}
.smart-style-1 .commonLblEdisection {
  color: #fff;
}
.smart-style-1 .commonTile,
.smart-style-1 .leftRowSection,
.smart-style-1 .rightediSetion,
.smart-style-1 .tileFinal0,
.smart-style-1 .tileFinal1,
.smart-style-1 .rightFinalSection,
.smart-style-1 .commonBorderColor,
.smart-style-1 .dropSelect {
  border: 1px solid #333333 !important;
}
.smart-style-1 .dashimgDiv {
  background-repeat: no-repeat !important;
  background-position: center !important;
  height: 100px;
  width: 100px;
}
.smart-style-1 .previewImg {
  background: url("/assets/img/iconSvg/dashboardBlack.png");
}
.smart-style-1 .toolsimg {
  background: url("/assets/img/iconSvg/toolsBlack.png");
}
.smart-style-1 .tile-formbtn {
  background-color: unset !important;
  color: #fff !important;
  border: 2px solid var(--theme-color-1) !important;
}
.smart-style-1 .tile-formbtn:hover {
  background-color: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
}
.smart-style-1 .tile1-formbtn {
  background-color: #212529 !important;
  color: #fff !important;
  border: 2px solid var(--theme-color-1) !important;
}
.smart-style-1 .tile1-formbtn:hover {
  background-color: #fff !important;
  border: 1px solid var(--theme-color-1) !important;
  color: #000 !important;
}
.smart-style-1 .NextconfirmLabels {
  font: normal normal normal 13px/24px Open Sans;
  letter-spacing: 0px;
  color: #fff;
  margin-right: 15px;
  padding: 4px 10px;
  border: 1px solid #666666;
  border-radius: 20px;
  margin-top: -7px;
  margin-left: 10px;
}
.smart-style-1 .graphsvgIcons {
  filter: brightness(100);
}
.smart-style-1 .code {
  color: #fff !important;
  background-color: #0C1214 !important;
}
.smart-style-1 .bottom-formbtns {
  background-color: unset !important;
  border: 2px solid var(--theme-color-1) !important;
  color: #fff !important;
}
.smart-style-1 .bottom-formbtns:hover {
  background: var(--theme-color-1) !important;
  color: #202528 !important;
}
.smart-style-1 .dateHeadingLbl,
.smart-style-1 .filterSpan {
  color: #fff;
}
.smart-style-1 .colorWhite {
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(202deg) brightness(105%) contrast(101%) !important;
}
.smart-style-1 .badge-disable-iom {
  color: #fff !important;
}
.smart-style-1 .sub-btn {
  border: 2px solid var(--theme-color-1) !important;
  color: #fff !important;
  font-weight: bold;
}
.smart-style-1 .sub-btn.freeButton {
  border-color: #333 !important;
}
.smart-style-1 .sub-btn.freeButton:hover {
  background-color: transparent !important;
  border-color: #333 !important;
  color: #fff !important;
}
.smart-style-1 .sub-btn.futurePlanBtn {
  border-color: var(--theme-color-1) !important;
  background-color: var(--theme-color-1) !important;
  color: #000 !important;
}
.smart-style-1 .sub-btn.futurePlanBtn:hover {
  background-color: transparent !important;
  border-color: #333 !important;
  color: #fff !important;
}
.smart-style-1 .sub-btn.currentPlanBtn {
  border-color: var(--theme-color-1) !important;
  background-color: var(--theme-color-1) !important;
  color: #000 !important;
}
.smart-style-1 .sub-btn.currentPlanBtn:hover {
  background-color: transparent !important;
  border-color: #333 !important;
  color: #fff !important;
}
.smart-style-1 .sub-btn:hover {
  background: var(--theme-color-1) !important;
  color: #0C1214 !important;
}
.smart-style-1 .jarviswidget > header h2 {
  color: #fff !important;
}
.smart-style-1 .owl-dt-calendar-control {
  color: #fff !important;
}
.smart-style-1 .owl-dt-container {
  background: #202124 !important;
  border: #202124 !important;
  border-radius: 10px !important;
}
.smart-style-1 .owl-dt-calendar-table .owl-dt-calendar-header {
  color: #666666 !important;
}
.smart-style-1 .owl-dt-calendar-table .owl-dt-calendar-cell {
  color: #666666 !important;
}
.smart-style-1 .owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: #171819 !important;
  border: #666666 !important;
}
.smart-style-1 .filter-top {
  color: #fff !important;
}
.smart-style-1 .lightTextColor {
  color: #ddd;
}
.smart-style-1 .settings-list .settings-list-items-contents {
  background-color: #0C1214 !important;
  border: 1px solid #333333 !important;
}
.smart-style-1 .settings-list-row {
  color: #fff;
}
.smart-style-1 .settings-list-row .list-group-item {
  background-color: transparent !important;
}
.smart-style-1 .settings-list-row .list-group-item:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
}
.smart-style-1 .spinnerClass {
  color: #fff;
}
.smart-style-1 .MessageBoxButtonSection button {
  color: #fff;
}
.smart-style-1 .modal-body {
  background-color: #171717 !important;
}
.smart-style-1 .modal-content {
  background-color: #171717 !important;
  border: 1px solid #333333;
  color: #fff;
  border-radius: 10px;
}
.smart-style-1 .modal-footer {
  padding: 20px 0px 0px 0px;
  border-top: none;
}
.smart-style-1 .border {
  border: 1px solid #333333 !important;
  border-radius: 10px;
}
.smart-style-1 .border .editConfigSection {
  background-color: #171819 !important;
}
.smart-style-1 .joyride-step__holder {
  background-color: #171717 !important;
  box-shadow: 0 0 0px 1px #171717 !important;
}
.smart-style-1 .joyride-step__holder .joyride-step__container {
  background-color: #171717 !important;
  border: 1px solid #333333 !important;
  box-shadow: 0 0 0px 1px #171717 !important;
}
.smart-style-1 .joyride-step__holder .joyride-arrow__top {
  border-bottom: 11px solid #666 !important;
}
.smart-style-1 .joyride-step__holder .joyride-arrow__right {
  border-left: 11px solid #666 !important;
}
.smart-style-1 .joyride-step__holder .joyride-arrow__left {
  border-right: 11px solid #666 !important;
}
.smart-style-1 .joyride-step__holder .joyride-arrow__bottom {
  border-top: 11px solid #666 !important;
}
.smart-style-1 .joyride-step__holder .joyride-button {
  color: #fff !important;
}
.smart-style-1 .joyride-step__holder .joyride-button:hover {
  background-color: var(--theme-color-1) !important;
  color: #111 !important;
}
.smart-style-1 .joyride-step__holder .joyride-step__title,
.smart-style-1 .joyride-step__holder .joyride-step__body {
  color: #fff !important;
}
.smart-style-1 .manageHeader,
.smart-style-1 .comapnyHeaderLbl {
  color: #fff !important;
}
.smart-style-1 .iom_btn {
  color: #fff;
}
.smart-style-1 .error_tab {
  color: #fff !important;
}
.smart-style-1 .error_text {
  color: #fff !important;
}
.smart-style-1 textarea.in {
  color: #fff;
  background-color: #0C1214;
  border: 1px solid #333333;
}
.smart-style-1 textarea.modal-in {
  color: #fff;
  background-color: #0C1214;
  border: 1px solid #333333;
}
.smart-style-1 .tour-title-input {
  color: #fff;
  background-clip: padding-box;
  background-color: #0C1214;
  border: 1px solid #333333;
}
.smart-style-1 .modal-title-input {
  color: #fff;
  background-clip: padding-box;
  background-color: #0C1214;
  border: 1px solid #333333;
}
.smart-style-1 .successcmpimgDiv {
  background-color: #0C1214;
}
.smart-style-1 .defaultDot {
  background-color: white;
}
.smart-style-1 .statusSection tr td:not(:last-child) {
  border-right: 2px solid #ffff;
}
.smart-style-1 .successDiv {
  background-color: #171717;
}
.smart-style-1 .successDiv-status {
  background-color: #171717;
}
.smart-style-1 .successdeleteDiv {
  background-color: #171717;
}
.smart-style-1 .theme-content-color {
  color: #FFFFFF;
}
.smart-style-1 #wizard {
  background-color: !important;
  color: #FFFFFF;
}
.smart-style-1 #wizard .line .line-1 {
  background: #666666;
}
.smart-style-1 #wizard .line11 .line-1 {
  background: transparent;
}
.smart-style-1 #wizard .wizard-items {
  border: 1px solid #333333;
  background: #202124 0% 0% no-repeat padding-box;
}
.smart-style-1 #wizard .wizard-items:hover {
  background: #171819 0% 0% no-repeat padding-box;
}
.smart-style-1 #wizard .wizard-items .wizardDiv {
  border: 1px solid #404040;
  background: #404040 0% 0% no-repeat padding-box;
}
.smart-style-1 #wizard b.badges {
  border: 1px solid #202124;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}
.smart-style-1 #wizard .wizard-billeritem {
  border: 1px solid #333333;
  background: #202124 0% 0% no-repeat padding-box;
}
.smart-style-1 #wizard .wizard-billeritem .wizardDiv {
  border: 1px solid #404040;
  background: #404040 0% 0% no-repeat padding-box;
}
.smart-style-1 .accordianClass {
  color: #FFFFFF;
}
.smart-style-1 b.badges {
  background-color: rgba(0, 0, 0, 0.5);
}
.smart-style-1 .warning-text {
  color: #fff !important;
}
.smart-style-1 nav ul ul li a {
  color: #fff;
  font-size: 14px !important;
  text-shadow: none !important;
}
.smart-style-1 nav ul ul li a:focus, .smart-style-1 nav ul ul li a:hover {
  color: #202427 !important;
  background: var(--theme-color-1) !important;
}
.smart-style-1 #left-panel nav ul.edi li:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
  border: 1px solid #3B3B3B33;
  border-bottom: 1px solid #3B3B3B33 !important;
}
.smart-style-1 #left-panel nav ul.ebill li:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
  border: 1px solid #3B3B3B33;
  border-bottom: 1px solid #3B3B3B33 !important;
}
.smart-style-1 nav ul li a {
  color: #fff !important;
  font-size: 16px !important;
}
.smart-style-1 nav ul li a:focus, .smart-style-1 nav ul li a:hover {
  color: var(--hovertext-color) !important;
  background: var(--theme-color-1) !important;
}
.smart-style-1 nav ul li.open a {
  color: #fff !important;
  font-weight: 400;
}
.smart-style-1 nav ul li.open:focus, .smart-style-1 nav ul li.open:hover {
  color: var(--hovertext-color) !important;
  background: var(--theme-color-1) !important;
}
.smart-style-1 nav ul li.open a b {
  color: #fff !important;
}
.smart-style-1 nav ul li.open.active a b {
  color: #202427 !important;
}
.smart-style-1 nav ul li.open:hover > a {
  color: #202427 !important;
}
.smart-style-1 nav ul li.open:hover > a b {
  color: #202427 !important;
}
.smart-style-1 nav ul ul li a {
  color: #fff;
  font-size: 16px !important;
  text-shadow: none !important;
  padding-left: 0px !important;
  padding-top: 7px !important;
  padding-bottom: 14px !important;
}
.smart-style-1 nav ul ul li a:focus, .smart-style-1 nav ul ul li a:hover {
  color: var(--hovertext-color) !important;
  background: var(--theme-color-1) !important;
}
.smart-style-1 nav ul.ebill li a:hover,
.smart-style-1 nav ul.ebill ul li a:hover,
.smart-style-1 nav ul.ebill li.open a:hover {
  background: var(--theme-color-1) !important;
}
.smart-style-1 nav ul li.open:hover > a {
  color: #202427 !important;
}
.smart-style-1 .dropzonbox {
  background-color: #171717 !important;
  border: 1px solid #3B424C !important;
}
.smart-style-1 .checkmark {
  border: 2px solid #666666;
}
.smart-style-1 .interConnPopupForm label {
  font-size: 14px !important;
}
.smart-style-1 .interConnPopupForm .form-control {
  border: 1px solid #666666;
  background-color: #202124 !important;
  border-radius: 5px !important;
}
.smart-style-1 .interConnPopupForm .invalid-feedback {
  font-size: 12px !important;
}
.smart-style-1 .interConnContent {
  background-color: #171717 !important;
}
.smart-style-1 .ebillFormFormly label,
.smart-style-1 .disableForm label,
.smart-style-1 .ebillForm label {
  color: #fff !important;
}
.smart-style-1 .ebillFormFormly .form-control,
.smart-style-1 .disableForm .form-control,
.smart-style-1 .ebillForm .form-control {
  background: transparent !important;
  border: 1px solid #333333;
  color: #fff;
  height: 40px !important;
  border-radius: 6px !important;
  font-size: 13px;
}
.smart-style-1 .companySelect .ng-select-container,
.smart-style-1 .company-select .ng-select-container {
  color: #fff;
  height: 42px !important;
  border: none;
}
.smart-style-1 .companySelect .ng-select-container input,
.smart-style-1 .company-select .ng-select-container input {
  color: #fff !important;
}
.smart-style-1 .sectorLbl {
  color: #fff !important;
}
.smart-style-1 .biller_tabSection .nav-item a {
  margin-right: unset !important;
  background-color: unset !important;
  color: #fff !important;
  border-bottom: unset !important;
  border-left-color: #666 !important;
  border-right-color: #666 !important;
  border-top-color: #666 !important;
}
.smart-style-1 .ebiller_tabSection .nav-item a {
  background-color: unset !important;
  color: #fff !important;
  border-bottom: unset !important;
  border-color: #666 !important;
}
.smart-style-1 .billerDataSection .nav-item a:hover {
  color: #fff !important;
}
.smart-style-1 .docInput {
  width: 100%;
  margin-right: 10px;
  border-radius: 10px !important;
  background-color: #0C1214 !important;
  border: unset !important;
}
.smart-style-1 .docLbl {
  width: 100%;
  color: #fff;
  font-size: 14px;
}
.smart-style-1 .nav-tabs {
  border-bottom: 1px solid #666;
  border-color: 1px solid #666 !important;
}
.smart-style-1 .communicationPanel .communicationBodyWrap {
  border: 1px solid #666;
}
.smart-style-1 .filelabel:hover i,
.smart-style-1 .filelabel:hover .title {
  color: #fff !important;
}
.smart-style-1 .panelSector .ng-select .ng-select-container {
  background-color: transparent !important;
  color: #000 !important;
  border: 1px solid #333333 !important;
}
.smart-style-1 .detail_save_btn {
  color: #fff;
}
.smart-style-1 .billTabSection {
  border: 1px solid #333333;
}
.smart-style-1 .emailMessage,
.smart-style-1 .ediEmailMessage {
  border: 1px solid #3B424C !important;
}
.smart-style-1 #header.colorChangeTest .header-btn {
  background-color: transparent;
  box-shadow: none;
}
.smart-style-1 #header.colorChangeTest .header-btn:hover {
  background-color: #1E1E1E;
}
.smart-style-1 #header.colorChangeTest .dropdown-icon-menu:hover .header-btn {
  background-color: #1E1E1E;
}
.smart-style-1 .companySelect .ng-select-container {
  background: #202124 !important;
  border: 1px solid #6666;
}
.smart-style-1 .companySelect .ng-select-container .ng-placeholder {
  font-size: 13px;
}
.smart-style-1 .companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #666 !important;
  border: 1px solid #6666 !important;
  font-size: 13px !important;
}
.smart-style-1 .companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #202124 !important;
  color: #fff;
  font-size: 13px !important;
}
.smart-style-1 .companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: #202124 !important;
}
.smart-style-1 .companySelect .requiredSector {
  color: #dc3545;
}
.smart-style-1 .companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.smart-style-1 .companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #beeb20 !important;
  font-size: 13px !important;
}
.smart-style-1 .internav-tabs > li > a {
  color: #fff !important;
}
.smart-style-1 .nav-tabs > li.active > a.display-border,
.smart-style-1 .nav-tabs > li.active > a.display-border:focus,
.smart-style-1 .nav-tabs > li.active > a.display-border:hover {
  background-color: #171717 !important;
  border-top: 2px solid var(--theme-color-1) !important;
  border-left: 2px solid var(--theme-color-1) !important;
  border-right: 2px solid var(--theme-color-1) !important;
  color: #fff;
  box-shadow: none;
}
.smart-style-1 .nav-tabs.invoice-tab > li {
  border: 1px solid #333333 !important;
  border-top: none !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li:hover {
  border: 1px solid #7c7b7b !important;
  border-top: none !important;
  background-color: #7c7b7b !important;
  box-shadow: 0 -2px 0 #7c7b7b !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li > a.invoice-tabhead {
  color: #fff !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li > a:hover {
  color: #fff !important;
  background-color: #7c7b7b !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li > a:focus {
  color: #fff !important;
  background-color: #7c7b7b !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li.active {
  border: none !important;
  box-shadow: 0 -2px 0 var(--theme-color-1) !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li.active:hover {
  background-color: transparent !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead {
  background-color: #202124 !important;
  color: #fff !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead:focus {
  background-color: #202124 !important;
  color: #fff !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead:hover {
  background-color: #202124 !important;
  color: #fff !important;
}
.smart-style-1 .invoice-tab-header {
  border: 1px solid #333333 !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  background-color: #202124 !important;
  color: #fff !important;
}
.smart-style-1 .tooltipA .tooltiptext {
  background: #171717 0% 0% no-repeat padding-box;
  color: #E2E2E2;
  border: 1px solid #333333;
}
.smart-style-1 .connector-form .inputSection .form-control,
.smart-style-1 .connector-form .selectSection .form-control {
  background-color: #0C1214 !important;
  border: 1px solid #0C1214 !important;
}
.smart-style-1 .borderHead {
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid #333333 !important;
}
.smart-style-1 .styleBackground {
  border: 1px solid #333333 !important;
}
.smart-style-1 .businessField,
.smart-style-1 .bp_connections,
.smart-style-1 .bp_connectorStatus {
  border: 1px solid #333333 !important;
}
.smart-style-1 .border-style {
  border: 1px solid #333333 !important;
}
.smart-style-1 .app-tut.active {
  background-color: #7c7b7b !important;
}
.smart-style-1 .app-tut {
  background-color: #202124;
}
.smart-style-1 .app-tut:not(.nohover):hover {
  background-color: #7c7b7b !important;
}
.smart-style-1 .dopzoneTxt {
  color: #fff;
}
.smart-style-1 .fuelux .wizard {
  background-color: transparent !important;
  border-color: #333333;
}
.smart-style-1 .approveBtn {
  color: #fff;
}
.smart-style-1 .fuelux .wizard ul li {
  position: relative;
  float: left;
  height: 46px;
  padding: 0 20px 0 30px;
  margin: 0;
  font-size: 16px;
  line-height: 46px;
  color: #fff;
  cursor: default;
  background: #7c7b7b;
}
.smart-style-1 .fuelux .wizard ul li.active {
  position: relative;
  float: left;
  height: 46px;
  padding: 0 20px 0 30px;
  margin: 0;
  font-size: 16px;
  line-height: 46px;
  color: #fff;
  cursor: default;
  background: #171717;
}
.smart-style-1 .fuelux .wizard .actions {
  position: absolute;
  right: 0;
  z-index: 2;
  float: right;
  padding-right: 15px;
  padding-left: 15px;
  line-height: 46px;
  vertical-align: middle;
  background-color: #171717;
  border-left: 1px solid #3B424C;
}
.smart-style-1 .fuelux .wizard ul li .chevron:before {
  position: absolute;
  top: -24px;
  right: 1px;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
  border-left: 14px solid #171717;
  content: "";
}
.smart-style-1 .fuelux .wizard ul li {
  position: relative;
  float: left;
  height: 46px;
  padding: 0 20px 0 30px;
  margin: 0;
  font-size: 16px;
  line-height: 46px;
  color: #fff;
  cursor: default;
  background: #171717;
}
.smart-style-1 .fuelux .wizard ul li.active {
  color: #000;
  background: var(--theme-color-1);
}
.smart-style-1 .fuelux .wizard ul li.active .chevron:before {
  border-left: 14px solid var(--theme-color-1);
}
.smart-style-1 .snd-rcv-div.active {
  background-color: var(--theme-color-1) !important;
}
.smart-style-1 .snd-rcv-div.active .snd-rcv-img {
  filter: brightness(0) invert(0) !important;
}
.smart-style-1 .snd-rcv-div.active .snd-rcv-img img {
  filter: brightness(0) invert(1) !important;
}
.smart-style-1 .snd-rcv-div.active .snd-rcv-title {
  color: #000 !important;
}
.smart-style-1 .snd-rcv-div {
  border: 1px solid var(--theme-color-1) !important;
}
.smart-style-1 .snd-rcv-div .snd-rcv-img {
  filter: brightness(0) invert(1) !important;
}
.smart-style-1 .snd-rcv-div .snd-rcv-img img {
  filter: brightness(0) invert(1) !important;
}
.smart-style-1 .snd-rcv-div:hover {
  background-color: var(--theme-color-1) !important;
}
.smart-style-1 .snd-rcv-div:hover .snd-rcv-img {
  filter: brightness(0) invert(0) !important;
}
.smart-style-1 .snd-rcv-div:hover .snd-rcv-img img {
  filter: brightness(0) invert(0) !important;
}
.smart-style-1 .snd-rcv-div:hover .snd-rcv-title {
  color: #000 !important;
}
.smart-style-1 #left-panel nav ul li {
  border-bottom: 1px solid var(--theme-color-1);
}
.smart-style-1 #left-panel nav ul li:hover {
  background: var(--theme-color-1) !important;
  color: #000 !important;
  border: 1px solid #3B3B3B33;
  border-bottom: 1px solid #3B3B3B33 !important;
}
.smart-style-1 #left-panel nav ul li:hover a {
  color: #000 !important;
}
.smart-style-1 #left-panel nav ul li:hover ul li a {
  color: #fff !important;
}
.smart-style-1 #left-panel nav ul li:hover ul li:hover {
  color: #000 !important;
}
.smart-style-1 #left-panel nav ul li.active {
  background-color: var(--theme-color-1) !important;
}
.smart-style-1 #left-panel nav ul li.active a {
  color: var(--hovertext-color) !important;
}
.smart-style-1 #left-panel nav ul li.active ul li {
  color: #202427 !important;
}
.smart-style-1 #left-panel nav ul li.active ul li:hover a {
  color: var(--hovertext-color) !important;
  background: transparent !important;
}
.smart-style-1 #left-panel nav ul li.active ul li a {
  color: #fff !important;
  font-weight: 400 !important;
}
.smart-style-1 #left-panel nav ul li.active ul li.active a {
  color: #202427 !important;
}
.smart-style-1 #left-panel nav ul li.open:hover a b {
  color: #000 !important;
}
.smart-style-1 #left-panel nav ul li.open li:hover a {
  color: #202427 !important;
}
.smart-style-1 #left-panel nav ul li.open a b {
  color: #ffffff !important;
}
.smart-style-1 #left-panel nav ul li.open li.open.active a b {
  color: #202427 !important;
}
.smart-style-1 #left-panel nav ul li.open li.open:hover > a {
  color: #202427 !important;
}
.smart-style-1 #left-panel nav ul li.open li.open:hover > a b {
  color: #202427 !important;
}
.smart-style-1 #left-panel nav ul li.open.active a b {
  color: #202427 !important;
}
.smart-style-1 table.bulkTable {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border: 1px solid #666666 !important;
  border-radius: 5px;
}
.smart-style-1 table.bulkTable {
  margin-top: 6px !important;
  margin-bottom: 0px !important;
  border: 1px solid #333333 !important;
  border-radius: 5px;
  color: #fff;
}
.smart-style-1 .archiveImport {
  background: #202124;
  color: #fff;
}
.smart-style-1 .filter-top .link {
  color: var(--theme-color-1) !important;
}
.smart-style-1 .subscriptions-pdf .sub-items {
  border: 1px solid #333;
}
.smart-style-1 .price_calcultation_form .dropdown-list {
  background-color: #202124 !important;
  box-shadow: 0 1px 5px #171717 !important;
  border: 1px solid #707070 !important;
}
.smart-style-1 .price_calcultation_form .multiselect-item-checkbox input[type=checkbox] + div {
  color: #fff !important;
}
.smart-style-1 .price_calcultation_form .item1 .multiselect-item-checkbox:hover {
  background-color: #7c7b7b !important;
}
.smart-style-1 .price_calcultation_form .item1 .multiselect-item-checkbox:hover input[type=checkbox] + div {
  color: #111 !important;
}
.smart-style-1 .price_calcultation_form .item2 .multiselect-item-checkbox:hover {
  background-color: #7c7b7b !important;
}
.smart-style-1 .price_calcultation_form .multiselect-dropdown .dropdown-btn {
  border: 1px solid #333 !important;
  background-color: #202124 !important;
}
.smart-style-1 .userEditTextBox {
  background: #202124 0% 0% no-repeat padding-box !important;
  color: #fff;
  border-radius: 10px !important;
}
.smart-style-1 .plan-title {
  color: var(--theme-color-1) !important;
}
.smart-style-1 .target-highlight, .smart-style-1 .target-highlightPdf {
  border-color: var(--theme-color-1) !important;
}
.smart-style-1 .invoice-div .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  background-color: #171717 !important;
  border-radius: 10px;
  margin-left: -1px;
}
.smart-style-1 .invoice-div .paymentOptions {
  display: none;
  border-radius: 10px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  background-color: #171717 !important;
  border-radius: 10px;
  margin-left: -1px;
}
.smart-style-1 .invoice-div .paymentOptions {
  display: none;
  border-radius: 10px;
}
.smart-style-1 .invoice-div .currentButton {
  background-color: #F7F7F7 !important;
  color: #000 !important;
  border-color: #CCCCCC !important;
  cursor: not-allowed;
  z-index: 1;
}
.smart-style-1 .invoice-div .currentPlan {
  background-color: #CCCCCC !important;
  color: #202528 !important;
}
.smart-style-1 .invoice-div .currentPlan:hover {
  background: #CCCCCC !important;
}
.smart-style-1 .inv-img {
  content: url('invoice_orange.449323bf104736ae3fe4.svg') !important;
}
.smart-style-1 .payment-detail {
  width: 100%;
  max-width: 1010px;
  background-color: #171717;
  margin: auto;
  padding: 10px 10px 6px 10px;
}
.smart-style-1 .underline {
  border-color: #333333;
}
.smart-style-1 .plan-stat {
  border-left: 1px solid #333333;
}
.smart-style-1 .plan-stat:first-of-type {
  border-bottom: 1px solid #333333;
}
.smart-style-1 .cursor-pointer {
  cursor: pointer !important;
}
.smart-style-1 .fieldForm .form-control {
  border: 1px solid #333333;
  color: #fff !important;
}
.smart-style-1 .durationDetailFields {
  background-color: #171717 !important;
}
.smart-style-1 .durationDetailFields .fieldForm .commonFormField input {
  background-color: #171717 !important;
}
.smart-style-1 .durationDetailFields .fieldForm .commonFormField textarea#exampleFormControlTextarea1 {
  background-color: #171717 !important;
}
.smart-style-1 .module .module-name {
  color: #fff !important;
}
.smart-style-1 .company-label {
  color: #fff !important;
}
.smart-style-1 .iom_btn:hover {
  color: var(--hovertext-color) !important;
}
.smart-style-1 .crossBtn {
  color: var(--theme-color-1) !important;
}
.smart-style-1 .ac_ConnectionSection .fillGreen {
  color: #000 !important;
}
.smart-style-1 .footer-menu {
  color: #fff !important;
}
.smart-style-1 .ac_ConnectionSection .ac_div {
  color: #fff !important;
}
.smart-style-1 .ac_ConnectionSection .ac_typeDiv {
  color: var(--theme-color-1) !important;
}
.smart-style-1 .ac_ConnectionSection .ac_typeDiv .common_detail {
  color: var(--theme-color-1) !important;
}
.smart-style-1 .chatIcon {
  filter: brightness(0) invert(1) !important;
}
.smart-style-1 .fillGreen {
  color: #212529 !important;
}
.smart-style-1 #overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
.smart-style-1 .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #fff !important;
}
.smart-style-1 #transaction-header .form-control::-moz-placeholder {
  color: #fff !important;
}
.smart-style-1 #transaction-header .form-control::placeholder {
  color: #fff !important;
}
.smart-style-1 .companyTooltip {
  background: #171717 0% 0% no-repeat padding-box;
  border: 1px solid #333333;
  color: #E2E2E2;
}
.smart-style-1 .checkmark {
  border: 2px solid #fff;
}
.smart-style-1 .checkbox-container .checkmark:after {
  left: 7px;
  top: 1px;
  width: 8px;
  height: 16px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.smart-style-1 .packageplanBtn {
  border-radius: 2px !important;
  transition: background-color 0.3s ease;
  padding: 8px 35px;
  font-size: 13px;
  color: #fff;
}
.smart-style-1 .packageplanBtn.active {
  color: #fff;
  background: var(--theme-color-1);
  border: solid var(--theme-color-1);
}
/*
---------------------------------------------------------------------------------------
    End  Dark theme style
---------------------------------------------------------------------------------------
*/
/*
---------------------------------------------------------------------------------------
    Default White theme style
---------------------------------------------------------------------------------------
*/
.smart-style-2 {
  /* #wizard1{
  	// background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
  	// border: 1px solid #666666;
  	color: map.get($whitetheme-colors, "textcolor");
  	.line-1{
  		background: #CCCCCC;
  	}
  	.wizard-items{
  		border: 1px solid #CCCCCC;
  		background: #fff 0% 0% no-repeat padding-box;
  		&:hover{
  			background: #ededed 0% 0% no-repeat padding-box;
  		}
  		.wizardDiv{
  			border: 1px solid transparent;
  			background: #cccccc 0% 0% no-repeat padding-box;
  			span{
  				color: #111;
  			}
  		}
  	}
  	.wizard-billeritem{
  		border: 1px solid #CCCCCC;
  		background: #fff 0% 0% no-repeat padding-box;

  		.wizardDiv{
  			border: 1px solid transparent;
  			background: #cccccc 0% 0% no-repeat padding-box;
  			span{
  				color: #111;
  			}
  		}
  	}
  	b.badges{
  		border: 1px solid #fff;
  		box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
  	}
  } */
  /* button#bot2-Msg1 {
  	color: #000 !important;
  } */
  /* price - calculation */
  /* price - calculation */
}
.smart-style-2 .setgreenFont {
  color: #474d22 !important;
}
.smart-style-2 .spinner-circle-swish {
  color: #252424 !important;
}
.smart-style-2 .upgradebtn {
  color: #577000 !important;
}
.smart-style-2 input:-webkit-autofill,
.smart-style-2 input:-webkit-autofill:hover,
.smart-style-2 input:-webkit-autofill:focus,
.smart-style-2 input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #0C1214 !important;
  -webkit-box-shadow: 0 0 0 30px #F7F7F7 inset !important;
}
.smart-style-2 .iconData {
  color: #4C4F53 !important;
}
.smart-style-2 .setCircleactive {
  color: #00eba9;
  font-size: 10px !important;
}
.smart-style-2 .setCirclerej {
  color: #eb0042;
  font-size: 10px !important;
}
.smart-style-2 .footerTextColor {
  color: #CCCCCC !important;
}
.smart-style-2 .login-info span.borderRightClr {
  border-right: 1px solid #CCCCCC !important;
}
.smart-style-2 #left-panel nav ul.edi li {
  border-bottom: 1px solid var(--theme-color-1);
}
.smart-style-2 #left-panel nav ul.edi li:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
  border: 1px solid #3B3B3B33;
}
.smart-style-2 #left-panel nav ul.edi li.active {
  background-color: var(--theme-color-1) !important;
}
.smart-style-2 #left-panel nav ul.edi li.active a:before {
  content: unset !important;
}
.smart-style-2 #left-panel nav ul.ebill li {
  border-bottom: 1px solid var(--theme-color-1);
}
.smart-style-2 #left-panel nav ul.ebill li:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
  border: 1px solid #3B3B3B33;
}
.smart-style-2 #left-panel nav ul.ebill li:hover a {
  color: #fff !important;
}
.smart-style-2 #left-panel nav ul.ebill li.active {
  background-color: var(--theme-color-1) !important;
  color: #fff !important;
}
.smart-style-2 #left-panel nav ul.ebill li.active a:before {
  content: unset !important;
}
.smart-style-2 #left-panel nav ul .active > a {
  color: #fff !important;
}
.smart-style-2 nav ul li.active > a:before {
  content: unset !important;
}
.smart-style-2 .setArrowBlue {
  color: #535353 !important;
}
.smart-style-2 table.borderline {
  border: 1px solid #CCCCCC !important;
}
.smart-style-2 .headClr {
  color: #0C1214 !important;
}
.smart-style-2 .inboxWidget {
  background: #fff 0% 0% no-repeat padding-box !important;
  border: none;
}
.smart-style-2 .detailDiv {
  background: #fff 0% 0% no-repeat padding-box !important;
  border: none;
}
.smart-style-2 table.positionTable {
  border: 1px solid #cccccc !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-radius: 5px;
}
.smart-style-2 .button-class {
  background-color: #fff !important;
  color: #7A7A7A !important;
}
.smart-style-2 .wid_content {
  border: none !important;
}
.smart-style-2 .font-14 {
  color: #000 !important;
}
.smart-style-2 .lstTrncnTbl .dt-toolbar {
  background: none !important;
  border-bottom: none;
}
.smart-style-2 .dt-toolbar .dataTables_filter {
  display: none !important;
}
.smart-style-2 .dt-toolbar .dataTables_length {
  display: none !important;
}
.smart-style-2 .dt-toolbar-footer {
  background: none !important;
  font-size: none !important;
  overflow: hidden;
  padding: none !important;
  border-top: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important;
  width: 100%;
}
.smart-style-2 .commonProgress {
  background-color: var(--theme-color-1) !important;
  color: #1E1E1E !important;
}
.smart-style-2 .btn.btn-ribbon {
  background: #9498A0;
  margin-left: 4px;
}
.smart-style-2 .line1 {
  border: 1px solid #F7F7F7 !important;
}
.smart-style-2 .setFontlabel {
  color: #073E84;
  font: normal normal bold 16px/22px Open Sans;
}
.smart-style-2 .setFonts {
  color: #959595;
}
.smart-style-2 .box-border1 {
  background-color: #fff !important;
  border: 1px solid #F7F7F7 !important;
  height: auto;
  width: 430px !important;
}
.smart-style-2 .goButtons {
  top: 189px;
  left: 1808px;
  width: 86px;
  height: 40px;
  background-color: #00B894 !important;
  text-align: center;
  letter-spacing: 0px;
  color: #fff !important;
  font: normal normal normal 13px/18px Open Sans;
}
.smart-style-2 .tblBsnsConnectionHead {
  color: #4C4F53 !important;
}
.smart-style-2 .labelColor {
  color: #0C1214 !important;
}
.smart-style-2 .stepsinout-form.formly .form-control {
  color: #0C1214 !important;
  background-color: #EEEEEE !important;
  border-color: #EEEEEE;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: border 0s ease-in-out 0s;
}
.smart-style-2 .businessconnect-form.formly .form-control {
  color: #0C1214 !important;
  background-color: #EEEEEE !important;
  border-color: #EEEEEE !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-2 .fileuploadform-form.formly .form-control {
  color: #0C1214 !important;
  background-color: #EEEEEE !important;
  border-color: #EEEEEE !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-2 .header-search-form.formly.horizontal .form-control {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .header-search-form.formly .input-group-append .input-group-text {
  background-color: #F7F7F7;
  border: unset !important;
}
.smart-style-2 .stepsinout-form .input-group-append .input-group-text {
  background-color: #EEEEEE;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.smart-style-2 .stepsinout-form .input-group-append .input-group-text::before {
  border-left: 1px solid #A2A2A2;
}
.smart-style-2 .businessconnect-form .input-group-append .input-group-text {
  background-color: #EEEEEE;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.smart-style-2 .businessconnect-form .input-group-append .input-group-text::before {
  border-left: 1px solid #A2A2A2;
}
.smart-style-2 .borderClr {
  border: unset !important;
}
.smart-style-2 .tableHeadBGclr {
  background: #EDEDED !important;
  border: none;
}
.smart-style-2 #inputState, .smart-style-2 .text-field {
  color: #7A7A7A !important;
}
.smart-style-2 .dashbaordlabel {
  color: #4C4F53 !important;
  display: block;
  width: 100%;
  height: 36px;
  font-size: 13px;
  color: #0C1214 !important;
  background-color: #F7F7F7 !important;
  background-clip: padding-box;
  border-color: #0C1214;
}
.smart-style-2 .hoverTable tbody tr:hover {
  color: #fff !important;
  background-color: #073E84 !important;
}
.smart-style-2 .tranhoverTable tbody tr:hover {
  color: #4C4F53 !important;
  background-color: #EDEDED !important;
  cursor: pointer;
}
.smart-style-2 #ribbon .breadcrumb li {
  color: #000 !important;
}
.smart-style-2 #ribbon .breadcrumb li:last-child {
  color: #000 !important;
}
.smart-style-2 #ribbon .breadcrumb li:before {
  color: #000 !important;
}
.smart-style-2 .intrTiles {
  background-color: #fff !important;
  border: unset !important;
}
.smart-style-2 .gridhead {
  color: #212529 !important;
}
.smart-style-2 nav ul li.active a:before {
  color: #202427;
}
.smart-style-2 nav ul li a {
  padding: 12px 17px 12px 16px !important;
}
.smart-style-2 nav ul li a i {
  font-size: 17px !important;
  vertical-align: 0 !important;
}
.smart-style-2 .blacktooltip {
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border: 1px solid #EDEDED;
  color: #4C4F53;
}
.smart-style-2 .blacktooltip .insideDivUl li:hover {
  background: #EDEDED;
}
.smart-style-2 .tooltip-text {
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border: 1px solid #EDEDED;
  color: #4C4F53;
}
.smart-style-2 .idTooltip,
.smart-style-2 .btnTooltip,
.smart-style-2 .errorTooltip {
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border: 1px solid #EDEDED;
  color: #4C4F53;
}
.smart-style-2 .progress {
  border-radius: 0px !important;
}
.smart-style-2 #left-panel {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
}
.smart-style-2 .InputCheck {
  color: #4C4F53 !important;
  font-size: 14px;
  margin-left: 21px;
}
.smart-style-2 .detailLbls {
  color: #4C4F53 !important;
  font-size: 18px;
}
.smart-style-2 .gridBGclr {
  background: #F7F7F7 !important;
}
.smart-style-2 .documentLbl {
  background: #4C4F53 !important;
  text-align: center;
  font: normal normal normal 13px/18px Open Sans;
  color: #fff !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.smart-style-2 .inboxtra {
  background-color: #fff !important;
}
.smart-style-2 .nav-tabs li {
  float: left;
  margin-bottom: -1px;
  width: 34%;
  text-align: center;
  border-bottom: 1px solid #CCCCCC !important;
}
.smart-style-2 .table.dataTable thead tr th.sorting_disabled {
  padding-right: 9px !important;
  border-color: #CCCCCC;
  vertical-align: top;
}
.smart-style-2 .InterData {
  color: #0C1214 !important;
  background-color: #fff !important;
  color: #0C1214 !important;
}
.smart-style-2 .headData {
  background-color: #EDEDED !important;
  color: #4C4F53 !important;
  border-color: #CCCCCC !important;
}
.smart-style-2 .header-dropdown-list .dropdown-menu li a:hover {
  background-color: #EDEDED !important;
}
.smart-style-2 .header-dropdown-list .dropdown-menu .active a {
  background-color: #EDEDED !important;
}
.smart-style-2 .header-dropdown-list a.dropdown-toggle {
  color: #4C4F53 !important;
}
.smart-style-2 .notification-body .msg-body {
  color: #7A7A7A !important;
}
.smart-style-2 .notification-body .activityTime {
  color: #7A7A7A !important;
}
.smart-style-2 .notification-body li span {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .notification-body .unread {
  background-color: #EDEDED;
}
.smart-style-2 .notification-body .from {
  color: #4C4F53 !important;
}
.smart-style-2 .ajax-dropdown {
  border: 1px solid #d9d9d9 !important;
}
.smart-style-2 #fullscreen .svg-icon {
  background-image: url('Path222.faed6ac4229dbddce176.svg');
}
.smart-style-2 #header .notification-svg {
  background-image: url('39-Notification.a91267442bae57b87b90.svg');
}
.smart-style-2 #header #activity.activity-svg {
  background-image: url('Page-1.06157606d3196914b46c.svg');
}
.smart-style-2 #header .collapseMenu {
  border: unset !important;
}
.smart-style-2 #header .collapseMenu .collaspe-menu-svg {
  background-image: url('Path 224.55a5f2da498c377ed45f.svg');
}
.smart-style-2 #header .collapseMenu .collaspe-menu-sub-svg {
  background-image: url('Group224.649ca2f656deeb39e0a7.svg');
  cursor: pointer;
}
.smart-style-2 #header .collapseMenu:hover {
  background-color: #CCCCCC;
}
.smart-style-2 #header .header-btn {
  box-shadow: none;
}
.smart-style-2 #header .header-btn-list a {
  box-shadow: none;
}
.smart-style-2 #header .menu-item:not(:last-child) {
  border: unset !important;
}
.smart-style-2 #header .menu-item .webComponetsBorder {
  border: none;
}
.smart-style-2 #header .menu-item:hover {
  background-color: #CCCCCC;
}
.smart-style-2 #header .menu-item .webComponetsHoverColor:hover {
  background-color: #CCCCCC;
}
.smart-style-2 #header .menu-item.no-hover:hover {
  background-color: #EDEDED;
}
.smart-style-2 #header .menu-left-item {
  border-left: 1px solid #CCCCCC;
}
.smart-style-2 .theme-icon {
  color: #4C4F53 !important;
}
.smart-style-2 .theme-text-color {
  color: #4C4F53 !important;
}
.smart-style-2 .theme-reverse-text-color {
  color: #fff !important;
}
.smart-style-2 .theme-header-text-color {
  color: #4C4F53 !important;
}
.smart-style-2 .theme-bg-color {
  background-color: #EDEDED !important;
}
.smart-style-2 .theme-subtext-color {
  color: #7A7A7A !important;
}
.smart-style-2 .dashboard .jarviswidget div {
  background-color: #F7F7F7 !important;
  border: 1px solid #CCCCCC !important;
}
.smart-style-2 .profileBackground {
  background-color: #F7F7F7 !important;
  border: 1px solid #CCCCCC !important;
}
.smart-style-2 .widBody .alignIconitems {
  background-color: #EDEDED !important;
  border: 8px solid #F7F7F7 !important;
}
.smart-style-2 .widBody .alignIconitems:hover {
  border: 8px solid #F7F7F7 !important;
}
.smart-style-2 .jarviswidget header {
  border: none !important;
}
.smart-style-2 .body-image {
  box-shadow: none !important;
}
.smart-style-2 header.subscriptionHead {
  background-color: #CCCCCC !important;
}
.smart-style-2 .bs-example {
  color: #0C1214 !important;
}
.smart-style-2 .headtabData {
  color: #4C4F53 !important;
  border: none;
}
.smart-style-2 .intertabData {
  color: #0C1214 !important;
  background-color: none;
}
.smart-style-2 .inboxHeads {
  background-color: #EDEDED !important;
  font: normal normal bold 13px/18px Open Sans;
  color: #0C1214 !important;
  border: none;
  border-radius: 5px 5px 0px 0px;
}
.smart-style-2 .transactionHeads {
  font: normal normal bold 13px/18px Open Sans;
  color: #4C4F53 !important;
}
.smart-style-2 .detailLbl {
  color: #0C1214 !important;
}
.smart-style-2 .lstLbl {
  color: #0C1214 !important;
}
.smart-style-2 .lblWhite {
  color: #0C1214 !important;
}
.smart-style-2 .edilabel {
  background: #F7F7F7 !important;
  color: #0C1214 !important;
  font: normal normal normal 13px/18px Open Sans;
}
.smart-style-2 .goButton {
  background: #00B894 !important;
  color: #fff !important;
  font: normal normal normal 13px/18px Open Sans;
}
.smart-style-2 .setBackground {
  background-color: #fff !important;
}
.smart-style-2 .periodlabel {
  background: #F7F7F7 !important;
  color: #0C1214 !important;
  font: normal normal normal 13px/18px Open Sans;
}
.smart-style-2 .commonHead {
  background: #fff !important;
  color: #0C1214 !important;
  font: normal normal normal 13px/18px Open Sans;
}
.smart-style-2 .headGreen {
  background-color: #739E73 !important;
  color: #fff;
}
.smart-style-2 .commonBackground {
  background-color: #F7F7F7;
  color: #4C4F53;
  height: 40px;
  border-color: #0C1214;
  font: normal normal normal 13px/18px Open Sans;
}
.smart-style-2 .commonBackground option {
  background-color: #F7F7F7;
  color: #4C4F53;
  height: 40px;
  border-color: #0C1214;
  font: normal normal normal 13px/18px Open Sans;
}
.smart-style-2 .setFontsub {
  color: #959595;
  font: normal normal bold 13px/18px Open Sans;
}
.smart-style-2 .notification-body .subject {
  color: #7A7A7A !important;
}
.smart-style-2 .dashboardHead .jarviswidget-ctrls .button-icon {
  border-left: unset !important;
}
.smart-style-2 .icon {
  padding: 10px;
  color: #0C1214;
  min-width: 50px;
  text-align: end;
  right: 15px;
  top: 8px;
  font-size: 14px;
}
.smart-style-2 nav ul li a {
  color: #fff !important;
  font-size: 14px !important;
}
.smart-style-2 nav ul li a:focus, .smart-style-2 nav ul li a:hover {
  background: var(--theme-color-1) !important;
}
.smart-style-2 .internav-tabs > li.active > a {
  color: #fff !important;
  font-size: 14px !important;
  border-bottom: 2px solid var(--theme-color-1);
  border-top: 2px solid var(--theme-color-1);
}
.smart-style-2 tbody tr.child td {
  background-color: #EDEDED;
  color: #4C4F53 !important;
}
.smart-style-2 .ui-datepicker td .ui-state-default {
  color: #707070;
}
.smart-style-2 .ui-datepicker .ui-datepicker-header {
  background-color: #fff;
}
.smart-style-2 .big-spin-loader {
  background: url('spin-loader.a01fb811724c25c2dd1e.svg') no-repeat !important;
}
.smart-style-2 .big-spin-page-loader {
  background: url('spin-loader.a01fb811724c25c2dd1e.svg') no-repeat !important;
}
.smart-style-2 .dataTables_processing {
  background: url('spin-loader.a01fb811724c25c2dd1e.svg') no-repeat !important;
}
.smart-style-2 .lstposTbale .dataTables_processing {
  background: url('spin-loader_small.b2cbaf97b93dc78ca6c3.svg') no-repeat !important;
}
.smart-style-2 .ui-datepicker td .ui-state-highlight {
  color: #fff;
}
.smart-style-2 .ui-datepicker td .ui-state-hover {
  color: #fff;
}
.smart-style-2 .card-header {
  background-color: #EDEDED;
}
.smart-style-2 .panel {
  background: #cecece;
}
.smart-style-2 .card {
  background-color: #f7f7f7;
  border: 1px solid #e1e1e1;
}
.smart-style-2 .card-box {
  background-color: #eee !important;
}
.smart-style-2 .box-header {
  color: #212529;
  border-bottom: 1px solid #ddd !important;
}
.smart-style-2 .panel-tag {
  border-left: 3px solid darkgray;
  background: #ccc;
  color: #000;
}
.smart-style-2 .commonWidgetText {
  color: inherit;
  font-size: 13px;
}
.smart-style-2 .userWidgetText {
  color: inherit;
  font-size: 16px;
}
.smart-style-2 .serviceTheme {
  background-color: #FFFFFF !important;
  border-radius: 10px;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar {
  background-color: #FFFFFF !important;
  border-bottom: none !important;
  width: 100% !important;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar .ui-dialog-title {
  width: 100% !important;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar .ui-dialog-title .widget-header {
  text-align: left;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar .ui-dialog-title .shortDescription {
  text-align: center;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar .ui-dialog-titlebar-close {
  position: absolute !important;
  top: 25px;
  right: 5px;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar h5 {
  color: #000 !important;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar h1 {
  color: #000 !important;
  padding: 0 25px;
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar .ui-button {
  color: var(--theme-color-1);
  opacity: 1;
}
.smart-style-2 .serviceTheme .ui-dialog-content {
  background-color: #FFFFFF !important;
}
.smart-style-2 .serviceTheme .ui-dialog-content .service_modal {
  background-color: #FFFFFF !important;
}
.smart-style-2 .serviceTheme .ui-dialog-content .service_modal .modal-content {
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
  border: unset !important;
  background-color: #FFFFFF !important;
}
.smart-style-2 .chip_Section {
  background-color: #fff !important;
  border: 1px solid #cccccc !important;
  color: #4C4F53 !important;
  margin-left: 0px !important;
}
.smart-style-2 .serviceSection:hover {
  background-color: #f3f1f1 !important;
}
.smart-style-2 .toggleMore {
  background-color: #ddd !important;
}
.smart-style-2 .menuIconsOption img {
  filter: brightness(100) invert(1);
}
.smart-style-2 .chip {
  background-color: transparent !important;
  color: #0C1214 !important;
}
.smart-style-2 .menuSelected {
  background-color: #CCCCCC;
}
.smart-style-2 .dropdown-icon-menu > ul > li .btn,
.smart-style-2 .header-btn {
  background: none;
}
.smart-style-2 .theme-bg-colors-border {
  background-color: #fff !important;
  box-shadow: unset !important;
}
.smart-style-2 .headerContent {
  border-radius: 8px;
  border: 1px solid #cccccc;
}
.smart-style-2 .pagination > .active > a {
  background-color: var(--theme-color-1) !important;
  border-color: var(--theme-color-1) !important;
  color: var(--hovertext-color);
}
.smart-style-2 .pagination > li > a {
  background-color: #fff !important;
  color: #000;
}
.smart-style-2 .pagination > li:first-child > a,
.smart-style-2 .pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #fff !important;
}
.smart-style-2 .pagination > li:last-child > a,
.smart-style-2 .pagination > li:last-child > span {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #fff !important;
}
.smart-style-2 .algntblTranscation {
  padding: 0 0 0 7px;
  border-radius: 8px;
  border: 1px solid #cccccc !important;
  margin: 0 3px !important;
  overflow: hidden;
}
.smart-style-2 .headerContents {
  border-radius: 8px;
  border: 1px solid #cccccc !important;
  overflow: hidden;
}
.smart-style-2 #content {
  background-color: #fff !important;
}
.smart-style-2 .widgetGray {
  background-color: #fff !important;
}
.smart-style-2 .edipartnerLbl {
  color: #000 !important;
}
.smart-style-2 .header-formbtn,
.smart-style-2 .advace-header-formbtn {
  background-color: #fff !important;
  border: 2px solid var(--theme-color-1) !important;
  color: #000 !important;
  padding: 3px 15px;
}
.smart-style-2 .header-formbtn:hover,
.smart-style-2 .advace-header-formbtn:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
}
.smart-style-2 .select-label {
  color: #000 !important;
}
.smart-style-2 .dashbaordlabel {
  background-color: unset !important;
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;
}
.smart-style-2 .modalBodyBgColor {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .theme-header-color {
  color: #4C4F53 !important;
}
.smart-style-2 .setting-menu #header .settingsMenu {
  background: #CCCCCC !important;
}
.smart-style-2 .btn-primary {
  background: #fff !important;
  border: 2px solid var(--theme-color-1);
  color: #202124 !important;
  padding: 3px 15px;
  border-radius: 15px;
  font-size: 13px;
}
.smart-style-2 .btn-primary:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
  border: 2px solid var(--theme-color-1);
}
.smart-style-2 .btn-primary:focus {
  border-color: var(--theme-color-1) !important;
}
.smart-style-2 .ebill_btn {
  background: #fff !important;
  border: 2px solid var(--theme-color-1);
  color: #202124 !important;
  padding: 3px 15px;
  border-radius: 15px;
  font-size: 13px;
}
.smart-style-2 .ebill_btn:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
  border: 2px solid var(--theme-color-1);
}
.smart-style-2 .ebill_btn:focus {
  border-color: var(--theme-color-1) !important;
}
.smart-style-2 .nav-pills > li.active > a {
  background: #fff !important;
  border: 2px solid var(--theme-color-1);
  color: #202124;
  margin-top: 5px;
}
.smart-style-2 .nav-pills > li.active > a:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
  border: 2px solid var(--theme-color-1);
}
.smart-style-2 .btnModalSave {
  float: right;
  background-color: #fff;
  border: none;
  color: #202124 !important;
  border-radius: 15px;
  border: 2px solid var(--theme-color-1);
}
.smart-style-2 .btnModalClose {
  float: left;
  background-color: #fff;
  border: none;
  color: #202124 !important;
  border-radius: 15px;
  border: 2px solid var(--theme-color-1) !important;
}
.smart-style-2 .theme-header-text-color {
  color: #000 !important;
}
.smart-style-2 .theme-header-color {
  color: #4C4F53 !important;
}
.smart-style-2 .cancel {
  text-align: left;
  width: auto;
  font: normal normal normal 13px/24px Open Sans;
  border-radius: 15px;
  background: #fff 0% 0% no-repeat padding-box !important;
  border: 2px solid var(--theme-color-1) !important;
  letter-spacing: 0px;
  color: #171717 !important;
  padding: 3px 15px;
}
.smart-style-2 .TotalLabel {
  top: 275px;
  left: 560px;
  height: 49px;
  text-align: left;
  font: normal normal bold 31px/23px Open Sans;
  letter-spacing: 0px;
  color: #202528;
  opacity: 1;
  margin-left: -16px;
}
.smart-style-2 .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  background-color: #fff !important;
}
.smart-style-2 .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  background-color: #fff !important;
  border-radius: 10px;
  margin-left: -1px;
}
.smart-style-2 .paymentOptions {
  display: none;
  background-color: #fff !important;
  border-radius: 10px;
}
.smart-style-2 .currentButton {
  background-color: #F7F7F7 !important;
  color: #000 !important;
  border-color: #CCCCCC !important;
  cursor: not-allowed;
  z-index: -1;
}
.smart-style-2 .currentPlan {
  background-color: #CCCCCC !important;
  color: #202528 !important;
}
.smart-style-2 .currentPlan:hover {
  background: #CCCCCC !important;
}
.smart-style-2 .emptyCol {
  background-color: #fff !important;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}
.smart-style-2 .selectButton {
  border-radius: 0%;
  padding: 5px 20px;
  background-color: #fff;
  border: 2px solid var(--theme-color-1);
  border-radius: 15px;
  color: #202528;
  font-size: 14px;
  outline: none;
}
.smart-style-2 .selectButton:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
  border: 1px solid #3B3B3B33;
}
.smart-style-2 .selected {
  background-color: var(--theme-color-1) !important;
  border-color: var(--theme-color-1) !important;
}
.smart-style-2 .selected:hover {
  color: var(--hovertext-color) !important;
}
.smart-style-2 .rightBorder {
  border-right: 2px solid var(--theme-color-1) !important;
  border-left: 2px solid var(--theme-color-1) !important;
}
.smart-style-2 .topBorder {
  border-top: 2px solid var(--theme-color-1) !important;
}
.smart-style-2 .bottomBorder {
  border-bottom: 2px solid var(--theme-color-1) !important;
}
.smart-style-2 .nextBtn {
  font: normal normal normal 13px/24px Open Sans;
  background: #fff 0% 0% no-repeat padding-box !important;
  border-color: var(--theme-color-1) !important;
  letter-spacing: 0px;
  border: 2px solid var(--theme-color-1) !important;
  border-radius: 15px;
  color: #171717 !important;
  padding: 3px 15px;
}
.smart-style-2 .nextBtn:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: var(--hovertext-color) !important;
}
.smart-style-2 .btnAction,
.smart-style-2 .btnFile,
.smart-style-2 .btnSendinvit {
  font: normal normal normal 13px/24px Open Sans;
  background: #fff 0% 0% no-repeat padding-box !important;
  border-color: var(--theme-color-1) !important;
  letter-spacing: 0px;
  border: 2px solid var(--theme-color-1) !important;
  border-radius: 15px;
  color: #171717 !important;
  padding: 0px 15px;
}
.smart-style-2 .btnAction:hover,
.smart-style-2 .btnFile:hover,
.smart-style-2 .btnSendinvit:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: var(--hovertext-color) !important;
}
.smart-style-2 .btnUpload {
  font: normal normal normal 13px/24px Open Sans;
  background: #fff 0% 0% no-repeat padding-box !important;
  border-color: var(--theme-color-1) !important;
  letter-spacing: 0px;
  border: 2px solid var(--theme-color-1) !important;
  border-radius: 15px;
  color: #171717 !important;
  padding: 0px 15px;
}
.smart-style-2 .btnUpload:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: var(--hovertext-color) !important;
}
.smart-style-2 .cancelBtn {
  border: 2px solid var(--theme-color-1) !important;
  border-radius: 15px;
  background: #fff 0% 0% no-repeat padding-box !important;
  color: #000 !important;
  font: normal normal normal 13px/24px Open Sans;
  padding: 3px 15px !important;
}
.smart-style-2 .cancelBtn:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: var(--hovertext-color) !important;
}
.smart-style-2 .jarviswidget > div {
  border: none;
}
.smart-style-2 .pricingTable table {
  color: #000 !important;
}
.smart-style-2 .bootstrapWizard li .step {
  background-color: #fff;
  border: 1px solid #CCCCCC;
  color: #202528;
}
.smart-style-2 .lightBackgroundColor {
  background-color: #fff !important;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  margin-left: 13px;
}
.smart-style-2 .textColor {
  color: #4C4F53 !important;
}
.smart-style-2 .textColor:hover {
  background: transparent !important;
}
.smart-style-2 .NextconfirmLabel {
  color: #171717;
  border: 1px solid #CCCCCC;
}
.smart-style-2 .headData {
  background-color: #fff;
  color: #000;
  margin-top: 10px;
  background-image: none;
}
.smart-style-2 .back-btn {
  background: #073E84 0% 0% no-repeat padding-box;
  border-color: #073E84;
  font-size: 13px;
}
.smart-style-2 .buy-addon .navigationList .nav.nav-tabs li a span {
  color: #000 !important;
}
.smart-style-2 .buy-addon .navigationList .nav.nav-tabs li a:hover {
  background-color: var(--theme-color-1) !important;
  border: 1px solid var(--theme-color-1) !important;
}
.smart-style-2 .package-name .packageHeader {
  color: #202528 !important;
}
.smart-style-2 .yearlyData span.price {
  color: #202528 !important;
}
.smart-style-2 .buy-addon .checkout {
  background-color: #fff;
}
.smart-style-2 .table-headData {
  background-color: #fff;
  color: #000;
  margin-top: 10px;
}
.smart-style-2 .editPaymentHeader {
  color: #000 !important;
  font-size: 16px !important;
}
.smart-style-2 .payContent {
  background: #F7F7F7 !important;
}
.smart-style-2 .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #fff;
  border: 1px solid #CCCCCC;
  font-size: 14px;
  color: #000;
}
.smart-style-2 .tileContainer .commonTile .userDash_tileHead,
.smart-style-2 .tileContainer .commonTile .clickLbl {
  font-weight: 500;
  font-size: 13px;
}
.smart-style-2 .tileContainer .commonTile .userDash_tileHeadgreen,
.smart-style-2 .tileContainer .commonTile .clickLblgreen {
  font-weight: 500;
}
.smart-style-2 .commonTile:not(.tile0),
.smart-style-2 .tileFinal0,
.smart-style-2 .tileFinal1 {
  background-color: #fff !important;
}
.smart-style-2 .commonTile,
.smart-style-2 .leftRowSection,
.smart-style-2 .rightediSetion,
.smart-style-2 .tileFinal0,
.smart-style-2 .tileFinal1,
.smart-style-2 .rightFinalSection,
.smart-style-2 .commonBorderColor {
  border: 1px solid #CCCCCC !important;
}
.smart-style-2 .dopzoneTxt {
  color: #000 !important;
}
.smart-style-2 .dashimgDiv {
  background-repeat: no-repeat !important;
  background-position: center !important;
  height: 100px;
  width: 100px;
}
.smart-style-2 .previewImg {
  background: url("/assets/img/iconSvg/dashboard.png");
}
.smart-style-2 .toolsimg {
  background: url("/assets/img/iconSvg/tools.png");
}
.smart-style-2 .ediLbl,
.smart-style-2 .editoLbl {
  color: #000 !important;
}
.smart-style-2 .iconForm,
.smart-style-2 .iconToForm {
  color: #000 !important;
}
.smart-style-2 .tileContainer.tile0 {
  background-color: #fff !important;
}
.smart-style-2 .tile-formbtn {
  background-color: unset !important;
  color: #000 !important;
  border: 2px solid var(--theme-color-1) !important;
}
.smart-style-2 .tile-formbtn:hover {
  background-color: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
}
.smart-style-2 .tile1-formbtn {
  background-color: unset !important;
  color: #000 !important;
  border: 2px solid var(--theme-color-1) !important;
}
.smart-style-2 .tile1-formbtn:hover {
  background-color: var(--theme-color-1) !important;
  border: 1px solid var(--theme-color-1) !important;
}
.smart-style-2 .NextconfirmLabels {
  font: normal normal normal 13px/24px Open Sans;
  letter-spacing: 0px;
  color: #000;
  margin-right: 15px;
  padding: 4px 10px;
  border: 1px solid #cccccc !important;
  border-radius: 20px;
  margin-top: -7px;
  margin-left: 10px;
}
.smart-style-2 .bottom-formbtns {
  background-color: unset !important;
  border: 2px solid var(--theme-color-1) !important;
  color: #000 !important;
}
.smart-style-2 .bottom-formbtns:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
}
.smart-style-2 .darkHeaderIcon,
.smart-style-2 .lightHeaderIcon {
  color: #000 !important;
}
.smart-style-2 .errorOccured {
  border-color: #dc3545 !important;
}
.smart-style-2 .stepsinout-form.formly #accordionExpand .form-control {
  background-color: #fff !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.smart-style-2 .form-control:disabled {
  background-color: #EEEEEE !important;
}
.smart-style-2 .fileFormat_toolTip {
  background-color: #fff !important;
  color: #202528 !important;
}
.smart-style-2 .tooltipHeader {
  color: #202528 !important;
}
.smart-style-2 .commonAchor {
  color: #202528 !important;
}
.smart-style-2 .serviceHead {
  color: #202528 !important;
}
.smart-style-2 .badge-disable-iom {
  color: #111 !important;
}
.smart-style-2 .sub-btn {
  border: 2px solid var(--theme-color-1) !important;
  color: #000 !important;
  font-weight: bold;
}
.smart-style-2 .sub-btn.freeButton {
  border-color: #333 !important;
}
.smart-style-2 .sub-btn.freeButton:hover {
  background-color: transparent !important;
  border-color: #333 !important;
  color: var(--hovertext-color) !important;
}
.smart-style-2 .sub-btn.futurePlanBtn {
  border-color: var(--theme-color-1) !important;
  background-color: var(--theme-color-1) !important;
  color: #000 !important;
}
.smart-style-2 .sub-btn.futurePlanBtn:hover {
  background-color: transparent !important;
  border-color: #333 !important;
  color: var(--hovertext-color) !important;
}
.smart-style-2 .sub-btn.currentPlanBtn {
  border-color: var(--theme-color-1) !important;
  background-color: var(--theme-color-1) !important;
  color: #000 !important;
}
.smart-style-2 .sub-btn.currentPlanBtn:hover {
  background-color: transparent !important;
  border-color: #333 !important;
  color: var(--hovertext-color) !important;
}
.smart-style-2 .sub-btn:hover {
  background: var(--theme-color-1) !important;
  color: #0C1214 !important;
}
.smart-style-2 #userChoiceSelect .container,
.smart-style-2 #userChoiceSelect .durationDetailFields,
.smart-style-2 #userChoiceSelect .successNote {
  background-color: #eee !important;
}
.smart-style-2 #userChoiceSelect .commonTimeData {
  background-color: #fff !important;
  border: 1px solid #EEEEEE !important;
}
.smart-style-2 #userChoiceSelect .fieldHeader {
  color: #000 !important;
}
.smart-style-2 #userChoiceSelect .commonFormField input,
.smart-style-2 #userChoiceSelect .commonFormField textarea {
  background-color: #fff !important;
  border: 1px solid #EEEEEE;
  color: #000 !important;
}
.smart-style-2 #userChoiceSelect textarea {
  background-color: #fff !important;
  border: 1px solid #EEEEEE;
  color: #000 !important;
}
.smart-style-2 .filter-top {
  color: #0C1214 !important;
}
.smart-style-2 .settings-list .settings-list-items-contents {
  background-color: #F7F7F7 !important;
  border: 1px solid #CCCCCC !important;
}
.smart-style-2 .settings-list-row {
  color: #111;
}
.smart-style-2 .settings-list-row .list-group-item {
  background-color: transparent !important;
}
.smart-style-2 .settings-list-row .list-group-item:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
}
.smart-style-2 .amount-div {
  color: #4C4F53 !important;
  background-color: #EDEDED !important;
}
.smart-style-2 .iom_btn {
  color: #000 !important;
}
.smart-style-2 .modal-content {
  background: #F7F7F7 !important;
  border-radius: 10px;
}
.smart-style-2 .border {
  border: 1px solid #CCCCCC !important;
  border-radius: 10px;
}
.smart-style-2 .border .editConfigSection {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .joyride-step__holder {
  background-color: #EDEDED !important;
  box-shadow: 0 0 0px 1px #EDEDED !important;
}
.smart-style-2 .joyride-step__holder .joyride-step__container {
  background-color: #EDEDED !important;
  box-shadow: 0 0 0px 1px #EDEDED !important;
}
.smart-style-2 .joyride-step__holder .joyride-arrow__top {
  border-bottom: 11px solid #EDEDED !important;
}
.smart-style-2 .joyride-step__holder .joyride-button {
  color: #111 !important;
}
.smart-style-2 .joyride-step__holder .joyride-button:hover {
  background-color: var(--theme-color-1) !important;
  color: #111 !important;
}
.smart-style-2 .joyride-step__holder .joyride-step__title,
.smart-style-2 .joyride-step__holder .joyride-step__body {
  color: #4C4F53 !important;
}
.smart-style-2 .error_tab {
  color: #111 !important;
}
.smart-style-2 .error_text {
  color: #111 !important;
}
.smart-style-2 .bpHeader,
.smart-style-2 .bc_sectionHead,
.smart-style-2 .invitationLbl,
.smart-style-2 .searchLbl,
.smart-style-2 .netComID,
.smart-style-2 .businessLbl,
.smart-style-2 .comapnyLbl {
  color: #000 !important;
}
.smart-style-2 .square,
.smart-style-2 .bp_connectorStatus {
  background-color: #eeee !important;
}
.smart-style-2 .statusSection tr td {
  color: #000 !important;
}
.smart-style-2 textarea.in,
.smart-style-2 textarea.modal-in {
  color: #000 !important;
  background-color: #F7F7F7 !important;
  border: 1px solid #CCCCCC;
}
.smart-style-2 .tour-title-input {
  color: #000 !important;
  background-clip: padding-box;
  background-color: #F7F7F7 !important;
  border: 1px solid #CCCCCC;
}
.smart-style-2 .customIdSection,
.smart-style-2 .payeeLabel,
.smart-style-2 .arrowColor,
.smart-style-2 .selectedHeadLbl {
  color: #000 !important;
}
.smart-style-2 .modal-title-input {
  color: #000 !important;
  background-clip: padding-box;
  background-color: #F7F7F7 !important;
  border: 1px solid #CCCCCC;
}
.smart-style-2 .successcmpimgDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .defaultDot {
  background-color: #666;
}
.smart-style-2 .bp_connectorStatus .fa-refresh {
  color: black;
}
.smart-style-2 .statusSection tr td:not(:last-child) {
  border-right: 2px solid #666;
}
.smart-style-2 .greyDot {
  background-color: black !important;
  opacity: 0.5;
}
.smart-style-2 .successDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .successDiv-status {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .successdeleteDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .theme-content-color {
  color: #666666;
}
.smart-style-2 #wizard {
  color: #666666;
}
.smart-style-2 #wizard .line .line-1 {
  background: #CCCCCC;
}
.smart-style-2 #wizard .line11 .line-1 {
  background: transparent;
}
.smart-style-2 #wizard .wizard-items {
  border: 1px solid #CCCCCC;
  background: #fff 0% 0% no-repeat padding-box;
}
.smart-style-2 #wizard .wizard-items:hover {
  background: #ededed 0% 0% no-repeat padding-box;
}
.smart-style-2 #wizard .wizard-items .wizardDiv {
  border: 1px solid transparent;
  background: #cccccc 0% 0% no-repeat padding-box;
}
.smart-style-2 #wizard .wizard-items .wizardDiv span {
  color: #111;
}
.smart-style-2 #wizard .wizard-billeritem {
  border: 1px solid #CCCCCC;
  background: #fff 0% 0% no-repeat padding-box;
}
.smart-style-2 #wizard .wizard-billeritem .wizardDiv {
  border: 1px solid transparent;
  background: #cccccc 0% 0% no-repeat padding-box;
}
.smart-style-2 #wizard .wizard-billeritem .wizardDiv span {
  color: #111;
}
.smart-style-2 #wizard b.badges {
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}
.smart-style-2 .wizardDiv {
  border: 1px solid #666666;
}
.smart-style-2 .accordianClass {
  color: #666666;
}
.smart-style-2 b.badges {
  background-color: #EDEDED;
}
.smart-style-2 .warning-text {
  color: #4C4F53 !important;
}
.smart-style-2 nav ul ul li {
  background-color: #333333;
}
.smart-style-2 nav ul ul li a {
  font-size: 16px !important;
  text-shadow: none !important;
  padding-left: 0px !important;
  padding-top: 7px !important;
  padding-bottom: 14px !important;
}
.smart-style-2 nav ul ul li a:focus, .smart-style-2 nav ul ul li a:hover {
  color: #202427 !important;
  background: var(--theme-color-1) !important;
}
.smart-style-2 #left-panel {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
}
.smart-style-2 #left-panel nav ul.edi li {
  border-bottom: 1px solid var(--theme-color-1);
}
.smart-style-2 #left-panel nav ul.edi li:hover {
  background: var(--theme-color-1) !important;
  color: #000 !important;
  border: 1px solid #3B3B3B33;
}
.smart-style-2 #left-panel nav ul.edi li:hover a {
  color: #fff !important;
}
.smart-style-2 #left-panel nav ul.edi li.active {
  background-color: var(--theme-color-1) !important;
}
.smart-style-2 #left-panel nav ul.edi li.active a {
  color: #fff !important;
}
.smart-style-2 #left-panel nav ul.edi li.active ul li {
  color: #fff !important;
}
.smart-style-2 #left-panel nav ul.edi li.active ul li:hover a {
  color: var(--hovertext-color) !important;
  background: var(--theme-color-1) !important;
}
.smart-style-2 #left-panel nav ul.edi li.active ul li a {
  color: #fff !important;
}
.smart-style-2 #left-panel nav ul.edi li.active ul li.active a {
  color: #fff !important;
}
.smart-style-2 nav ul li.active a {
  font-weight: 400;
}
.smart-style-2 nav ul li.active a:before {
  color: #202427;
}
.smart-style-2 nav ul li a {
  color: #4C4F53 !important;
  padding: 12px 17px 12px 16px !important;
  font-size: 16px !important;
}
.smart-style-2 nav ul li a i {
  font-size: 17px !important;
  vertical-align: 0 !important;
}
.smart-style-2 nav ul li.open a {
  color: #fff !important;
  font-weight: 400;
}
.smart-style-2 nav ul li.open:focus, .smart-style-2 nav ul li.open:hover {
  color: var(--hovertext-color) !important;
  background: var(--theme-color-1) !important;
}
.smart-style-2 nav ul li.open a b {
  color: #fff !important;
}
.smart-style-2 nav ul li.open.active a b {
  color: #202427 !important;
}
.smart-style-2 nav ul li.open:hover > a {
  color: #202427 !important;
}
.smart-style-2 nav ul li.open:hover > a b {
  color: #202427 !important;
}
.smart-style-2 .businessField {
  color: #495057 !important;
}
.smart-style-2 .dropzonbox {
  background-color: #F7F7F7 !important;
  border: 1px solid #dadce0;
}
.smart-style-2 .checkmark {
  border: 2px solid #666666;
}
.smart-style-2 .interConnPopupForm label {
  font-size: 14px !important;
}
.smart-style-2 .interConnPopupForm .form-control {
  background-color: #fff !important;
  border-radius: 5px !important;
}
.smart-style-2 .interConnPopupForm .invalid-feedback {
  font-size: 12px !important;
}
.smart-style-2 .ediAccountSection {
  border-radius: 10px;
  margin-bottom: 20px;
}
.smart-style-2 .ediAccountSection .userDash_tileText {
  font-size: 13px;
  font-weight: 400px;
}
.smart-style-2 .ediAccountSection .userDash_tileHeadgreen {
  font-size: 13px;
  color: #212529 !important;
}
.smart-style-2 .ediAccountSection .userDash_tileCount {
  font-size: 17px;
  color: #212529 !important;
}
.smart-style-2 .ediAccountSection .userDash_tileCount .from-text {
  color: #212529 !important;
}
.smart-style-2 .ediAccountSection .tile-formbtn {
  border-radius: 20px;
  padding: 3px 15px;
  font-size: 13px;
}
.smart-style-2 .ediAccountSection .userDash_tileHead {
  color: #212529 !important;
}
.smart-style-2 .billerCommonDetails:hover {
  background-color: unset !important;
}
.smart-style-2 .ebillFormFormly formly-field-select select option,
.smart-style-2 .disableForm formly-field-select select option,
.smart-style-2 .ebillForm formly-field-select select option {
  background-color: unset !important;
}
.smart-style-2 .ebillFormFormly .form-control,
.smart-style-2 .disableForm .form-control,
.smart-style-2 .ebillForm .form-control {
  background: #fff !important;
  border: 1px solid #6666 !important;
  color: #000;
  height: 40px !important;
  border-radius: 6px !important;
  font-size: 13px;
}
.smart-style-2 .sectorLbl {
  color: #000 !important;
}
.smart-style-2 .companySelect .ng-select-container,
.smart-style-2 .company-select .ng-select-container {
  border-radius: 10px !important;
  color: #000 !important;
  height: 42px !important;
  border: none;
}
.smart-style-2 .companySelect .ng-select-container .ng-value-container .ng-placeholder,
.smart-style-2 .company-select .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 13px;
}
.smart-style-2 .companySelect .ng-select-container .ng-value-container .ng-value,
.smart-style-2 .company-select .ng-select-container .ng-value-container .ng-value {
  font-size: 13px;
}
.smart-style-2 .companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.smart-style-2 .company-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 13px !important;
}
.smart-style-2 .biller_tabSection .nav-item {
  width: auto !important;
}
.smart-style-2 .biller_tabSection .nav-item a {
  margin-right: unset !important;
  background-color: unset !important;
  color: #000 !important;
  border-bottom: unset !important;
  border-left-color: #6666 !important;
  border-right-color: #6666 !important;
  border-top-color: #6666 !important;
}
.smart-style-2 .ebiller_tabSection .nav-item a {
  color: #000 !important;
  border-color: #dee2e6 !important;
  border-left-color: #dee2e6 !important;
  border-right-color: #dee2e6 !important;
  border-top-color: #dee2e6 !important;
}
.smart-style-2 .communicationPanel .card-header {
  color: #000;
}
.smart-style-2 .docLbl {
  width: 100%;
  color: #000;
  font-size: 14px;
}
.smart-style-2 .docInput {
  width: 100%;
  margin-right: 10px;
  border-radius: 10px !important;
  background-color: #EEEEEE !important;
}
.smart-style-2 .invoiceTbl .sorting_disabled,
.smart-style-2 .subscribersTbl .sorting_disabled {
  background-color: #CCCCCC !important;
}
.smart-style-2 .nav-tabs {
  border-bottom: 1px solid #dee2e6;
  border-color: 1px solid #dee2e6 !important;
}
.smart-style-2 .communicationPanel .communicationBodyWrap {
  border: 1px solid #dee2e6;
}
.smart-style-2 .filelabel:hover i,
.smart-style-2 .filelabel:hover .title {
  color: #000 !important;
}
.smart-style-2 .panelSector .ng-select .ng-select-container {
  background-color: transparent !important;
  color: #000 !important;
  border: 1px solid #6666 !important;
}
.smart-style-2 .detail_save_btn {
  color: #000;
}
.smart-style-2 .card-body {
  background-color: #fff;
}
.smart-style-2 .billTabSection {
  border: 1px solid #cccccc;
}
.smart-style-2 .emailMessage,
.smart-style-2 .ediEmailMessage {
  border: 1px solid #CCCCCC !important;
}
.smart-style-2 #header.colorChangeTest .upgradebtn {
  color: #000 !important;
}
.smart-style-2 .internav-tabs > li > a {
  color: #000 !important;
}
.smart-style-2 .nav-tabs > li.active > a.display-border,
.smart-style-2 .nav-tabs > li.active > a.display-border:focus,
.smart-style-2 .nav-tabs > li.active > a.display-border:hover {
  background-color: #fff !important;
  border-top: 2px solid var(--theme-color-1) !important;
  border-left: 2px solid var(--theme-color-1) !important;
  border-right: 2px solid var(--theme-color-1) !important;
  color: #000;
}
.smart-style-2 .nav-tabs.invoice-tab > li.active {
  border-bottom: none !important;
  box-shadow: 0 -2px 0 var(--theme-color-1) !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li.active:hover {
  background-color: transparent !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead {
  background-color: #fff !important;
  color: #000 !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead:focus {
  background-color: #fff !important;
  color: #000 !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead:hover {
  background-color: #fff !important;
  color: var(--hovertext-color) !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li {
  border-bottom: 1px solid #cccccc !important;
  border-right: 1px solid #cccccc !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li:last-child {
  border-right: none !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li:hover {
  border-bottom: 1px solid #cccccc !important;
  border-top: none !important;
  background-color: #cccccc !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li > a:hover {
  color: var(--hovertext-color) !important;
}
.smart-style-2 .invoice-tab-header {
  border: 1px solid #EDEDED !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}
.smart-style-2 .setCircleTest {
  color: #F1DA91;
  font-size: 10px !important;
}
.smart-style-2 .tooltipA .tooltiptext {
  background: #F7F7F7 0% 0% no-repeat padding-box;
  color: #4C4F53;
  border: 1px solid #EDEDED;
}
.smart-style-2 .nav_btn {
  color: #4c4f53 !important;
}
.smart-style-2 .nav_btn:hover {
  color: var(--hovertext-color) !important;
}
.smart-style-2 .fieldSection {
  border: 1px solid #CCCCCC !important;
}
.smart-style-2 .descriptionSection {
  border: 1px solid #CCCCCC !important;
}
.smart-style-2 .descriptionSection .ds-1 {
  border-bottom: 1px solid #CCCCCC !important;
}
.smart-style-2 .descriptionSection .connectorlbl {
  color: #000 !important;
}
.smart-style-2 .descriptionSection .businessFields label {
  color: #000 !important;
}
.smart-style-2 .serviceForm formly-field label,
.smart-style-2 .serviceForm formly-wrapper-form-field label {
  color: #000 !important;
}
.smart-style-2 .serviceForm formly-field input,
.smart-style-2 .serviceForm formly-wrapper-form-field input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.smart-style-2 .connectorContainer .serviceLbl {
  color: #000 !important;
}
.smart-style-2 .filePattern {
  color: #000 !important;
}
.smart-style-2 .serviceBody label {
  color: #000;
}
.smart-style-2 .serviceBody .inputSection label,
.smart-style-2 .serviceBody .selectSection label {
  color: #000 !important;
}
.smart-style-2 .addonItem {
  color: #000 !important;
}
.smart-style-2 .connector-form .inputSection .form-control,
.smart-style-2 .connector-form .selectSection .form-control {
  background-color: #EEEEEE !important;
  border: 1px solid #EEEEEE !important;
}
.smart-style-2 .searchcompanyName {
  color: #000 !important;
}
.smart-style-2 .connectorlblleft,
.smart-style-2 .connectorlbl {
  color: #000 !important;
}
.smart-style-2 .borderHead {
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid #cccccc !important;
}
.smart-style-2 .styleBackground {
  border: 1px solid #cccccc !important;
}
.smart-style-2 .businessField,
.smart-style-2 .bp_connections,
.smart-style-2 .bp_connectorStatus {
  border: 1px solid #cccccc !important;
}
.smart-style-2 .border-style {
  border: 1px solid #cccccc !important;
}
.smart-style-2 .app-tut {
  background-color: #ededed !important;
}
.smart-style-2 .app-tut.active {
  background-color: #ccc !important;
}
.smart-style-2 .app-tut {
  background-color: #202124;
}
.smart-style-2 .app-tut:not(.nohover):hover {
  background-color: #ccc !important;
}
.smart-style-2 .modal-footer {
  border-top: none !important;
}
.smart-style-2 #statusChangeModal .fieldSection {
  border: none !important;
}
.smart-style-2 .approveBtn {
  color: #000;
}
.smart-style-2 .company-select {
  border: none;
  border-radius: 10px;
  background-color: #EEEEEE !important;
}
.smart-style-2 .fuelux .wizard ul li .chevron:before {
  position: absolute;
  top: -24px;
  right: 1px;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
  border-left: 14px solid #ededed;
  content: "";
  /* background: #fff; */
}
.smart-style-2 .fuelux .wizard ul li {
  position: relative;
  float: left;
  height: 46px;
  padding: 0 20px 0 30px;
  margin: 0;
  font-size: 16px;
  line-height: 46px;
  color: #000;
  cursor: default;
  background: #ededed;
}
.smart-style-2 .fuelux .wizard ul li.active {
  color: #000;
  background: var(--theme-color-1);
}
.smart-style-2 .fuelux .wizard ul li.active .chevron:before {
  border-left: 14px solid var(--theme-color-1);
}
.smart-style-2 .snd-rcv-div.active {
  background-color: var(--theme-color-1) !important;
}
.smart-style-2 .snd-rcv-div {
  background-color: #fff;
  border: 1px solid var(--theme-color-1);
}
.smart-style-2 .snd-rcv-div:hover {
  background-color: var(--theme-color-1) !important;
}
.smart-style-2 table.bulkTable {
  border: 1px solid #ccc !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-radius: 5px;
  margin-top: 6px !important;
}
.smart-style-2 .trapezium {
  border-bottom: 34px solid #ccc;
}
.smart-style-2 .step {
  border: 2px solid #ccc !important;
  background: #ccc !important;
}
.smart-style-2 .step.active {
  border: 2px solid var(--theme-color-1) !important;
  background: var(--theme-color-1) !important;
}
.smart-style-2 .bulk-popup {
  background: #fff !important;
}
.smart-style-2 .interConnPopupForm .form-control.is-invalid {
  border: 1px solid #ced4da;
}
.smart-style-2 .archiveImport {
  background: #ffffff;
}
.smart-style-2 .subscriptions-pdf .sub-items {
  border: 1px solid #ccc;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .dropdown-btn {
  background-color: #fff !important;
  border: 1px solid #ccc !important;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .filter-textbox input {
  background: #fff !important;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox {
  border-bottom: none !important;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div {
  color: #4C4F53;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div:before {
  color: #4C4F53 !important;
  border: 2px solid #4C4F53 !important;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div:focus {
  color: #4C4F53 !important;
  border: 2px solid #4C4F53 !important;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div:after {
  border-color: #4C4F53 !important;
}
.smart-style-2 .plan-title {
  color: #212529 !important;
}
.smart-style-2 .target-highlight, .smart-style-2 .target-highlightPdf {
  border-color: var(--theme-color-1) !important;
}
.smart-style-2 .cardimg {
  filter: brightness(100) invert(1);
}
.smart-style-2 .inv-img {
  content: url('invoice.129e5b40d80804bcb275.svg') !important;
}
.smart-style-2 .payment-detail {
  width: 100%;
  max-width: 1010px;
  background-color: #F7F7F7;
  margin: auto;
  padding: 10px 10px 6px 10px;
}
.smart-style-2 .underline {
  border-color: #CCCCCC;
}
.smart-style-2 .plan-stat {
  border-left: 1px solid #CCCCCC;
}
.smart-style-2 .plan-stat:first-of-type {
  border-bottom: 1px solid #CCCCCC;
}
.smart-style-2 .durationDetailFields {
  background-color: transparent !important;
}
.smart-style-2 .fileuploadform-form .input-group-append .input-group-text {
  background-color: #fff;
  color: #495057;
  border: 1px solid #ced4da;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.smart-style-2 .module .module-name {
  color: #202427 !important;
}
.smart-style-2 .company-label {
  color: #4C4F53 !important;
}
.smart-style-2 .iom_btn:hover {
  color: var(--hovertext-color) !important;
}
.smart-style-2 .crossBtn {
  color: var(--theme-color-1) !important;
}
.smart-style-2 .footer-menu {
  color: #000 !important;
}
.smart-style-2 .ac_ConnectionSection .ac_div {
  color: #000 !important;
}
.smart-style-2 .ac_ConnectionSection .ac_typeDiv {
  color: #000 !important;
}
.smart-style-2 .ac_ConnectionSection .ac_typeDiv .common_detail {
  color: #000 !important;
}
.smart-style-2 .ac_ConnectionSection .fillGreen {
  color: #fff !important;
}
.smart-style-2 .chatIcon {
  filter: brightness(0) invert(0) !important;
}
.smart-style-2 table.dataTable tbody tr.selected {
  color: #fff !important;
}
.smart-style-2 .fillGreen {
  color: #fff !important;
}
.smart-style-2 #overlay {
  background-color: rgba(200, 200, 200, 0.4);
}
.smart-style-2 .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #202124 !important;
}
.smart-style-2 #transaction-header .form-control::-moz-placeholder {
  color: #202124 !important;
}
.smart-style-2 #transaction-header .form-control::placeholder {
  color: #202124 !important;
}
.smart-style-2 .companyTooltip {
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border: 1px solid #EDEDED;
  color: #4C4F53;
}
.smart-style-2 .checkmark {
  border: 2px solid #666666;
}
.smart-style-2 .checkbox-container .checkmark:after {
  left: 7px;
  top: 1px;
  width: 8px;
  height: 16px;
  border: solid #959595;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.smart-style-2 .packageplanBtn {
  border-radius: 2px !important;
  transition: background-color 0.3s ease;
  padding: 8px 35px;
  font-size: 13px;
  color: #000;
}
.smart-style-2 .packageplanBtn.active {
  color: #fff;
  background: var(--theme-color-1);
  border: solid var(--theme-color-1);
}
/*
---------------------------------------------------------------------------------------
    End   White theme style
---------------------------------------------------------------------------------------
*/
@media (max-width: 767px) {
  .jarviswidget header h2 {
    width: auto !important;
  }

  .right-side {
    flex: 0 0 0% !important;
  }
}
.tabgrid {
  margin-bottom: 1px !important;
}
@media (max-width: 767px) {
  body:not(.menu-on-top).desktop-detected {
    min-height: 1000px !important;
    overflow: unset !important;
  }
}
@media (max-width: 384px) {
  #left-panel {
    padding-top: 198px !important;
    top: 0px !important;
  }
}
@media (min-width: 385px) and (max-width: 706px) {
  #left-panel {
    padding-top: 153px !important;
    top: 0px !important;
  }
}
@media (min-width: 1131px) {
  #left-panel {
    padding-top: 0px !important;
    top: 49px !important;
  }
}
@media (min-width: 989px) and (max-width: 1440px) {
  #content {
    overflow-y: scroll !important;
  }
}
.field_without_addon .form-control {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.selectedFormats {
  position: relative;
  border-radius: 10px !important;
  height: 40px;
  padding: 0 44px 0 10px;
}
.formatRow {
  margin: 0px 10px 0px 0px;
}
.copyIcon {
  position: absolute;
  font-size: 16px;
  right: 20px;
  top: 20px;
}
.fileFormat_toolTip {
  width: 100%;
  color: #fff;
  padding: 5px 35px;
  overflow-y: scroll;
}
.fileFormat_toolTip::-webkit-scrollbar {
  display: none !important;
}
.fileFormat_toolTip {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.fileFormatContent {
  border-radius: 10px !important;
  border: none !important;
}
.fileFormatContent .modal-header {
  border-bottom: unset !important;
}
.fileFormatContent .modal-footer {
  border-top: unset !important;
  padding: 10px 12px 10px 12px;
}
.fileFormatfooter {
  border-top: unset !important;
}
.commonSeperator {
  padding: 0 10px 0 10px;
}
.commonAchor {
  width: 30%;
  color: var(--theme-color-1) !important;
}
.foramatDetail {
  width: 64%;
}
.commonFileFormat {
  font-size: 14px;
}
#main {
  margin-left: 220px;
  padding: 0;
  min-height: 500px;
  position: relative !important;
}
@media (max-width: 979px) {
  #main {
    margin-left: 0px !important;
    padding: 0;
    padding-bottom: 52px;
    min-height: 500px;
    position: absolute !important;
  }
}
@media (max-width: 796px) {
  span.language-text {
    display: none !important;
  }

  .menu-item.languageCustom {
    width: 30px !important;
    text-align: center !important;
  }
}
li.interconnectBtnAdd.active {
  border-top-left-radius: 8px !important;
}
@media (max-width: 576px) {
  #content {
    max-height: -webkit-fit-content !important;
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
  }
}
@media (max-width: 992px) {
  #content {
    max-height: -webkit-fit-content !important;
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
  }
}
@media (max-width: 505px) {
  .btnAlign {
    margin-bottom: 10px !important;
    width: 100% !important;
  }
}
@media (max-height: 650px) {
  ul.navList {
    height: 180px !important;
  }
}
.filter-top .link {
  color: #000000 !important;
}
@media (max-width: 450px) {
  .amount-div {
    height: 150px;
  }
}
@media (max-width: 1323px) {
  span.header_icon_text_custom {
    display: none;
  }
}
@media (min-width: 374px) {
  .settings-list {
    right: -5px;
  }
}
.btnediCheck.align-items-end {
  margin-top: 10px;
}
/* .ng-select .ng-clear-wrapper {
    display: none !important;
} */
.joyride-backdrop.backdrop-left {
  width: 0px !important;
  background-color: #0d0e0e !important;
}
.backdrop-target {
  width: 0px !important;
}
p.errorTooltip {
  z-index: 1 !important;
}
@-webkit-keyframes target-fade-dark {
  0% {
    background-color: rgba(255, 255, 153, 0);
  }
  100% {
    background-color: #b6eb00;
  }
}
.setCirclehold {
  color: orange;
  font-size: 10px !important;
}
.smart-style-2 .MessageBoxButtonSection button {
  border-radius: 15px;
  background: transparent;
  border: 2px solid var(--theme-color-1) !important;
  font-size: 13px;
  padding: 3px 15px;
  color: #050505 !important;
  font-weight: 400 !important;
}
.accounts-repeat-section {
  display: flex !important;
  align-items: flex-start !important;
}
.bulk-form label {
  font-size: 14px !important;
}
.smart-style-1 .form-control {
  font-size: 14px !important;
}
.smart-style-2 .form-control {
  font-size: 14px !important;
}
.jarviswidget {
  position: static !important;
}
.pdfxm-service-div::-webkit-scrollbar {
  display: none;
}
.pdfxm-service-div {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.smart-style-1 #logo-group {
  background-color: var(--logo-color) !important;
}
.smart-style-2 #logo-group {
  background-color: var(--logo-color) !important;
}
.epost .epostlogoBackGround {
  background-repeat: no-repeat;
  background-size: 100%;
  height: 140px !important;
  width: 62px !important;
  display: block;
  margin-top: 1px !important;
  margin-left: 82px !important;
}
.epost .green-label {
  color: var(--theme-color-1) !important;
}
.epost .bg-color-red {
  background-color: var(--theme-color-1) !important;
}
.epost .smart-style-2 #left-panel nav ul.edi li.active a {
  color: #000 !important;
}
.sender_ellipsis_col {
  white-space: nowrap;
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.ellipsis_col {
  white-space: nowrap;
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.transaction_ellipse {
  white-space: nowrap;
  width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
