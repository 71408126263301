@use "sass:map";
@import "commonstyle";
$darktheme-colors: ("bgcolor": #171717,
	"datepicker-bgcolor": #303030,
	"bordercolor": #333333,
	"widgetbgcolor": #262626,
	"background-color": #0C1214,
	"textcolor": #FFFFFF,
	"popup-bg-color":#171819);
$whitetheme-colors: ("bordercolor": #CCCCCC,
	"bgcolor": #EDEDED,
	"color": #4C4F53,
	"commonbgcolor":#F7F7F7,
	"widgetbgcolor": #EDEDED,
	"textcolor": #666666,
);

#main {
	height: calc(100vh - 100px) !important;
	overflow-y: scroll;
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	padding-bottom: 2px;
	/* Firefox */
}

table thead tr th {
	font-size: 14px;
	font-weight: 400;
}

#main::-webkit-scrollbar {
	display: none;
}

.headticket {
	opacity: 0.4 !important;
}

table {
	width: 100%;
	background-color: inherit !important;
	color: $primary-color;
	border-collapse: collapse;
}

.toggle-demo {
	position: absolute;
	top: 10px;
	right: -8px;
	display: block;
	width: 154px;
	height: auto;
	z-index: 99;
	opacity: .9;

	&:hover {
		opacity: 1;
	}

	.btn-group {
		width: 100px;
		float: left;
	}

	span {
		display: inline-block;
		float: left;
		margin-right: 5px;
		line-height: 21px;
		color: #757575;
	}
}

.demo-vertical-uislider {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;

	div {
		height: 200px;
		margin: 0 0 0 10px;
		display: inline-block;
	}

	&:first-child {
		margin-left: 15px;
	}
}

.knobs-demo {
	div {
		display: inline-block;
		margin-left: 30px;
	}

	&:first-child {
		margin-left: 0;
	}
}

.demo {
	$demobgcolor: #F1DA91;

	label {
		input[type=checkbox].checkbox+span {
			&:before {
				border: 1px solid #928585;
			}
		}

		input[type=radio].radiobox+span {
			&:before {
				border: 1px solid #5E5E5E;
			}
		}

		&:hover {
			input[type=checkbox].checkbox+span {
				&:before {
					border-color: #E77317;
				}
			}
		}

		margin-bottom: 4 !important;
	}

	.checkbox {
		i {
			border-color: $subtext-color;
		}

		padding-left: 22px;
	}

	.radio {
		padding-left: 22px;
	}

	#demo-setting+form {
		display: none;
	}

	position: absolute;
	top: 5px;
	right: 0;
	width: 160px;
	z-index: 902;
	background: $demobgcolor;

	span {
		position: absolute;
		right: 0;
		top: 0;
		display: block;
		height: 30px;
		width: 30px;
		border-radius: 5px 0 0 5px;
		background: $demobgcolor;
		padding: 2px;
		text-align: center;
		line-height: 28px;
		cursor: pointer;

		i {
			font-size: 17px;
		}

		&:hover {
			i {
				color: #333333 !important;
			}
		}
	}

	form {
		section {
			margin: 0 !important;
		}
	}

	.btn {
		font-size: 13px !important;
		line-height: 1.5 !important;
		border-radius: 3px !important;
		padding: 3px 7px !important;
		height: auto !important;
	}
}

.demo.activate {
	right: 0 !important;
	box-shadow: -11px 12px 23px rgba(0, 0, 0, .2);
	padding: 5px 10px 10px;

	#demo-setting+form {
		display: block;
	}

	span {
		i {
			&:before {
				content: "\f00d";
			}
		}

		left: -30px;
	}
}

.demo-liveupdate-1 {
	font-size: 12px !important;
	position: absolute !important;
	left: 33px;
	top: 15px;
	display: block;
	z-index: 2;
	font-weight: 700;
	padding: 1px 61px 3px 7px;
}

.demo-btns {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		margin-bottom: 7px;
	}
}

.demo-icon-font {
	font-size: 14px;
	margin-bottom: 6px;
	padding-top: 4px;
	padding-bottom: 4px;

	&:hover {
		background-color: rgba(86, 61, 124, .1);
		text-decoration: none;
	}
}

.demo-modal {
	width: auto;
	max-width: 600px;
	z-index: 1;
}

.demo-red {
	color: #DF413E;
}

.demo-orange {
	color: #DAA03E;
}

.demo-border {
	border: 1px solid rgba(0, 0, 0, .2);
}

.nav-demo-btn {
	display: none;
}

body {
	&:not(.menu-on-top) {
		.nav-demo-btn {
			display: block;
			padding: 6px 5px;
			margin: 5px 10px;
			width: auto;
			border-radius: 5px;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			font-size: 12px;
			white-space: normal;
		}
	}
}

.fixed-navigation {
	.nav-demo-btn {
		display: none !important;
	}
}

.minified {

	.nav-demo-btn,
	.ac_ConnectionSection {
		display: none !important;
	}
}


.smart-rtl {
	.demo {
		.margin-right-5 {
			margin-right: 0 !important;
		}
	}
}


.applicationIcon {
	background-repeat: no-repeat;
	background-size: 100% 71%;
	top: 15px;
	position: relative;
}

// .setArrowBlue {
// 	color: #6558F5 !important;
// }

.setArrowOrange {
	color: #E8833A !important;
}

.processtablLbl {
	background-color: $common_base_color;
	color: $primary-color;
	border-radius: 15px;
	padding: 5px;
	font-size: 10px;
	display: block;
	text-align: center;
}

.inactiveLbl {
	background-color: #EA2027;
	color: $primary-color;
	border-radius: 15px;
	padding: 5px;
	font-size: 10px;
	display: block;
}

.activeLbl {
	background-color: $buttoncolor;
	color: $primary-color;
	border-radius: 15px;
	padding: 5px;
	font-size: 10px;
}

.holdLbl {
	background-color: #535353;
	color: $primary-color;
	border-radius: 15px;
	padding: 5px;
	font-size: 10px;
	display: block;
}

.scroll-dashboard::-webkit-scrollbar {
	display: none;
}

.scroll-dashboard {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.scroll-content::-webkit-scrollbar {
	display: none;
}

.scroll-content {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

nav ul li a:hover {
	background: $tabsborder-color !important;
}

.jarviswidget>header h2 {
	font-size: 16px !important;
	// font-weight: 600;
}

.jarviswidget-ctrls .button-icon {
	color: $common-color;
}

#header {
	.menu-item {
		margin: 0px 0px 2px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 60px;
		cursor: pointer;
	}

	.menu-item_pading {
		padding: 0 10px;
	}

	.menu-left-item {
		padding: 0 10px;
	}

	.company-title {
		font: normal normal bold 12px/19px Open Sans;
		text-transform: uppercase;
	}

	.company-name {
		font: normal normal normal 14px/19px Open Sans;
	}

	#fullscreen {
		.svg-icon {
			background : {
				repeat: no-repeat;
				position: center;
			}

			width: 60px !important;
			height: 45px !important;
			margin-top: -3px;
		}
	}

	.notification-svg {
		background : {
			repeat: no-repeat;
			position: center;
		}

		width: 60px !important;
		height: 45px !important;
	}

	#activity.activity-svg {
		background : {
			repeat: no-repeat;
			position: center;
		}

		width: 60px !important;
		height: 45px !important;
	}

	.collapseMenu {
		.collaspe-menu-svg {
			background : {
				repeat: no-repeat;
				position: center;
			}

			height: 30px !important;
		}

		.collaspe-menu-sub-svg {
			background-repeat: no-repeat;
			width: 33px !important;
			height: 45px !important;
		}
	}

	.header-btn {
		&:hover {
			border-color: $common_base_color !important;
			background: $common_base_color !important;
		}
	}

	.dropdown-icon-menu {
		ul {
			li {
				.btn {
					&:hover {
						border-color: $common_base_color !important;
						background: $common_base_color !important;
					}
				}
			}
		}
	}
}

.header-menu {
	font: normal normal normal 14px/19px Open Sans;
}

.notification-body>li {
	border: 1px solid #e3e3e3 !important;
}

.iom_btn {
	border-radius: 15px;
	background: transparent;
	border: 2px solid $commonbase-color;
	font-size: 13px;
	padding: 3px 15px;
	line-height: 20px;
	font-weight: 400;

	&:hover {
		border-color: $commonbase-color !important;
		background: $commonbase-color !important;
	}
}

/*----------------- Header ----------------- */
.header-dropdown-list {
	.dropdown-menu {
		li {
			a {
				border-radius: 0 !important;
				font-size: 14px;
			}
		}

		padding: 4px;
		overflow: auto;
		max-height: 200px;

		&::-webkit-scrollbar {
			width: 0px;
		}

		border-radius: 10px;
	}

	a.dropdown-toggle {
		color: $primary-color;
		margin-top: 6px;
	}
}


.activity.ajax-dropdown {
	&:first-child {
		margin: 0;
		padding: 0;
	}
}

.activity {
	.ajax-notifications {
		border-bottom: none;
		border-top: none;
	}
}

.scroll-dashboard {
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: $widget-color;
	}

	&::-webkit-scrollbar {
		width: 0px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $datepicker-bgcolor;
	}

	overflow-y: scroll !important;
	z-index: 1;
}

.nav-tabs>li>a {
	border-radius: 0;
	color: $secondaryFocus;
	font: normal normal bold 13px/18px Open Sans;
}

.scroll-tb {
	border: 0px solid $primary-color !important;
	border-top: transparent !important;
	height: 734px !important;
	overflow-y: scroll !important;
	margin-bottom: -9px !important;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.scroll-tbs {
	height: 170px !important;
	overflow-y: auto !important;

	margin : {
		top: 10px;
		left: -3px;
	}

	// -ms-overflow-style: none;
	// scrollbar-width: none;

	&::-webkit-scrollbar {
		width: 5px !important;
		height: 5px !important;
	}

	&::-webkit-scrollbar-track {
		// border: 1px solid #202124;
		border-radius: 5px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $whiteborder-color;
		// border: 1px solid #202124;
		border-radius: 5px;
	}
}

// Scroller for pdf view in the invoice page
.scroll-tbs-pdf {
	height: 170px !important;
	overflow-y: auto !important;

	margin : {
		top: 10px;
		left: -3px;
	}

	&::-webkit-scrollbar {
		width: 0px !important;
		height: 5px !important;
	}

	&::-webkit-scrollbar-track {
		border-radius: 5px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $whiteborder-color;
		border-radius: 5px;
	}
}

.dashHeadLocation {
	color: $primary-color !important;
	display: flex;
	list-style: none;
	padding: 0;
}

.listitems {
	font-size: 16px;
	color: $primary-color;
}

.fixed-head {
	// position: sticky;
	top: 0;
	z-index: 100;
}

.table {
	thead {
		&:first-child {
			tr {
				&:first-child {
					th {
						border-bottom: none !important;
					}
				}
			}
		}
	}
}

.rightAlign {
	text-align: right;
}

.big-spin-loader {
	background: url(../img/spin-loader.svg) no-repeat;
	width: 50px;
	height: 50px;
	position: absolute;
	left: 0px;
	right: 0px;
	margin: 5% auto;
	-ms-transform: translate(0, -50%);
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	top: 50%;
}

.big-spin-page-loader {
	background: url(../img/spin-loader.svg) no-repeat;
	width: 50px;
	height: 50px;
	position: absolute;
	left: 0px;
	right: 0px;
	margin: 5% auto;
	-ms-transform: translate(0, -50%);
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	top: 50%;
	z-index: 100;
}

table.dataTable {
	tbody {
		tr.selected {
			background-color: $common_base_color !important;
			border-color: #CCCCCC;
			color: $common-color !important;

			&:hover {
				background-color: $common_base_color !important;
				border-color: #CCCCCC;
			}
		}
	}
}

tr.selected .btn-primary {
	color: #000 !important;
}

a.btn.disabled {
	cursor: not-allowed !important;
}

nav {
	ul {
		li.active {
			a {
				&:before {
					right: -15px !important;
					content: none !important;
				}
			}
		}
	}
}


.minified {
	#help_text {
		display: none;
	}

	#help_text1 {
		display: none;
	}

	#help_text_div {
		display: none;
	}

	#help_text_btn {
		display: none;
	}

	#clearCache_text,
	.module-name {
		display: none;
	}

	#hide-menu {
		display: block;
	}

	.footer-menu {
		padding: 12px 0px;
	}

	.tutorial-popup {
		width: 43px !important;
		padding: 3px 12px;
	}

	.module {
		padding: 5px 5px 5px 10px !important;

		.module-img {
			width: 26px !important;
		}
	}

	.chatIcon {
		margin-left: 8px;
	}

	#clearCache {
		.iom_btn {
			padding: 3px 13px !important;
		}
	}

	.ebill-icon {
		width: 26px !important;
	}

	.hrColor {
		display: none;
	}

	.ebill-nav {
		padding: 5px 8px !important;
	}

	#left-panel {
		padding-top: 0px !important;
	}
}

.fixed-navigation {
	nav {
		&::-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;
		scrollbar-width: none;
	}
}


.hidden-menu .page-footer {
	padding-left: 20px;
}

.default-dropdown {
	.input-group {
		.form-control {
			padding-right: 5px !important;
		}
	}
}

.header-formbtn {
	width: auto;
	margin-top: 11px !important;
	border: none;
	text-align: center !important;
	letter-spacing: 0;
	font-weight: bold;
	font: normal normal normal 13px/20px Open Sans !important;
}

.advace-header-formbtn {
	height: 35px;
	margin-top: 26px !important;
	width: 100px;
	border: none;
	text-align: center !important;
	letter-spacing: 0;
	font-weight: bold;
	font: normal normal normal 13px/20px Open Sans !important;
	border-radius: 30px;
}




.colon-symbol {
	label {
		position: relative;

		&:after {
			content: ":";
			position: initial;
			right: -12px;
			top: 0px;
		}
	}
}

formly-field .colon-symbol label {
	display: inline-block;
}

.input-group {
	.form-control {
		position: relative;
		z-index: 2;
		float: left;
		width: 100%;
		margin-bottom: 0;
		font-size: 12px;
		height: 35px;
	}
}

.form-group {
	.form-control {
		position: relative;
		// z-index: 2;
		float: left;
		width: 100%;
		margin-bottom: 0;
		font-size: 12px;
		height: 40px;
	}

	textarea {
		&.form-control {
			height: unset !important;
			border-top-right-radius: 10px !important;
			border-bottom-right-radius: 10px !important;
		}
	}
}

.stepsinout-form.formly,.fileuploadform-form
.businessconnect-form.formly {
	.input-group-text {
		font-size: 13px;
		// width: 29px;
		font-weight: 400;
		color: $secondaryFocus;
		text-align: center;
		border: unset !important;
	}
}

.blacktooltip {
	text-align: left;
	z-index: 1000;
	position: fixed;
	width: 150px;
	height: 86px;
	border-radius: 5px;
	font: normal normal normal 13px/18px Open Sans;

	.insideDivUl {
		list-style: none;
		padding: 5px;
		display: flex;
		flex-direction: column;

		li {
			height: 38px;
			line-height: 38px;
			cursor: default;
		}
	}
}
.tooltip-text{
	text-align: left;
	z-index: 1000;
	position: absolute;
	border-radius: 5px;
	font: normal normal normal 13px/18px Open Sans;
}

.idTooltip,
.btnTooltip {
	text-align: center;
	z-index: 99999;
	position: absolute;
	width: 170px;
	height: 38px;
	border-radius: 5px;
	font: normal normal normal 13px/18px Open Sans;

	.insideDivUl-nohover {
		z-index: 99999;
		list-style: none;
		padding: 10px;
		display: flex;
		flex-direction: column;
	}
}

.errorTooltip {
	z-index: 99999;
	position: absolute;
	min-width: 200px;
	height: auto;
	border-radius: 5px;
	max-width: 700px;
	word-break: break-word;
	text-align: left;
	padding: 5px;
	position: absolute;
	left: 20px;
	right: 20px;
	font: normal normal normal 13px/18px Open Sans;

	.insideDivUl-nohover {
		z-index: 99999;
		list-style: none;
	}
}

.header-search-form.formly.horizontal {
	.form-control {
		font-size: 13px;
		font-weight: 400;
	}

	formly-field {
		height: 40px !important;
		line-height: 40px;
	}

	formly-field-input {
		width: calc(100% - 110px);
		float: right;

		input {
			height: 40px !important;
		}
	}

	formly-wrapper-addons {
		width: calc(100% - 110px);
		float: right;

		formly-field-input {
			width: 100%;
		}
	}

	formly-field-textarea {
		width: calc(100% - 110px);
		float: right;
	}

	formly-field-select {
		width: calc(100% - 110px);
		float: right;

		select {
			height: 40px !important;
		}
	}

	header {
		display: block;
	}

	display: block;

	.default-dropdown {
		formly-field-select {
			select {
				padding-right: 5px !important;
			}
		}
	}

	formly-wrapper-form-field {
		.form-group {
			label {
				width: 110px;
				text-align: center;
				color: $primary-color;
				font-size: 14px;
			}
		}
	}
}

.header-search-form.formly {
	.input-group-append {
		.input-group-text {
			height: 36px;
			z-index: 100;

			margin : {
				left: -40px;
				top: 2px;
			}
		}

		margin-left: 12px !important;
	}
}

.default-dropdown {
	.input-group {
		.form-control {
			padding-right: 5px !important;
		}
	}
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-tb-inbox {
	border: 0px solid $primary-color !important;
	border-top: transparent !important;
	height: 677px !important;
	overflow-y: scroll !important;
	margin-bottom: -9px !important;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

/* NEED TO CHANGE START */
.stepsinout-form {
	.custom-checkbox {
		.custom-control-label {
			&::before {
				width: 1.2rem;
				height: 1.3rem;
				top: .5rem;
			}

			&::after {
				top: 0.4rem;
				left: -1.7rem;
				width: 1.5rem;
				height: 1.5rem;
			}
		}
	}
}

.fileExport {
	.form-group {
		display: flex;
	}
}

.stepsinout-form {
	.fileExport {
		formly-field-input {
			margin-left: 20px;
		}
	}

	.custom-radio {
		.custom-control-label {
			// &::before {
			// 	top: .6rem;
			// }

			margin-right: 20px;
		}

		.custom-control-input {
			&:checked~.custom-control-label {
				&::after {
					background: none;
				}
			}
		}
	}

	.custom-radio.custom-control {
		display: inline-block;
	}
}

.form-control.ng-invalid.is-invalid {
	border-color: $invalid-bordercolor !important;
}

.form-control.is-invalid {
	background-image: none !important;
}

.was-validated {
	.form-control {
		&:invalid {
			background-image: none !important;
			border-color: $invalid-bordercolor !important;
		}
	}
}

// .font-size-16 {
// 	font-size: 16px !important;
// }

.form-group.has-error {
	formly-wrapper-addons {
		.input-group {
			.input-group-append {
				border: 1px solid $invalid-bordercolor !important;
				border-left: none !important;
				border-top-right-radius: 10px !important;
				border-bottom-right-radius: 10px !important;
			}

			.input-addons {
				border: 1px solid $invalid-bordercolor !important;
				border-right: none !important;
				border-top-left-radius: 10px !important;
				border-bottom-left-radius: 10px !important;

			}

			.form-control.is-invalid {
				border: none !important;
			}
		}
	}
}

.btn-success {
	&:focus {
		box-shadow: 0 0 0 0.2rem rgb(40 167 69 / 50%);
		color: $primary-color;
		background-color: $buttoncolor;
		border-color: $buttoncolor;
	}
}

.businessconnect-form {
	margin-top: 45px;
}

.labelOr {
	text-align: center;
	margin-top: 30px;
}

.dataTables_processing {
	text-indent: -99999px;
	border: 0px !important;
	box-shadow: none !important;
	height: 55px;
	width: 55px;
	z-index: 999;
	margin-top: 100px !important;
	background-position: center !important;
}

.lstposTbale {
	.dataTables_processing {
		display: block;
		text-indent: -99999px;
		border: 0px !important;
		box-shadow: none !important;
		z-index: 999;
		margin-top: 15px !important;
		background-position: center !important;
	}

	.dt-toolbar {
		background: none !important;
		border: none !important;
		display: none;
	}
}

.blur-foreground {
	filter: blur(5px);
}

.proccesLogTable {
	height: 141px !important;
	overflow-y: scroll;

	// -ms-overflow-style: none;
	// scrollbar-width: none;
	// &::-webkit-scrollbar {
	// 	width: 5px;
	// 	height: 5px;
	// }
	&::-webkit-scrollbar {
		width: 1px;
	}
}

// .outbox-detail-document {
// 	&::-webkit-scrollbar {
// 		display: none;
// 	}

// 	-ms-overflow-style: none;
// 	scrollbar-width: none;
// }

.dataTable tbody tr td {
	font-size: 13px;
}

/* For full screen */
#jarviswidget-fullscreen-mode {
	.jarviswidget {
		div {
			// height: 905px !important;
		}
	}

	.row {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
}

.ui-datepicker {
	border-radius: 5px !important;
	z-index: 2000 !important;

	select.ui-datepicker-month {
		margin: 0 2px;
		width: 47%;
	}

	select.ui-datepicker-year {
		margin: 0 2px;
		width: 47%;
	}
}

.ui-datepicker {
	td {
		.ui-state-default {
			color: $primary-color;
		}
	}

	.ui-datepicker-next {
		span {
			background: none !important;
		}
	}

	.ui-datepicker-prev {
		span {
			background: none !important;
		}
	}
}

.ui-datepicker-prev {
	span {
		background-image: none !important;
	}
}

.ui-datepicker-next {
	span {
		background-image: none !important;
	}
}

.ui-datepicker-prev {
	&:before {
		font-family: FontAwesome;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		font-weight: normal;
		align-items: center;
		justify-content: center;
		background: $primary-color;
		z-index: 9;
		padding-top: 4px;
		content: "\f053";
	}
}

.ui-datepicker-next {
	&:before {
		font-family: FontAwesome;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		font-weight: normal;
		align-items: center;
		justify-content: center;
		background: $primary-color;
		z-index: 9;
		padding-top: 4px;
		content: "\f054";
	}
}

.flag.flag-en {
	background-position: -144px -154px;
}

.input-icons i {
	position: absolute;
}

.input-icons {
	width: 100%;
	margin-bottom: 10px;
}

.icon {
	padding: 10px;
	color: $primary-color;
	min-width: 50px;
	text-align: end;
	right: 15px;
	top: 8px;
	font-size: 14px;
}

.input-field {
	width: 100%;
	padding: 10px;
	text-align: left;
}

#ribbon {
	ol.breadcrumb {
		font-size: 13px;
		font-weight: 600;
	}
}

#left-panel {
	padding-top: 10px !important;

	.login-info {
		height: 41px !important;
	}
}

.borderRightClr {
	height: 33px !important;
	padding: 6px 0px 0px 15px !important;
	border-radius: 0;
}

.minified {
	.borderRightClr {
		padding: 0px !important;
		height: 40px !important;
	}
}

.filter-form-select {
	border: 1px solid $tabsborder-color !important;
	border-radius: 5px !important;
}

.selectedBox {
	border: 1px solid $tabsborder-color !important;
	border-radius: 5px !important;

}

table.dataTable {
	>tbody {
		>tr.child {
			ul.dtr-details {
				display: block;
			}
		}
	}
}

table.dataTable.dtr-inline.collapsed {
	>tbody {
		>tr.child {
			>td {
				&:first-child {
					padding-left: 10px;
				}
			}
		}
	}
}

#divSmallBoxes {
	top: 30px !important;
}

.dashbaordlabel {
	margin-top: 4px;
}

.ebilllabel {
	margin-top: 9px;
}

.dropzone .dz-default.dz-message {
	margin: unset !important;
	width: unset !important;
}

.lstTransatiosectionHeadDiv {
	.jarviswidget {
		header {
			h2 {
				overflow: hidden !important;
				text-overflow: ellipsis !important;
				white-space: nowrap;
			}
		}
	}
}

.serviceHead {
	padding: 0 !important;
	margin: 5px 0 10px;
	color: $primary-color;
	font-size: 20px;
}

.sub-btn {
	font-size: 14px;
	border-radius: 15px;
	background-color: transparent !important;
	padding: 3px 15px;
	&:active{
		top: 0px !important;
		left: 0px !important;
	}
}

@media(max-width:280px) {
	.dropzone .dz-default.dz-message {
		// margin-left: -35px !important;
		margin-top: 5.5px !important;
	}

}

@media (max-width: 500px) {

	.header-formbtn,
	.advace-header-formbtn {
		width: 100% !important;
	}
}

@media (min-width: 576px) {
	.dt-toolbar-footer {
		.col-xs-12.col-sm-7 {
			float: right !important;
		}
	}
}

@media screen and (max-width: 1545px) {
	.inbox-data-table {
		+.dt-toolbar-footer {
			display: flex !important;
			flex-direction: column !important;
		}

		+ {
			.dt-toolbar-footer {
				div {
					&:last-child {
						align-self: self-end !important;
					}
				}
			}
		}
	}

	.outbox-data-table {
		+ {
			div.dt-toolbar-footer {
				display: flex !important;
				flex-direction: column !important;
			}

			.dt-toolbar-footer {
				div {
					&:last-child {
						align-self: self-end !important;
					}
				}
			}
		}
	}

	.scroll-tbs {
		height: 173px !important;
	}

	.scroll-tbs-pdf {
		height: 173px !important;
	}
}

@media screen and (max-width: 975px) {
	.dt-toolbar-footer {
		display: flex !important;
		flex-direction: column !important;

		div {
			&:last-child {
				align-self: self-end !important;
			}
		}
	}
}

@media (max-width: 576px) {
	.invoiceOverview {
		ul.tabgrid {
			display: flex !important;
			flex-direction: column !important;
		}
	}

	.nav-tabs {
		li {
			width: 100% !important;
		}
	}

	.joyride-step__container {
		width: auto;
	}

	textarea.in,
	textarea.modal-in {
		width: auto;
	}
}

// @media only screen and (min-width: 0) and (max-width: 315px) {
// 	#left-panel {
// 		padding-top: 250px !important;
// 	}
// }

// @media only screen and (min-width: 316px) and (max-width: 480px) {
// 	#left-panel {
// 		padding-top: 190px !important;
// 	}
// }

// @media only screen and (min-width: 481px) and (max-width: 585px) {
// 	#left-panel {
// 		padding-top: 165px;
// 	}
// }

// @media (min-width: 586px) and (max-width: 976px) {
// 	#left-panel {
// 		padding-top: 100px;
// 	}
// }

@media (max-width: 1199px) {
	.header-search-form.formly {
		.input-group-append {
			margin-left: 0px !important;
		}
	}
}

@media (min-width: 1200px) and (max-width:1375px) {

	.header-search-form.formly.horizontal {
		formly-wrapper-form-field {
			.form-group {
				label {
					width: 75px !important;
				}
			}
		}

		formly-field-input {
			width: calc(100% - 75px);
		}

		formly-wrapper-addons {
			width: calc(100% - 75px);
		}

		formly-field-textarea {
			width: calc(100% - 75px);
		}

		formly-field-select {
			width: calc(100% - 75px);
		}
	}

	.header-formbtn,
	.advace-header-formbtn {
		width: 75px !important;
	}
}

@media (max-width: 767px) {
	.header-search-form.formly {
		.input-group-append {
			margin-left: -4px !important;

			.input-group-text {
				margin-left: -23px;
			}
		}
	}
}

@media (max-width: 979px) {
	.page-footer {
		padding: 5px 14px 0;
	}
}

@media(min-width: 1196px) and (max-width: 1364px) {

	#main {
		height: calc(100vh - 50px) !important;
	}
}

#left-panel {
	position: absolute;
	//     left: 0;
	z-index: 904;
	// padding-top: 38px;
	height: 100px !important;
	top: 49px;
}

.minified {
	.userlabel {
		display: none !important;
	}

	.body-image {
		width: 40px !important;
		height: 40px !important;
		margin: auto;
	}
}

.MessageBoxButtonSection button {
	border-radius: 15px;
	background: transparent;
	border: 2px solid $commonbase-color !important;
	font-size: 13px;
	padding: 3px 15px;
	color: $primary-color !important;
	font-weight: 400 !important;

}

.MessageBoxButtonSection button:hover {
	border-radius: 15px;
	background: $commonbase-color !important;
	border: 2px solid $commonbase-color !important;
	font-size: 13px;
	padding: 3px 15px;
	color: $common-color !important;
}

.theme-header-color {
	color: $base-color !important;
}

.border-line-none {
	background: transparent !important;
	border: none;
}

.jarviswidget {
	div {
		border: none;
	}
}

/* .NextconfirmLabel {
	font: normal normal normal 13px/24px Open Sans;
	width: 193px;
	letter-spacing: 0px;
}
 */
.popover {
	display: none;
}

.editCommunication,
.deleteCommunication {
	padding: 2px 15px !important;
	border-radius: 13px !important;
}

.note-icon-caret {
	display: none;
}

.btn-primary {
	&:not(:disabled) {
		&:not(.disabled) {
			&:active {
				border-color: $common_base_color !important;
			}
		}
	}
}

table.positionTable {
	thead {
		tr {
			th {
				&:first-child {
					border-radius: 5px 0px 0px 0px;
				}

				&:last-child {
					border-radius: 0px 5px 0px 0px;
				}
			}
		}
	}

	tbody {
		tr {
			&:last-child {
				td {
					&:first-child {
						border-radius: 0px 0px 0px 5px;
					}

					&:last-child {
						border-radius: 0px 0px 5px 0px;
					}
				}
			}

		}
	}
}

.fa-exclamation {
	color: red;
	font-size: x-large;
	padding: 0px 10px;
}

.MsgTitle .txt-color-logorangeDark {
	color: #a57225 !important;
}

.serviceTheme {
	border-radius: 10px !important;

	.chip_Section {
		border-radius: 10px !important;
	}

	.serachService {
		border-top-left-radius: 10px !important;
		border-bottom-left-radius: 10px !important;
		height: 35px;
	}

	#basic-text1 {
		// background-color: #202124 !important;
	}

	.input-group-append {
		.input-group-text {
			border-top-right-radius: 10px !important;
			border-bottom-right-radius: 10px !important;
		}
	}
}

.minified {
	.profile-image-box {
		display: none;
	}

	ul.navList {
		margin-top: 34px !important;
	}

	.chatIcon img {
		margin-left: -8px;
	}
}

.ui-dialog-titlebar-close:before {
	content: "\f00d";
	font-family: FontAwesome;
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 20px;
}

.ui-dialog .ui-dialog-titlebar-close {
	position: absolute;
	right: 0.3em;
	top: 50%;
	width: 19px;
	margin: -20px 0 0 0;
	padding: 1px;
	height: 18px;
	font-size: 20px;
	font-weight: 400;
	line-height: 19.5px;
	text-shadow: none;
}

.card {
	border: none !important;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
	border-bottom-right-radius: 5px !important;
	border-top-right-radius: 5px !important;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
	border-bottom-left-radius: 5px !important;
	border-top-left-radius: 5px !important;
}

.dt-toolbar-footer {
	padding: 5px 0px;
}

.nextBtn {
	font-size: 13px;
	// width: 178px;
	background: transparent 0% 0% no-repeat padding-box !important;
	border-color: $commonbase-color !important;
	letter-spacing: 0px;
	border: 2px solid $commonbase-color !important;
	border-radius: 15px;
	color: $primary-color !important;
	padding: 3px 15px;

	&:hover {
		background: $commonbase-color !important;
		border: 2px solid $commonbase-color;
		color: $common-color !important;
	}

}

.btnAction,
.btnFile,
.btnSendinvit {
	font-size: 13px;
	// width: 178px;
	background: transparent 0% 0% no-repeat padding-box !important;
	border-color: $commonbase-color !important;
	letter-spacing: 0px;
	border: 2px solid $commonbase-color !important;
	border-radius: 15px;
	color: $primary-color !important;
	padding: 3px 15px;

	&:hover {
		background: $commonbase-color !important;
		border: 2px solid $commonbase-color;
		color: $common-color !important;
	}
}

.btnUpload {
	font-size: 13px;
	// width: 178px;
	background: transparent 0% 0% no-repeat padding-box !important;
	border-color: $commonbase-color !important;
	letter-spacing: 0px;
	border: 2px solid $commonbase-color !important;
	border-radius: 15px;
	color: $primary-color !important;
	padding: 3px 15px;
	;

	&:hover {
		background: $commonbase-color !important;
		border: 2px solid $commonbase-color;
		color: $common-color !important;
	}
}

.filter-top {
	font-size: 16px !important;
	letter-spacing: 0px;
	// font-weight: 600;
	font-family: "Open Sans", Arial, Helvetica, Sans-Serif;

	.link {
		font-weight: 400;
		font-size: 14px !important;
	}
}

.chatIcon {
	width: 25px;
	height: 25px;
	margin-right: 10px;
}

.footer-menu {
	padding: 12px 17px 12px 16px;
}

.settings-list {
	margin-top: 4px;
	min-width: 270px;
	// right: -5px;
	font-family: Open Sans, Arial, Helvetica, Sans-Serif;
	position: absolute;
	z-index: 1000;

	.settings-list-items-contents {
		display: block;
		max-height: calc(100vh - 120px);
		overflow: overlay;
		max-width: 320px;
		border-radius: 10px;
	}

	.settings-list-row {
		// color: #3b424c;
		padding: 15px 0px;
		display: flex;
		flex-wrap: wrap;

		.list-group-item {
			background-color: transparent !important;
			height: 35px;
			border: none !important;
			display: flex !important;
			flex-direction: row;

			div.settings-item-div {
				width: 90% !important;
				display: flex;
				flex-direction: row;
				align-content: center;
				align-items: center;
			}

			div.icon-div {
				padding: 0px 2px;
				display: flex;
				width: 25px !important;

				i {
					margin: auto;
				}
			}

			.count {
				border-radius: 50%;
				font-size: 13px;
				// top: 6%;
				// right: 8px;
				z-index: 10;
				background-color: $commonbase-color !important;
				height: 20px;
				width: 20px;
				font-weight: bold;
				color: $common-color !important;
				line-height: 09px;
				padding: 5px;
				display: flex;
				align-items: center;
				flex-direction: column;
			}
		}
	}

	.settings-list-box {
		width: 33.3333%;
		text-align: center;
		padding: 30px 20px 0;
		font-weight: 500;
	}

	.border-bootm-green {
		border-bottom: 1px solid $common_base_color;
	}
}

.amount-div {
	width: 30%;
	float: right;
	height: 100px;
	padding: 10px;
	position: relative;
	top: 148px;
	border-radius: 5px;

	.total-amount {
		font-size: 16px;
		font-weight: 400
	}

	.amountDiv {
		float: right;
		font-size: 16px;
		// font-weight: 500;
		text-align: right;
	}
}

.CodeMirror-line {
	// margin-left: 30px !important;
	font-size: 13px;
}

#preview .modal-body {
	padding: 1rem 0px;
}

.joyride-step__holder {
	border-radius: 15px;
}

.joyride-step__container {
	border-radius: 15px;
	width: 600px !important;
	max-height: 300px !important;

	.joyride-step__body {
		// height: 300px !important;
		overflow-y: auto !important;
		overflow-x: hidden;
		z-index: 1;

		&::-webkit-scrollbar {
			width: 5px !important;
			height: 5px !important;
		}

		&::-webkit-scrollbar-track {
			border-radius: 5px;
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $whiteborder-color;
			border-radius: 5px;
		}
	}
}

.joyride-button {
	border-radius: 20px;
	background: transparent !important;
	border: 2px solid $common_base_color !important;
	padding: 3px 15px !important;
	border-radius: 15px !important;
	font: normal normal normal 13px/20px Open Sans !important;
}

.joyride-step__close {
	text-align: right;
	letter-spacing: 0px;
	color: $common_base_color !important;
	opacity: 1;
	font-size: 18px;
	font-weight: bold;
	right: 15px !important;
	top: 15px !important;

	svg {
		display: none;
	}

	&:before {
		content: "\f00d";
		font-family: 'Font Awesome 5 Pro';
		left: 5px;
		position: absolute;
		top: -10px;
		font-size: 18px;
		font-weight: 900;
		text-align: right;
	}
}

.infoIcon {
	height: 20px;
	width: 20px;
	cursor: pointer;
	filter: $filter;
}

.infoFieldIcon {
	height: 14px;
	width: 14px;
	cursor: pointer;
	filter: $filter;
}

.infoServiceFieldIcon {
	height: 14px;
	width: 14px;
	cursor: pointer;
	filter: $filter;
}

.joyride-step__footer {
	justify-content: end !important;
	flex-direction: row-reverse !important;
	padding-top: 10px !important;
}

li.active {
	a.error {
		color: $buttondanger-color !important;
	}
}

.error_text {
	font-size: 13px;
}

button[disabled],
html input[disabled] {
	cursor: not-allowed;
}

.btn-save {
	display: none;
}

textarea.in {
	display: none;
	width: 570px !important;
	height: 173px !important;
	font-size: 13px;
	color: #ffff;
	background-clip: padding-box;
	background-color: unset !important;
	border: 1px solid #666666 !important;
	border-radius: 5px !important;

	&::-webkit-scrollbar {
		width: 0px;
	}

}

textarea.modal-in {
	min-width: 400px;
	// max-width: 500px;
	height: 300px;
	font-size: 13px;
	color: #ffff;
	background-clip: padding-box;
	background-color: unset !important;
	border: 1px solid #666666 !important;
	border-radius: 10px !important;
}

.tour-title-input {
	border-radius: 5px !important;
	height: 35px;
	width: 100%;
	color: #ffff;
	background-clip: padding-box;
	background-color: unset !important;
	border: 1px solid #666666 !important;
	font-size: 13px;
	margin-top: 15px;
}

.modal-title-input {
	// display: none;
	border-radius: 5px !important;
	height: 35px;
	width: 100%;
	color: #ffff;
	background-clip: padding-box;
	background-color: unset !important;
	border: 1px solid #666666 !important;
	font-size: 13px;
	margin-top: 15px;
	margin-right: -15px;
}

.joyride-step__counter-container {
	margin-right: 10px !important;
	margin-left: 5px !important;
}

.btn-primary {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: 0 0 0 0 rgb(38 143 255 / 50%);
			}
		}

		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: 0 0 0 0 rgb(38 143 255 / 50%);
				}
			}
		}
	}
}

.green-border {
	border: 1px solid $common_base_color !important;
}

.blue-border {
	border: 1px solid $common_base_color !important;
}

.accordianXpand {
	transition: all 0.2s ease-in-out 0s !important;
}

.green-label {
	color: $common_base_color !important;
}

tr.selected {
	span.green-label {
		color: $darkTextColor !important;
	}
}

nav ul ul {
	padding: 0px;

	li {
		padding-left: 43px;
	}
}

nav ul ul li>a:hover {
	background: $common_base_color !important;
}

nav ul ul li>a:hover {
	background: #666 !important;
}

nav ul b .fa-plus-square-o:before {
	content: "\f107" !important;
}

nav ul b .fa-minus-square-o:before {
	content: "\f106" !important;
}

nav ul ul li a {
	padding-left: 43px !important;
	padding-top: 6px !important;
	padding-bottom: 6px !important;
	color: #ffffff;
	font-size: 14px !important;
}

.textalignlabel {
	text-align: center;
}

.positionTable {
	thead tr {
		position: sticky;
		top: 0px;
	}

}

.contactUs {
	div {
		font-size: 14px;
	}
}

.ebillForm {
	formly-field-select {
		select {
			option {
				background-color: #202124 !important;
			}
		}
	}

	.commmonWrap {
		formly-group {
			width: 100% !important;
		}
	}
}

.commmonWrap {
	formly-group {
		width: 100% !important;
	}
}

.requiredSection {
	border-color: #dc3545 !important;
}

.requiredSector {
	color: #dc3545 !important;
	font-size: 13px !important;
}

.billerFormSection {
	formly-wrapper-form-field {
		div {
			font-size: 14px !important;
		}
	}
}

.biller_tabSection {
	.nav-item {
		font-size: 14px !important;
	}
}

.ebiller_tabSection {
	width: 100% !important;

	.nav-item {
		font-size: 14px !important;
		width: 10%;

		a {
			text-align: center;
		}
	}
}

.companySelect {
	border: none;
	border-radius: 10px;
	// background-color: #171717 !important;
}

.company-select {
	border: none;
	border-radius: 10px;
	background-color: #0C1214 !important;
}

.communicationPanel {
	display: block !important;

	.card {
		margin: 0 10px;

		.card-header {
			border-radius: 5px;
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
			color: #ffff;
		}

		.communicationBodyWrap {
			border: 1px solid #666;
			border-top: 0;
			margin: 0 1px 0 1px;
		}
	}
}

.includePdf {
	formly-field-radio {
		div {
			display: flex;

			.custom-radio {
				padding-left: 2.5rem;
			}
		}
	}
}

.invoiceTbl,
.subscribersTbl {
	.dt-toolbar {
		background-color: unset !important;
	}

	.sorting_disabled {
		background-color: #1E1E1E !important;
	}
}

.common_wizardItemContainer {
	.common_wizardItems {
		// width: 20% !important;
		width: 35% !important;
	}

	.wizard_format {
		width: 55% !important;
	}
}

.detail_save_btn {
	border-radius: 15px;
	background: transparent;
	border: 2px solid $commonbase-color;
	font-size: 13px;
	padding: 3px 15px;
	line-height: 19.5px;
	font-weight: 400;

	&:hover {
		border-color: $commonbase-color !important;
		background: $commonbase-color !important;
		color: $common-color !important;
	}

}

.biller_tabSection {
	li.active a {
		box-shadow: unset !important;
	}
}

.interConnPopupForm {
	small {
		font-size: 12px !important;
		color: red !important;
	}
}

.header-settings {
	.count {
		position: absolute;
		border-radius: 50%;
		font-size: 13px;
		top: 6%;
		right: 8px;
		z-index: 10;
		background-color: $commonbase-color !important;
		height: 20px;
		width: 20px;
		font-weight: bold;
		color: $common-color !important;
		line-height: 15px;
		padding: 2px;
	}
}

.backdrop-container {
	z-index: 1100 !important;
}

.joyride-step__holder {
	z-index: 1100 !important;
}

.preview-div {
	.preview-widget {
		display: flex;
		flex-direction: row;

		.left-side {
			flex: 0 0 100%;
			justify-content: center;
			align-items: center;
		}

		.right-side {
			flex: 0 0 30%;
			padding: 5px 5px 5px 10px;

			.err-status {
				display: flex;
				flex-direction: column;
				border-radius: 8px;
				height: 100px;
				padding: 15px;
				font-size: 15px;

				.text-status {
					font-size: 14px;
					font-weight: bold;
				}
			}
		}
	}
}

.invoice-table-status {
	td {
		padding: 5px 0;
	}
}

.tooltipA {
	position: relative;
	display: inline-block;
}

/* Tooltip text */
.tooltipA .tooltiptext {
	visibility: hidden;
	min-width: 180px;
	text-align: center;
	padding: 5px 10px;
	border-radius: 6px;
	/* Position the tooltip text - see examples below! */
	position: absolute;
	z-index: 100;
	top: -5px;
	right: 40%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipA:hover .tooltiptext {
	visibility: visible;
}

.error-div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 30px;

	div.error-div-div {
		width: 300px;
		width: 300px;
		height: 200px;
		border-radius: 8px;
		padding: 5px;
	}
}

ul.invoice-tab>li.active>a {
	border: 1px solid $common_base_color !important;
	border-bottom: none !important;
	border-left: none !important;
	border-right: none !important;
	box-shadow: 0 -2px 0 $common_base_color !important;
}

.table {
	tbody {
		tr {
			td {
				padding: 7px;
				line-height: 1.42857143;
				vertical-align: top;
			}
		}
	}
}

.invoice-tab-header {
	.nav-tabs {
		>li {
			text-align: center;

			>a {
				margin: auto;
			}
		}
	}
}

.businessFields {
	label {
		color: #fff !important;
		font-size: 14px;
	}
}

.editCommmunication {
	display: flex !important;
	align-items: center !important;
	margin-bottom: -15px;
	justify-content: end;
}

.radioLbl {
	color: #fff;
	// margin: 0 20px;
	font-size: 18px;
}

.filePattern {
	color: #E2E2E2 !important;
}

.serviceForm {
	formly-field {
		.invalid-feedback {
			formly-validation-message {
				font-size: 13px !important;
			}
		}
	}
}

.inputSection,
.selectSection {
	small {
		font-size: 13px !important;
	}
}

.dividerOr {
	.alignOr {
		display: flex !important;
		justify-content: center !important;
		margin: 0px !important;
		padding-top: 10px !important;
		font-size: 14px;
	}
}

.searchRecipientForm {
	formly-field {
		label {
			font-size: 13px;
		}

		formly-field-input {
			input {
				border-radius: 10px !important;
			}
		}

		formly-validation-message {
			font-size: 12px;
		}

	}
}

.file_export_filename {
	formly-wrapper-form-field {
		label {
			margin-bottom: 15px !important;
		}

		input {
			border-top-right-radius: 10px !important;
			border-bottom-right-radius: 10px !important;
		}
	}
}

.formatIcon {
	display: flex !important;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.commonHead-main {
	display: flex;
	justify-content: space-between;
	align-items: center;

	h2 {
		margin-left: 3px !important;
	}

	.right-side {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
	}
}

.tdd {
	table {
		table-layout: fixed !important;

		td {
			overflow: hidden !important;
			text-overflow: ellipsis !important;

			.tr-filename {
				word-break: break-all !important;
			}
		}
	}
}

.failImg {
	img {
		height: 75px;
		width: 75px;
	}
}

.modal-body {
	.text-danger.error {
		font-size: 14px;
	}
}

.minified nav>ul>li>a>.menu-item-parent {
	top: 1px !important;
}

.app-list {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
}

.app-tut {
	width: 220px;
	height: 220px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 20px;
	cursor: pointer;

	.app-inner-tut {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&:first-child {
		margin-left: 0px !important;
	}

	&:last-child {
		margin-right: 0px !important;
	}
}

.app-tut.disabled {
	cursor: not-allowed;
	opacity: 0.5;

	.app-inner-tut {
		pointer-events: none !important;
	}
}

.app-img {
	width: 100px;
	height: 100px;

	img {
		width: 100px;
		height: 100px;
	}
}

.app-title {
	margin-top: 10px;
	font-size: 14px;
	font-weight: bold;
}

.approveBtn {
	border-radius: 15px;
	background: transparent;
	border: 2px solid $commonbase-color;
	font-size: 13px;
	padding: 3px 15px;
	line-height: 19.5px;
	font-weight: 400;

	&:hover {
		border-color: $commonbase-color !important;
		background: $commonbase-color !important;
		color: $common-color !important;
	}

}

.bulk-form {
	width: 100%;

	label {
		font-size: 14px !important;
	}

	input {
		border-radius: 10px !important;
	}
}

.invalid-feedback {
	font-size: 11px !important;
}

// .billerclass{

// 	border-radius: 10px !important;
// 	border: 1px solid #333 !important;

// }
.defaultDot {
	height: 15px;
	width: 15px;
	border-radius: 50%;
	display: inline-block;
	position: relative;
}

.stepsinout-form select,
.businessconnect-form select {
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
}

.stepsinout-form,
.businessconnect-form {
	.input-group-append {
		.input-group-text:before {

			margin-left: -6px;
			padding-left: 6px;
		}
	}
}

.stepsinout-form {
	.stepsDown {
		.form-group {
			input {
				margin-top: -9px !important;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
			}
		}
	}
}

.btn-primary {
	&:focus {
		box-shadow: none;
	}
}

.ticketPopupClass,
.summernoteEditor {
	z-index: 99999 !important;
}

// .smart-style-2 .stepsinout-form.formly #accordionExpand .form-control {
// 	background-color: $primary-color !important;
// 	border-top-right-radius: 10px !important;
// 	border-bottom-right-radius: 10px !important;
// }

// @media(min-width: 888px) and (max-width: 1075px) { 

// 	#left-panel {
// 		top: 109px !important;
// 	}
//  }

@media(min-width: 980px) and (max-width: 1108px) {

	:not(.hidden-menu) {
		.wizard-items {
			width: 100% !important;
			flex-basis: 100% !important;

		}

		.wizard-billeritem {
			width: 100% !important;
			flex-basis: 100% !important;

		}

		.line-1 {
			height: 50px;
			width: 3px;
			left: 50%;
		}

		.wizardTextWidth {
			align-items: center !important;
		}

		.wizard-container {
			flex-direction: column !important;
		}

		.line {
			width: 100% !important;
		}

		.wizardDiv {
			margin-left: 20px !important;
		}
	}
}

.colorWhite {
	filter: none;
}

.smart-style-2.minified #left-panel {
	//     padding-top: 10px!important;
	position: absolute;
	z-index: 904;
	padding-top: 0px !important;
	height: 100px !important;
}


.serviceTheme.steps {
	.ui-dialog-titlebar {
		display: none;
	}

}

.commonBorderRadius {
	border-radius: 10px;
}

.dz-filename {
	padding-top: 20px;
}

// @media (min-width: 454px) and (max-width:690px) {
// 	.settings-list {
// 		left: 0px !important;
// 		right: unset !important;
// 	}
// }

.toolTipHideUserRole {
	width: 340px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-bottom: 0;
}

.roleLabel {
	width: 340px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: justify;
}

.tooltipRole {
	&:hover {
		cursor: pointer;

		.tooltiptextRole {
			visibility: visible;

			.insideDivUl {

				li:last-child {
					border-bottom: unset;
				}
			}
		}
	}

	position: relative;
	display: inline-block;

	.tooltiptextRole {
		visibility: hidden;
		// width: 125%;
		text-align: center;
		border-radius: 6px;
		padding: 5px 5px;
		word-break: break-all;
		position: fixed;
		z-index: 1;
		left: 38%;
		top: 0%;
		text-align: left;
		width: 150px;
		// min-height: 140px;
		font: normal normal normal 13px/18px Open Sans;
		border-radius: 10px;
		height: auto;

		.insideDivUl {
			list-style: none;
			padding: 5px;
			display: flex;
			flex-direction: column;
			margin-top: 0;
			margin-bottom: 0rem;

			li {
				height: 38px;
				line-height: 38px;
				cursor: default;
			}
		}
	}

	.toolTipRoleTitle {
		padding: 5px 5px !important;
		left: inherit !important;
		width: max-content;
		z-index: 1000;
	}
}

.toolTipHideUserRole {
	width: 340px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-bottom: 0;
}

.roleLabel {
	width: 340px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: justify;
}

.tooltipRole {
	&:hover {
		cursor: pointer;

		.tooltiptextRole {
			visibility: visible;

			.insideDivUl {

				li:last-child {
					border-bottom: unset;
				}
			}
		}
	}

	position: relative;
	display: inline-block;

	.tooltiptextRole {
		visibility: hidden;
		// width: 125%;
		text-align: center;
		border-radius: 6px;
		padding: 5px 5px;
		word-break: break-all;
		position: fixed;
		z-index: 1;
		left: 38%;
		top: 0%;
		text-align: left;
		width: 150px;
		// min-height: 140px;
		font: normal normal normal 13px/18px Open Sans;
		border-radius: 10px;
		height: auto;

		.insideDivUl {
			list-style: none;
			padding: 5px;
			display: flex;
			flex-direction: column;
			margin-top: 0;
			margin-bottom: 0rem;

			li {
				height: 38px;
				line-height: 38px;
				cursor: default;
			}
		}
	}

	.toolTipRoleTitle {
		padding: 5px 5px !important;
		left: inherit !important;
		width: max-content;
		z-index: 1000;
	}
}

.CodeMirror-wrap pre {
	word-break: break-word;
}

.codePopup {
	width: 1500px !important;
}

.CodeMirror {
	height: 600px !important;
	font-size: 16px;
}

#wizard {
	border-radius: 15px;
	padding: 0px 20px;

	.wizard-container {
		display: flex;
		flex-direction: row;
	}
}

/* #wizard1{
	border-radius: 15px;
	padding: 10px 20px;
	.wizard-container{
		display: flex;
		flex-direction: row;
	}
} */
.line-1 {
	height: 1px;
	width: 100%;
	z-index: 200;
	position: relative;
	top: 50% !important;
}

.line {
	width: 5%;
	z-index: 1;
	position: relative;
}

.wizardDiv {
	width: 50px;
	height: 50px;
	line-height: 50px;
	border-radius: 50%;
	font-size: 24px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;

}

.wizard-billeritem {
	width: 30%;
	border-radius: 10px;
	z-index: 100;
	position: relative;
	min-height: 90px;
	display: flex;
}

.wizard-items {
	width: 30%;
	border-radius: 10px;
	z-index: 100;
	position: relative;
	min-height: 90px;
	display: flex;

	&:hover {
		cursor: pointer;
	}

	&:first-of-type {
		&:before {
			display: none;
		}
	}

	&:last-of-type {
		&:after {
			display: none;
		}
	}

	&:last-child {
		margin-right: 0px !important;
	}
}

.font-size-14 {
	font-size: 14px !important;
}

.tickImage {
	// position: absolute;
	// height: 25px;
	width: 25px;
	// margin-left: 8px;
}

.wizard .plusBtn {
	position: absolute;
	right: 20px;
	cursor: pointer;
	top: 13px;
}

.wizard#accordionExpandWizard {
	border-radius: 10px;
	// color: $header-bgcolor;
	padding: 20px 20px 0px;
}

.wizardTextWidth {
	width: 75%;
	flex: 1 0 75% !important;
	padding: 0px 10px;
	display: flex;
	align-items: center !important;

}

.wizardTextButton {
	width: 75%;
	flex: 1 0 75% !important;
	padding: 0px 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

}

.wizardCircleWidth {
	width: 20%;
	flex: 1 0 20% !important;
}

b.badges {
	position: absolute;
	cursor: pointer;
	font-size: 10px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	padding: 2px 4px 3px;
	text-align: center;
	line-height: normal;
	content: "";
	top: -1px;
	right: -1px;
	transform: translate(25%, -25%);
	width: 25px;
	height: 25px;
	color: #111;
	background-color: $common_base_color;
	font-weight: 800;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bg-color-green {
	background-color: $common_base_color !important;
}

.bg-color-blue {
	background-color: $common_base_color !important;
}

@media (min-width: 980px) and (max-width: 1108px) {
	.wizard-container {
		flex-direction: column !important;
	}
}

@media (min-width: 1109px) and (max-width: 1170px) {
	.line {
		width: 100% !important;
	}
}

@media (min-width: 980px) and (max-width: 1170px) {
	:not(.hidden-menu) {
		#wizard {
			flex-direction: column;
		}

		#wizard1 {
			flex-direction: column;
		}

		.wizard-items {
			width: 100% !important;
		}

		.wizard-billeritem {
			width: 100% !important;
		}

		.common_wizardItemContainer {
			.common_wizardItems {
				width: 100% !important;
			}
		}

		.line-1 {
			height: 50px;
			width: 3px;
			left: 50%;
		}

		.wizardDiv {
			margin-left: 20px !important;
		}
	}

	.wizard-items {
		width: 100% !important;
	}

	.wizard-container {
		flex-direction: column !important;
	}

	.wizardTextWidth {
		width: 60%;
		flex: 1 0 80% !important;
		align-items: center !important;
	}

	.wizardCircleWidth {
		width: 15%;
		flex: 1 0 5% !important;
	}

	.wizardDiv {
		margin-left: 20px !important;
	}
}

@media (min-width: 1170px) and (max-width: 1339px) {

	// .wizard-items{
	// 	height: 145px;
	// }
	.wizardDiv {
		margin-left: 0px !important;
	}

}

@media (min-width: 1440px) and (max-width: 1720px) {
	// .wizard-items{
	// 	height: 100px;
	// }

}

@media (min-width: 1109px) and (max-width: 1300px) {
	.wizardTextWidth {
		align-items: flex-start !important;
	}
}

@media (min-width:900px) and (max-width: 1750px) {
	// .wizard-items{
	// 	height: 120px;
	// }
}

@media (max-width: 900px) {
	.wizard-container {
		flex-direction: column !important;
	}

	.line {
		width: 100% !important;
	}

	.line11 {
		width: 100% !important;
	}
}

@media (max-width:1600px) {
	.wizardTextWidth {
		width: 60%;
	}

	.wizardCircleWidth {
		width: 15%;
	}
}

// @media (min-width:992px) and (max-width:1400px) {
// 	.wizardTextWidth{
// 		width: 75%;
// 	}
// 	.wizardCircleWidth{
// 		width: 25%;
// 	}
// }
// @media (max-width:979px) {
// 	.wizardTextWidth{
// 		width: 50%;
// 	}
// 	.wizardCircleWidth{
// 		width: 50%;
// 	}
// }
// @media (max-width:858px) {
// 	.wizardTextWidth{
// 		width: 100%;
// 		justify-content: center;
// 		text-align: center;
// 	}
// 	.wizardCircleWidth{
// 		width: 100%;
// 		justify-content: center;
// 	}
// }
@media (max-width: 900px) {
	.wizard-items {
		width: 100%;
		margin-right: 0px;
	}

	.line-1 {
		height: 50px;
		width: 3px;
		left: 50%;
		transform: rotate(0deg);
	}

	.wizardTextWidth {
		width: 60%;
		flex: 1 0 80% !important;
		align-items: center !important;
	}

	.wizardCircleWidth {
		width: 15%;
		flex: 1 0 5% !important;
	}

	.wizardDiv {
		margin-left: 20px !important;
	}
}

@media (max-width: 700px) {
	.wizardDiv {
		margin-left: 0px !important;
	}
}

@media (max-width: 500px) {
	.wizardDiv {
		margin-left: 0px !important;
	}

	.wizard-items .row {
		margin-top: 25px;
	}

	.wizardCircleWidth {
		justify-content: center;
	}

	.wizardTextWidth {
		flex: 1 0 100% !important;
		margin-top: 30px;
		text-align: center;
	}
}

.backdrop {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	background: black;
}

#overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	bottom: 0;
	top: 0;
	z-index: 999;
}

.disabledProp {
	.input-group {
		cursor: not-allowed;
	}

	select {
		cursor: not-allowed;
		pointer-events: none;
	}

	.custom-radio {
		cursor: not-allowed;
		pointer-events: none;
	}
}

.disableall {
	.input-group {
		cursor: not-allowed;
	}

	select {
		cursor: not-allowed;
		pointer-events: none;
	}

	.custom-radio {
		cursor: not-allowed;
		pointer-events: none;
	}

	input {
		cursor: not-allowed;
		pointer-events: none;
	}
}

.companySelect.ng-select.ng-select-focused:not(ng-select-opened)>.ng-select-container,
.company-select.ng-select.ng-select-focused:not(ng-select-opened)>.ng-select-container {
	border: none;
	box-shadow: none;
}

.ng-select-container .ng-value-container .ng-input {
	top: 10px !important;
}

.emailMessage {
	border-radius: 10px !important;
	height: 100px;
	padding: 15px 7px;
}

.receiveMail div:not(.emailMessage) {
	position: relative;
	bottom: 0px;
	margin-top: 25px;
	padding-right: 15px;
}

.ediEmailMessage {
	border-radius: 10px !important;
	padding: 7px;
}

.ediReceiveMail div:not(.emailMessage) {
	margin-top: 25px;
}

.panelSector .ng-select.ng-select-multiple .ng-select-container {
	.ng-value-container .ng-placeholder {
		position: relative;
		top: 0px;
	}
}

.panelSector .ng-select.ng-select-multiple.requiredSection .ng-select-container {
	border: 1px solid #dc3545 !important;

	.ng-value-container .ng-placeholder {
		position: relative;
		top: 0px;
	}
}

#header.colorChangeTest {
	background-color: $header-color !important;
}

.ng-dropdown-panel.ng-select-bottom {
	overflow: auto;
}

@media(max-width: 1140px) {
	.preview-div .preview-widget {
		flex-direction: column-reverse !important;
		margin-bottom: 0.5rem;
	}

	.left-side {
		flex: 0 0 100%;
	}

	.right-side {
		padding: 0;
		flex: 0 0 0%;

		.err-status {
			flex-direction: column !important;
		}
	}

	.jarviswidget header h2 {
		width: auto !important;
	}
}

.cookie-text {
	font-size: 12px;

}

.show-consent {
	display: flex;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
}

.cookie-consent {
	width: 100%;
	flex-wrap: wrap;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	color: #d6d6d6;
	background-color: #3c404d;
	padding: 0.6em 1.8em;
	z-index: 9999;
	padding: 10px 0 0 10px;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.consent-btn {
	color: $fontcommon-color;
	flex: 1 0 130px;
	background-color: $commonbase-color;
	border-color: transparent;
	padding: 0.2em 0.5em;
	font-size: 1.2em;
	font-family: Helvetica, Calibri, Arial, sans-serif;
	font-weight: bolder;
	border-width: 2px;
	border-style: solid;
	text-align: center;
	white-space: nowrap;
	border-radius: 5px;
	min-height: 20px;
	margin: 0 30px 10px 10px;
	max-width: 100px;
}

a.cookie_anchor {
	color: $commonbase-color;
	text-decoration: none;
	font-weight: 500;

	&:hover {
		color: $commonbase-color;
	}
}

.invoice-table {
	th {
		font-size: 14px;
	}
}

.headers-fonts {
	th {
		font-size: 14px;
	}
}

.deleteBtnDiv {
	position: relative;

	.btnTooltip {
		position: absolute;
		left: 90px;
		height: auto;
	}
}

.disabledClass {
	pointer-events: none !important;
}

.disabledCursor {
	cursor: not-allowed !important;
}

.displayNone {
	display: none !important;
}

.ebill-draft {
	width: 18px;
	height: 18px;
}

.fa-check {
	background-color: $common_base_color;
	padding: 4px 3px;
}

.fa-times {
	background-color: rgb(251, 60, 74);
	padding: 4px 5px;
}

.biller-status {
	font-size: 14px;

	// margin-top: 35px;
	i {
		width: 20px;
		height: 20px;
		border-radius: 50%;

		color: black;
		font-size: 14px;
		margin-right: 5px;
	}
}
.inner-divs{
	margin-top: 15px;
	display: flex;
	// align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	flex-direction: column;
}
.snd-rcv {
	height: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	white-space: nowrap;

	&:first-child {
		margin-left: 0px !important;
	}

	&:last-child {
		margin-right: 0px !important;
	}
}

.snd-rcv-div {
	border-radius: 10px;
	width: 250px;
	height: 220px;
	margin: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.snd-rcv-img {
		width: 80px;
		height: 100px;

		img {
			width: 100px;
			height: 100px;
		}
	}

	.snd-rcv-title {
		margin-top: 10px;
		font-size: 18px;
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0px;
	}
}

.trapezium {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 0;
	width: 50%;
	line-height: 30px;
	border-bottom: 34px solid #3B424C;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	text-align: center;
	margin: auto 0;
	position: relative;
	/* Add position relative to the parent div */
}

.intrfce-drctn-div-text {
	position: absolute;
	/* Add absolute position to the child div */
	top: 50%;
	/* Set top position to 50% */
	left: 50%;
	/* Set left position to 50% */
	transform: translate(-50%, -50%);
	/* Translate the child div to center */
	line-height: 10px;
	margin-top: 15px;
	font-size: 12px;
	text-transform: uppercase;
}
@media (min-width: 350px) and (max-width: 991px){
	.intrfce-drctn-div-text{
		line-height: 14px !important;
		font-size: 13px !important;
	}
}
@media (min-width: 992px){
	.intrfce-drctn-div-text{
		font-size: 15px !important;
		line-height: 20px !important;
	}
}


.trapezium.active {
	border-bottom: 34px solid $common_base_color !important;
	color: #111 !important;
}

.steps-dir {
	display: flex;
}

.steps {
	// display: flex;
	gap: 15px;
}

.step {
	position: relative;
	width: 50px;
	max-width: 50px;
	text-align: center;
	height: 5px;
	border: 2px solid #FFF;
	background: #FFF;
	border-radius: 10px;
	margin-right: 10px;
}

.step.active {
	border: 2px solid $common_base_color !important;
	background: $common_base_color !important;
	color: $common_base_color;
}

.font-13 {
	font-size: 13px !important;
}

table.bulkTable thead tr th:first-child {
	border-radius: 5px 0px 0px 0px;
}

table.bulkTable thead tr th:last-child {
	border-radius: 0px 5px 0px 0px !important;
}

.commonSelectRadius .form-control {
	border-radius: 10px !important;
}

.scroll-tb {
	border: 0px solid #eee !important;
	border-top: transparent !important;
	height: 425px !important;
	overflow-y: scroll !important;
}

.scanPattern {
	margin-left: -10px;
}

.bulkInviteModal {
	.modal-footer {
		&> :not(:last-child) {
			margin-right: 0.25rem !important;
		}
	}
}

.nav-tabs {
	li {
		float: left;
		margin-bottom: -1px;		
		text-align: center;
		border-bottom: 1px solid $tabsborder-color;
		
	}

}
.sales-page{
	.nav-tabs {
		li{
			width: 34%;
		}
	}
}
.transaction{
	li{
		&:first-child {
			a {
				border-top-left-radius: 8px !important;
			}
		}
	}
}

li.active .last-li-child {
	border-top-right-radius: 8px !important;
}

.tab-section {
	padding-bottom: 10px !important;
}

/* Width and height of the scrollbar track */
::-webkit-scrollbar {
	width: 2px
}

/* Background color of the scrollbar track */
::-webkit-scrollbar-track {
	background-color: #2e2e2e11;
}

@media (max-width: 657px) {
	.actions {
		display: contents !important;
	}

	.steps-container {
		display: flex !important;
	}
}
.wid_content .inboxtra{
	padding-top: 2px;
}
.archiveImport {
	border-radius: 15px;
	border: 2px solid $common_base_color;
	font-size: 13px;
	padding: 3px 15px;
	line-height: 19.5px;
	font-weight: 400;
}
@media (max-height: 750px) {
	ul.navList.edi{
		// overflow: auto;
		height: 230px !important;
	}
}
@media (min-height: 751px) {
	ul.navList.edi {
		// overflow: auto;
		height: 450px !important;
    }
}
.repeate-btn-div{
	height: 45px;
	align-content: center;
    flex-direction: row;
	justify-content: flex-start;
    flex-wrap: wrap;
}

/* price - calculation */

.price_calcultation_form {
	.multiselect-dropdown {
		width: 98.5% !important;
		height: 40px !important;
		padding: 4px 0px 0px;
		font: 14px/16px "Open Sans", Helvetica, Arial, sans-serif;

		.dropdown-btn {
			display: flex !important;
			border-radius: 10px !important;
			padding: 8px 12px !important;
			font-size: 13px !important;
			.dropdown-multiselect--active{
				padding-left: 10px;
			}
			span {
				// padding-left: 5px;
			}

			.selected-item-container {
				width: 90% !important;
				.selected-item {
					border: 1px solid $common_base_color !important;
					background: $common_base_color !important;
					color: $common-color !important;
					max-width: 100% !important;
					padding: 0px 5px !important;
					border-radius: 20px !important;
					box-shadow: 1px 1px $common_base_color !important;
					width: auto !important;
					justify-content: center;
					display: flex;
					min-width: 100px;
					span{
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					a {
						color: $common-color !important;
						width: 8% !important;
						text-align: right;
					}
				}
			}

			.dropdown-multiselect__caret {
				display: none !important;
			}
		}

		.filter-textbox {
			input {
				background: $widget-bgcolor !important;
			}
		}

		.multiselect-item-checkbox {
			input {
				+ {
					div {
						font-size: 12px !important;
						&:before {
							color: $primary-color !important;
							border: 2px solid $primary-color !important;
							border-radius: 5px;
						}

						&:focus {
							color: $primary-color !important;
							border: 2px solid $primary-color !important;
						}
					}
				}
			}

			input[type=checkbox] {
				&:checked {
					+ {
						div {
							&:before {
								background: transparent !important;
							}
						}
					}
				}
			}
		}

		.dropdown-multiselect__caret {
			top: 5px !important;
		}
	}
}

/* price - calculation */
          
.userEditTextBox {
	background: $primary-color 0% 0% no-repeat padding-box !important;
}
.ediPriceCalc ng-multiselect-dropdown .multiselect-dropdown .dropdown-list{
	// position: fixed;
	width: auto;
}
.ediPriceCalc ng-multiselect-dropdown .multiselect-dropdown .dropdown-list[hidden]{
	position: absolute;
}
.invoice-div{
	.paymentSectionDetails {
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
		// background-color: $secondaryFocus !important;
		border-radius: 10px;
		margin-left: -1px;
	}

	.paymentOptions {
		display: none;
		// background-color: $secondaryFocus !important;
		border-radius: 10px;
	}
}
.PaySctn input[type=radio]:checked:after {
	// color: $common_base_color;
	width: 15px;
	height: 15px;
	border-radius: 15px;
	top: -2px;
	left: -1px;
	position: relative;
	background-color: $commonbase-color;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 2px solid map.get($whitetheme-colors, "textcolor");
	cursor: pointer;

}
.requestBody{
	.NextconfirmLabel {
		font-size: 13px;
		letter-spacing: 0px;
		color: $primary-color;
		margin-right: 15px;
		padding: 5px 15px;
		// border: 1px solid #666666;
		border-radius: 20px;
	}
}
.commonBasebg{
	background-color: $common_base_color !important;
}
.checkmark {
	position: absolute;
	left: 0;
	height: 25px;
	width: 25px;
	border-radius: 7px;
	background-color: transparent;
}


/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
	background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
	display: block;
}

.companyTooltip {
	text-align: center;
	z-index: 1000;
	position: fixed;
	// width: auto;
	// height:auto;
	border-radius: 5px;
	font: normal normal normal 13px/18px Open Sans;

	.insideDivUl-nohover {
		list-style: none;
		padding: 5px;
		display: flex;
		flex-direction: column;
		width: fit-content;
		word-break: break-all;
		height: auto;
	}

}
// .companyTooltip {
// 	text-align: center;
// 	z-index: 1000;
// 	position: fixed;
// 	width: 150px;
// 	height: 86px;
// 	border-radius: 5px;
// 	font: normal normal normal 13px/18px Open Sans;

// 	.insideDivUl-nohover {
// 		list-style: none;
// 		padding: 5px;
// 		display: flex;
// 		flex-direction: column;
// 		width: fit-content;
// 		word-break: break-all;
// 		height: auto;
// 	}

// }

/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-1 {
	.CodeMirror {
		background: lightgrey;
	}

	.amount-div {
		background: #7c7b7b;
		color: #fff;
	}

	.clickread {
		color: $primary-color;
		font-weight: 500;
		font-size: 13px;

		&:hover {
			color: $common-color !important;

		}
	}

	.clickLbls {
		&:hover {
			color: $common-color !important;

		}
	}

	.jarviswidget-ctrls {
		.button-icon {
			color: $base-color;
		}
	}

	.footerTextColor {
		color: $footer-color !important;
	}

	.setCircleactive {
		color: #00eba9;
		font-size: 10px !important;
	}

	.setCirclerej {
		color: #eb0042;
		font-size: 10px !important;
	}

	.setCircleTest {
		color: #F1DA91;
		font-size: 10px !important;
	}

	.wid_content {
		background-color: $content-bg-color !important;
		border: none !important;
	}

	.setArrowBlue {
		color: #6558F5 !important;
	}

	.setFont {
		color: $primary-color;
	}

	.setblackFont {
		color: $secondaryFocus;
	}

	.setwhiteFont {
		color: $primary-color;
	}

	.headerItems {
		color: $primary-color !important;
		font-size: 14px !important;
	}

	.commonHead {
		background-color: $content-bg-color !important;
		color: #fff;
		font-size: 14px;
		border: unset !important;
	}

	.commonWidgetText {
		color: #fff;
		font-size: 13px;
	}

	.userWidgetText {
		color: #fff;
		font-size: 16px;
		display: inline;
		// padding: 4px 20px;
	}

	.commonProgress {
		background-color: $common_base_color !important;
		// background-color: $tabsborder-color !important;
		border-color: $tabsborder-color !important;
		color: $tabsborder-color !important;
	}

	.blueHead {
		color: $primary-color;
	}

	.orangeHead {
		color: $primary-color;
	}

	.redHead {
		color: $primary-color;
	}

	.lstTrncnTbl {
		thead {
			tr {
				th {
					background-color: $table-bgcolor !important;
					color: $primary-color;
					border-color: map.get($darktheme-colors, "bordercolor");
				}
			}
		}

		tbody {
			// tr.odd {
			// 	// color: $primary-color;
			// 	/*background-color: $table-backgroundcolor;*/
			// 	border: 1px solid $tabsborder-color !important;
			// }

			tr {
				color: $primary-color;
				/*background-color: map.get($darktheme-colors, "bgcolor");*/
				border: 1px solid $tabsborder-color !important;
			}
		}

		.table-bordered {
			border-color: $header-bgcolor !important;
		}

		.dt-toolbar-footer {
			background: none !important;
		}

		.dt-toolbar {
			background: none !important;
			border-bottom: none;
		}
	}

	.table {
		tbody {
			tr {
				td {
					border : {
						top: 1px solid $header-bgcolor;
						color: $header-bgcolor;
					}
				}
			}
		}
	}

	.dt-toolbar-footer {
		background: none !important;
		font-size: none !important;
		overflow: hidden;
		padding: none !important;
		border-top: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		-ms-box-shadow: none !important;
		box-shadow: none !important;
		width: 100%;
	}

	.lstposTbale {
		thead {
			tr {
				th {
					background-color: $table-bgcolor !important;
					color: $primary-color;
					border-color: map.get($darktheme-colors, "bordercolor");
				}
			}
		}

		tbody {
			tr {
				color: $primary-color;
				border: 1px solid map.get($darktheme-colors, "bordercolor") !important;
			}
		}

		.dt-toolbar-footer {
			background: none !important;
		}

		.dataTables_processing {
			background: url(../img/spin-loader_small_white.svg) no-repeat !important;
		}

		.dt-toolbar {
			background: none !important;
			border: none !important;
			display: none !important;
		}
	}

	#header {
		.menu-item {
			// .webComponetsBorder {
			// 	border-right: 1px solid $header-border;
			// }

			// &:not(:last-child) {
			// 	border-right: 1px solid $header-border !important;
			// }

			&:hover {
				background-color: $header-bgcolor;
			}

			// .webComponetsHoverColor {
			// 	&:hover {
			// 		background-color: $header-bgcolor;
			// 	}
			// }
		}

		.theme-text-color {
			color: $primary-color !important;
		}

		.menu-item.no-hover {
			&:hover {
				background-color: $table-bgcolor;
			}
		}

		.menu-left-item {
			// border-left: 1px solid $header-border;
		}

		.collapseMenu {
			&:hover {
				background-color: $header-bgcolor;
			}

			.collaspe-menu-svg {
				background-image: url("../img/iconSvg/Path 224.svg");
			}

			.collaspe-menu-sub-svg {
				background-image: url("../img/iconSvg/Group224.svg");
				filter: brightness(0) invert(1);
				cursor: pointer;
			}
		}

		.graphsvgIcon {
			background-image: url("../img/iconSvg/graph.png");
			filter: brightness(100) invert(1);
		}

		.notification-svg {
			background-image: url("../img/iconSvg/39-Notification.svg");
		}

		#activity.activity-svg {
			background-image: url("../img/iconSvg/Page-1.svg");
		}

		.header-btn {
			background-color: #202124;
		}

		.header-btn-list {
			a {
				background-color: $table-bgcolor !important;
			}
		}
	}

	.dropdown-icon-menu>ul>li .btn,
	.header-btn {
		background: $widget-color;
	}

	#fullscreen {
		.svg-icon {
			background-image: url("../img/iconSvg/Path222.svg");
		}
	}

	.btn.btn-ribbon {
		background-color: #697486;
		margin-left: 4px;
	}

	.ajax-dropdown {
		border: 1px solid #313840 !important;
	}

	.notification-body {
		li {
			border: 1px solid #252b31 !important;
		}
	}

	.dropdown-menu {
		background-color: $secondaryFocus;
		border: 1px solid map.get($darktheme-colors, "bordercolor");

		li {
			a {
				color: $primary-color;
			}
		}
	}

	.header-dropdown-list {
		.dropdown-menu {
			li {
				a {
					&:hover {
						background-color: $table-bgcolor !important;
					}
				}
			}

			.active {
				a {
					background-color: $table-bgcolor !important;
				}
			}
		}
	}

	.documentLbl {
		background: $primary-color !important;
		text-align: center;
		font: normal normal normal 13px/18px Open Sans;
		color: $secondaryFocus !important;

		padding : {
			left: 5px !important;
			right: 5px !important;
		}
	}

	table.positionTable {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		border: 1px solid $header-bgcolor !important;
		border-radius: 5px;
	}

	.gridBGclr {
		background: $secondaryFocus !important;
	}

	.borderClr {
		border: unset !important;
	}

	table.datatable {
		margin-top: 0px !important;
	}

	.tableHeadBGclr {
		background: $table-bgcolor !important;
	}

	#inputState, .text-field {
		color: $primary-color !important;
	}

	.hoverTable {
		tbody {
			tr {
				&:hover {
					color: $base-color !important;
					background-color: $tablehover-color !important;
				}
			}
		}
	}

	.tranhoverTable {
		tbody {
			tr {
				&:hover {
					// color: $primary-color !important;
					background-color: #7c7b7b !important;
					cursor: pointer;
				}
			}
		}
	}

	#ribbon {
		.breadcrumb {
			li {
				color: $primary-color !important;

				&:last-child {
					color: $primary-color !important;
				}

				&:before {
					color: $primary-color !important;
				}
			}
		}
	}

	.big-spin-loader {
		background: url(../img/spin-loader_big_white.svg) no-repeat !important;
	}

	.big-spin-page-loader {
		background: url(../img/spin-loader_big_white.svg) no-repeat !important;
	}

	.blacktooltip {
		background: $widget-color 0% 0% no-repeat padding-box;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
		color: $base-color;

		.insideDivUl {
			li {
				&:hover {
					background: $table-bgcolor;
				}
			}
		}
	}
	.tooltip-text{
		background: $widget-color 0% 0% no-repeat padding-box;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
		color: $base-color;
	}

	.idTooltip,
	.btnTooltip,
	.errorTooltip {
		background: $widget-color 0% 0% no-repeat padding-box;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
		color: $base-color;
	}

	.progress {
		font-size: 17px !important;
		vertical-align: 0 !important;
		border-radius: 0px !important;
	}

	nav {
		ul {
			li {
				a {
					i {
						font-size: 17px !important;
						vertical-align: 0 !important;
					}
				}
			}
		}

		ul {
			li.active {
				background-color: $header-border !important;
			}

			li {
				a {
					&:hover {
						background-color: $header-border !important;
					}
				}
			}
		}
	}

	.header-search-form.formly.horizontal {}

	.form-control {
		color: $primary-color;
		background-color: $secondaryFocus;
		border: 1px solid $header-bgcolor;
	}

	.header-search-form.formly {
		.input-group-append {
			.input-group-text {
				background-color: $secondaryFocus;
				color: $primary-color;
				border: 1px solid $secondaryFocus;
			}
		}
	}

	.stepsinout-form,
	.businessconnect-form{
		.input-group-append {
			.input-group-text {
				background-color: $secondaryFocus;
				color: $primary-color;
				border: 1px solid $secondaryFocus;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;

				&::before {
					border-left: 1px solid $primary-color;
				}
			}
		}
	}
	.fileuploadform-form {
		.input-group-append {
			.input-group-text {
				background-color:#202124;
				color: $primary-color;
				border: 1px solid #333333;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;

				
			}
		}
	}
	#content {
		background: $content-bg-color !important;
		opacity: 1;

		.dashbaordlabel {
			background-color: unset !important;
			border: 1px solid $header-bgcolor !important;
			border-radius: 5px !important;
		}

		.header-formbtn,
		.advace-header-formbtn {
			background-color: unset !important;
			border: 2px solid $common_base_color !important;
			color: $primary-color !important;
			// padding: 3px 15px;

			&:hover {
				background: $common_base_color !important;
				color: $hovertext-color !important;
			}
		}
	}

	#advanceSearch {
		.dashbaordlabel {
			background-color: unset !important;
			border: 1px solid $header-bgcolor !important;
			border-radius: 5px !important;
		}
	}

	.commonBackground {
		option {
			background-color: $secondaryFocus;
			color: $primary-color;
			height: 40px;
			border-color: $secondaryFocus;
			font: normal normal normal 13px/18px Open Sans;
		}
	}

	.invoiceOverview {
		.nav-tabs {
			li.active {
				// border-top: 3px solid $primary-color !important;
				border-top: 1px solid #707070 !important;
				// border-radius: 10%;
			}
		}
	}

	#left-panel {
		nav {
			ul.edi {
				li {
					border-bottom: 1px solid $common_base_color;

					&:hover {
						background: $common_base_color !important;
						color: $hovertext-color !important;
						// border-top: 1px solid #666666;
						// border-bottom:1px solid #3B3B3B33;
						border: 1px solid #3B3B3B33;
						border-bottom: 1px solid #3B3B3B33 !important;
					}
				}

				// li.active {
				// 	background-color: $common_base_color !important;

				// 	// margin-right: -1px !important;
				// 	a {
				// 		color: #202427 !important;
				// 	}
				// }
			}

			ul.ebill {
				li {
					border-bottom: 1px solid $common_base_color;

					&:hover {
						background: $common_base_color !important;

						color: $common-color !important;
						// border-top: 1px solid #666666;
						// border-bottom:1px solid #3B3B3B33;
						border: 1px solid #3B3B3B33;
						border-bottom: 1px solid #3B3B3B33 !important;
					}
				}

				li.active {
					background-color: $common_base_color !important;

					// margin-right: -1px !important;
					a {
						color: #202427 !important;
					}
				}
			}
		}
	}

	select {
		&:not([multiple]) {
			option {
				background: #202124;
			}
		}
	}

	// option:hover { background-color: red; }
	// option[selected] {
	// 	color: green;
	//           }
	.login-info {
		span.borderRightClr {
			border: 1px solid $header-border !important;
		}

		background-color: map.get($darktheme-colors, "bgcolor") !important;
	}

	.setFontlabel {
		color: $primary-color;
		font: normal normal bold 16px/22px Open Sans;
	}

	.setFontsub {
		color: $fontColor;
		font: normal normal bold 13px/18px Open Sans;
	}

	.setFonts {
		color: $fontColor;
	}

	.line1 {
		border: 1px solid map.get($darktheme-colors, "bordercolor") !important;
	}

	.goButtons {
		top: 189px;
		left: 1808px;
		width: 86px;
		height: 40px;
		background-color: $primary-color !important;
		text-align: center;
		letter-spacing: 0px;
		color: $tablehover-color !important;
		font: normal normal normal 13px/18px Open Sans;
	}

	.processTbl {
		thead {
			tr {
				th {
					// background-color: map.get($darktheme-colors, "bgcolor") !important;
					color: $primary-color;
					border-top: 1px solid $tabsborder-color;
				}
			}
		}

		// tbody {
		// 	tr.odd {
		// 		color: $primary-color;
		// 		background-color: map.get($darktheme-colors, "bgcolor") !important;
		// 		border: 1px solid $tabsborder-color !important;
		// 	}

		// 	tr.even {
		// 		color: $primary-color;
		// 		background-color: $table-backgroundcolor !important;
		// 		border: 1px solid $tabsborder-color !important;
		// 	}
		// }

		.table-bordered {
			border-color: $tabsborder-color !important;
		}
	}

	table.borderline {
		border: 1px solid $tabsborder-color !important;
	}

	.inboxWidget {
		// background: map.get($darktheme-colors, "bgcolor") 0% 0% no-repeat padding-box !important;
		border: none;
	}

	.outboxWidget {
		background: map.get($darktheme-colors, "bgcolor") 0% 0% no-repeat padding-box !important;
		border: none;
	}

	.headClr {
		color: $primary-color !important;
	}

	.ui-datepicker {
		background: #202124 !important;
		border-color: #666666 !important;
		z-index: 2000 !important;

		.ui-datepicker-header {
			background-color: #202124;
		}

		select.ui-datepicker-month {
			border-color: map.get($darktheme-colors, "datepicker-bgcolor");
			background: #202124 !important;
		}

		select.ui-datepicker-year {
			border-color: map.get($darktheme-colors, "datepicker-bgcolor");
			background: #202124 !important;
		}

		th {
			color: $primary-color !important;
		}

		td {
			.ui-state-active {
				background: $tabsborder-color;
			}
		}
	}

	.ui-datepicker-header {
		a {
			color: $primary-color !important;
		}
	}

	.ui-datepicker-month {
		color: $primary-color !important;
	}

	.ui-datepicker-year {
		color: $primary-color !important;
	}

	.ui-datepicker-prev {
		&:before {
			background: #202124 !important;
		}
	}

	.ui-datepicker-next {
		&:before {
			background: #202124 !important;
		}
	}

	.form-control {
		color: $primary-color !important;
		background-color: $content-bg-color;
	}

	.labelColor {
		color: $primary-color !important;
	}

	.stepsinout-form.formly {
		.form-control {
			color: $primary-color !important;
			background-color: $secondaryFocus !important;
			border-color: $secondaryFocus;
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			// border-bottom-right-radius: 10px !important;
			// border-top-right-radius: 10px !important;
			// border-radius: 10px !important;
			-webkit-transition: border 0s ease-in-out 0s;
			-moz-transition: border 0s ease-in-out 0s;
			-o-transition: border 0s ease-in-out 0s;
			-ms-transition: border 0s ease-in-out 0s;
			transition: border 0s ease-in-out 0s;
		}
	}

	.billTabSection {
		.stepsinout-form.formly {
			.form-control {
				color: $primary-color !important;
				background-color: $secondaryFocus !important;
				border-color: $secondaryFocus;
				border-top-left-radius: 10px !important;
				border-bottom-left-radius: 10px !important;
				border-bottom-right-radius: 10px;
				border-top-right-radius: 10px;
				// border-radius: 10px !important;
				-webkit-transition: border 0s ease-in-out 0s;
				-moz-transition: border 0s ease-in-out 0s;
				-o-transition: border 0s ease-in-out 0s;
				-ms-transition: border 0s ease-in-out 0s;
				transition: border 0s ease-in-out 0s;
			}
		}
	}

	#accordionExpand {
		.form-control {
			border-radius: 10px !important;
		}
	}

	.businessconnect-form.formly {
		.form-control {
			color: $primary-color !important;
			background-color: $secondaryFocus !important;
			// border-radius: 10px !important;
			border-color: $secondaryFocus;
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			-webkit-transition: border 0s ease-in-out 0s;
			-moz-transition: border 0s ease-in-out 0s;
			-o-transition: border 0s ease-in-out 0s;
			-ms-transition: border 0s ease-in-out 0s;
			transition: border 0s ease-in-out 0s;
		}
	}
	.fileuploadform-form.formly {
		.form-control {
			color: $primary-color !important;
			background-color: $secondaryFocus !important;
			// border-radius: 10px !important;
			border-color: $secondaryFocus;
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			-webkit-transition: border 0s ease-in-out 0s;
			-moz-transition: border 0s ease-in-out 0s;
			-o-transition: border 0s ease-in-out 0s;
			-ms-transition: border 0s ease-in-out 0s;
			transition: border 0s ease-in-out 0s;
		}
	}
	.table {
		tbody {
			tr {
				th {
					padding: 8px;
					line-height: 1.42857143;
					vertical-align: top;
					border-top: 1px solid $header-bgcolor;
					border-color: $header-bgcolor;
				}
			}
		}

		tfoot {
			tr {
				td {
					padding: 8px;
					line-height: 1.42857143;
					vertical-align: top;
					border-top: 1px solid $header-bgcolor;
					border-color: $header-bgcolor;
				}

				th {
					padding: 8px;
					line-height: 1.42857143;
					vertical-align: top;
					border-top: 1px solid map.get($darktheme-colors, "bordercolor");
					border-color: map.get($darktheme-colors, "bordercolor");
				}
			}
		}

		thead {
			tr {
				td {
					padding: 8px;
					line-height: 1.42857143;
					vertical-align: top;
					border-top: 1px solid $header-bgcolor;
					border-color: $header-bgcolor;
				}

				th {
					padding: 8px;
					line-height: 1.42857143;
					vertical-align: top;
					border-top: 1px solid $header-bgcolor;
					border-color: $header-bgcolor;
				}
			}
		}
	}

	.dt-toolbar {
		.dataTables_filter {
			display: none !important;
		}

		.dataTables_length {
			display: none !important;
		}
	}

	.minified {
		nav {
			ul {
				li {
					border-top: none;
				}
			}
		}
	}

	.dashboardHead {
		.jarviswidget-ctrls {
			.button-icon {
				border-left: unset !important;
			}
		}
	}

	nav ul li a {
		color: $primary-color !important;
		font-size: 14px !important;

		&:focus,
		&:hover {
			background: $common_base_color !important;
			color: $common-color !important;
			//         border-bottom: 2px solid #666666;
			//         border-top: 2px solid #666666;
			// border: 2px solid #666666;
		}
	}

	tbody {
		tr.child {
			td {
				background-color: map.get($darktheme-colors, "bgcolor");
				color: $base-color;
			}
		}
	}

	.ui-datepicker {
		td {
			.ui-state-default {
				color: $primary-color;
			}
		}
	}

	.dataTables_processing {
		background: url(../img/spin-loader_big_white.svg) no-repeat !important;
	}

	.errorOccured {
		border-color: #dc3545 !important;
	}

	.card-header {
		background-color: rgba(0, 0, 0, 0.5);
	}

	.panel {
		background: #1e1e1e;
	}

	.card {
		background-color: $content-bg-color;
		border: 1px solid rgba(0, 0, 0, .08);
	}

	.card-box {
		background-color: $widget-color !important;
	}

	.box-header {
		color: $primary-color;
	}

	.panel-tag {
		border-left: 3px solid aliceblue;
		background: $common-color;
		color: $primary-color;
	}

	.service_modal {
		.modal-content {
			background-color: $secondaryFocus !important;
			color: $primary-color;

			.modal-header {
				border-bottom: #1B1E24 !important;
			}

			.servicePopUp_header_label {
				font-size: 14px;
				font-weight: 500;
			}

			.service_section {
				height: auto;
			}

			.service_details {
				// border-left: solid 1px #1B1E24 !important;
				height: auto;
			}
		}
	}

	.serviceTheme {
		background-color: map.get($darktheme-colors, "bgcolor");
		// width: 1200px !important;
		border-radius: 0px;

		.ui-dialog-titlebar {
			background-color: map.get($darktheme-colors, "bgcolor");
			border-bottom: none !important;
			width: 100% !important;

			.ui-dialog-title {
				width: 100% !important;

				.widget-header {
					// display: flex;
					// justify-content: center;
					// padding: 0 21px;
					text-align: left;
					// font: normal normal bold 16px/22px Open Sans;
					// letter-spacing: 0px;
					// opacity: 1;
				}

				.shortDescription {
					text-align: center;
				}

			}

			.ui-dialog-titlebar-close {
				position: absolute;
				top: 25px;
				right: 5px;
			}

			h5 {
				color: $primary-color !important;
			}

			h1 {
				color: $primary-color !important;
				padding: 0 21px;
				text-align: left;
				font: normal normal bold 16px/22px Open Sans;
				letter-spacing: 0px;
				opacity: 1;
			}

			.ui-button {

				color: $common_base_color;
				opacity: 1;

			}
		}

		.ui-dialog-content {
			background-color: map.get($darktheme-colors, "bgcolor") !important;

			.service_modal {
				background-color: map.get($darktheme-colors, "bgcolor") !important;

				.modal-content {
					box-shadow: unset !important;
					-webkit-box-shadow: unset !important;
					border: unset !important;
					background-color: map.get($darktheme-colors, "bgcolor") !important;
				}
			}
		}

		.chip_Section {
			border: 1px solid $header-bgcolor;
			// background-color: #7c7b7b !important;
			color: $primary-color !important;
			margin-left: 13px !important;
		}

		.serviceSection:hover {
			// border: 1px solid #1B1E24 !important;
			background-color: #303030 !important;
		}

		.toggleMore {
			background-color: $tabsborder-color !important;
		}

		.serachService {
			border: 1px solid $header-bgcolor;
			// background-color: $fontColor !important;
		}
	}

	.select-label {
		color: $primary-color !important;
	}

	.menuSelected {
		background-color: $header-bgcolor;
	}

	.model-7 .checkbox label:after {
		background: $common-color !important;
	}

	.inboxHeads {
		background-color: map.get($darktheme-colors, "bgcolor") !important;
		font: normal normal bold 13px/18px Open Sans;
		// color: $secondaryFocus !important;
		border: none;
		// border-radius: 5px 5px 0px 0px;
	}

	.progress {
		background-color: $header-bgcolor;
		box-shadow: none;
	}

	// .add_ServiceBtn {
	// 	.btn-primary {
	// 		color: #fff !important;
	// 		background-color: #727f8b !important;
	// 		border-color: #2c699d !important;

	// 		&:hover {
	// 			color: #fff !important;
	// 			background-color: #007bff !important;
	// 			border-color: #007bff !important;
	// 		}
	// 	}

	// 	.btn {
	// 		text-align: center;
	// 		vertical-align: middle;
	// 		display: inline-block;
	// 		font-weight: 400;
	// 		user-select: none;
	// 		background-color: transparent;
	// 		border: 1 px solid transparent;
	// 		line-height: 1.5;
	// 		padding: 0.375 rem 0.75 rem;
	// 		transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	// 	}


	// }

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
		-webkit-text-fill-color: $primary-color !important;
		-webkit-box-shadow: 0 0 0 30px $secondaryFocus inset !important;
	}

	.theme-bg-colors-border {
		background-color: $content-bg-color !important;
		box-shadow: unset !important;
	}

	.headerContent {
		border-radius: 10px;
		border: 1px solid $header-bgcolor;
		background-color: $content-bg-color !important;
	}

	.pagination>.active>a {
		background-color: $common_base_color !important;
		border-color: $common_base_color !important;
		color: $hovertext-color !important;
		border-color: $header-bgcolor;
		z-index: 0;
	}

	.pagination>li>a {
		background-color: transparent !important;
		color: $primary-color;
		border-color: $header-bgcolor;
	}

	.pagination>li:first-child>a,
	.pagination>li:first-child>span {
		margin-left: 0;
		border-bottom-left-radius: 5px;
		border-top-left-radius: 5px;
		background-color: transparent !important;
		color: $primary-color;
		border-color: $header-bgcolor;
	}

	.pagination>li:last-child>a,
	.pagination>li:last-child>span {
		border-bottom-right-radius: 5px;
		border-top-right-radius: 5px;
		background-color: transparent !important;
		border-color: $header-bgcolor;

	}

	.setBackground {
		border-radius: 10px;
		border: 1px solid $header-bgcolor;
	}

	.widgetBorder {
		border-radius: 10px;
		border: 1px solid $header-bgcolor;
		overflow: hidden;
		background-color: #303030 !important;
	}

	.upgradebtn {
		color: $primary-color !important;
		// background: #FFFFFF !important;
	}

	.algntblTranscation {
		border-radius: 8px;
		border: 1px solid $header-bgcolor !important;
		// margin: 0 3px !important;
		overflow: hidden;
		background: $content-bg-color !important;
	}

	.headerContents {
		// padding: 0 0 0 7px;
		border-radius: 8px;
		border: 1px solid $header-bgcolor !important;
		// margin-left: 0 3px !important;
		overflow: hidden;
		background: $content-bg-color;
	}

	.modalBodyBgColor {
		background-color: map.get($darktheme-colors, "background-color") !important;
	}

	.theme-icon {
		color: $primary-color !important;
	}

	.border-line-none {
		// border: 1px solid $subborder-color;
		border: none;
		background-color: $content-bg-color !important;

	}

	// chain class selctors

	.setting-menu {
		#header {
			.settingsMenu {
				background: $header-bgcolor !important;
			}
		}

	}



	.note-table-popover,
	.note-image-popover,
	.note-link-popover {
		display: none;
	}

	.ticketPopupImportant {
		max-height: calc(100% - 100px);
		overflow-y: auto;
		border-radius: 10px;

		&::-webkit-scrollbar {
			width: 0px;
		}
	}

	.btn-primary {
		background: transparent !important;
		border: 2px solid $common_base_color;
		color: $primary-color !important;
		padding: 3px 15px;
		border-radius: 15px;
		font-size: 13px;

		&:hover {
			background: $common_base_color !important;
			color: $hovertext-color !important;
			border: 2px solid $common_base_color;
		}
	}

	.ebill_btn {
		background: transparent !important;
		border: 2px solid $common_base_color;
		color: $primary-color !important;
		padding: 3px 15px;
		border-radius: 15px;
		font-size: 13px;
		line-height: 20px;

		&:hover {
			background: $common_base_color !important;
			color: $common-color !important;
			border: 2px solid $common_base_color;
		}
	}

	//             .nav-pills .nav-item.active, .nav-pills .show>.nav-link {
	// 	background: #202124 !important;
	// 	border: 2px solid $common_base_color;
	// 	color: #ffff;
	// 	padding: 8px 20px;
	// 	border-radius: 15px;
	// 	&:hover {
	// 		background: $common_base_color !important;
	// 		color: #ffff !important;
	// 		border: 2px solid $common_base_color;
	// 	         }
	//           }
	.nav-pills>li.active>a {
		background: $content-bg-color !important;
		border: 2px solid $common_base_color;
		color: $primary-color;
		margin-top: 5px;

		&:hover {
			background: $common_base_color !important;
			color: $common-color !important;
			border: 2px solid $common_base_color;
		}
	}

	.btnModalSave {
		float: right;
		background-color: $content-bg-color;
		border: none;
		color: $primary-color !important;
		border-radius: 15px;
		border: 2px solid $common_base_color;
	}

	.btnModalClose {
		float: left;
		background-color: $content-bg-color;
		border: none;
		color: $primary-color !important;
		border-radius: 15px;
		border: 2px solid $common_base_color !important;
	}

	// BUY-ADDON start

	// <!-------Subscripition scss------------------>
	// .table-bordered>tbody>tr>td {
	// 	border: 1px solid $header-bgcolor;
	// }

	.emptyCol {
		border-top: 1px solid transparent !important;
		border-left: 1px solid transparent !important;
		background-color: $content-bg-color !important;
		border-right: 1px solid transparent !important;
		border-bottom: 1px solid transparent !important;
	}

	.currentPlan {
		background-color: #CCCCCC !important;
		color: $secondary-color !important;

		&:hover {
			background: #CCCCCC !important;

		}
	}

	.currentButton {
		background-color: #404040 !important;
		color: map.get($whitetheme-colors, "textcolor") !important;
		// color:map.get($whitetheme-colors, "textcolor") !important;
		border-color: $header-bgcolor !important;
		cursor: not-allowed;
		z-index: -1;
	}

	.model-7 .checkbox label:after {
		background: $common-color !important;
	}

	// .pagination>.active>a {
	// 	background-color: $commonbase-color !important;
	// 	border-color: $commonbase-color !important;
	// 	color: $primary-color;
	// 	border-color: $header-bgcolor;
	// }

	// .pagination>li>a {
	// 	background-color: #303030 !important;
	// 	color: $primary-color;
	// 	border-color: $header-bgcolor;
	// }

	// .pagination>li:first-child>a,
	// .pagination>li:first-child>span {
	// 	margin-left: 0;
	// 	border-bottom-left-radius: 2px;
	// 	border-top-left-radius: 2px;
	// 	background-color: #303030 !important;
	// 	color: $primary-color;
	// 	border-color: $header-bgcolor;
	// }

	// .pagination>li:last-child>a,
	// .pagination>li:last-child>span {
	// 	border-bottom-right-radius: 2px;
	// 	border-top-right-radius: 2px;
	// 	background-color: #303030 !important;
	// 	border-color: $header-bgcolor;

	// }

	.selectButton {
		border: 2px solid $commonbase-color;
		border-radius: 15px;
		padding: 5px 20px;
		background-color: $content-bg-color;
		border-color: $commonbase-color;
		font-size: 14px;
		outline: none;

		&:hover {
			background: $commonbase-color !important;
			color: $common-color !important;
			border: 1px solid #3B3B3B33;
		}
	}

	.rightBorder {
		border-right: 2px solid $commonbase-color !important;
		border-left: 2px solid $commonbase-color !important;
	}

	.topBorder {
		border-top: 2px solid $commonbase-color !important;
	}

	.bottomBorder {
		border-bottom: 2px solid $commonbase-color !important;
	}

	.cancel {
		text-align: left;
		width: auto;
		// min-width: 50px !important;
		border-radius: 15px;
		background: $content-bg-color 0% 0% no-repeat padding-box !important;
		border: 2px solid $commonbase-color !important;
		font: normal normal normal 13px/24px Open Sans;
		padding: 3px 15px;
	}

	.TotalLabel {
		top: 275px;
		left: 560px;
		height: 49px;
		text-align: left;
		font: normal normal bold 31px/23px Open Sans;
		letter-spacing: 0px;
		color: $primary-color;
		opacity: 1;
		margin-left: -16px;
	}

	.paymentSectionDetails {
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
		background-color: $secondaryFocus !important;
		border-radius: 10px;
		margin-left: -1px;
	}

	.paymentOptions {
		display: none;
		background-color: $secondaryFocus !important;
		border-radius: 10px;
	}

	.lightBackgroundColor {
		border-radius: 10px;
		background-color: $content-bg-color !important;
		border: 1px solid $header-bgcolor;
		margin-left: 13px;
	}

	.pricingTable {
		table {
			color: $primary-color !important;
		}
	}

	.theme-bg-color {
		background-color: $content-bg-color !important;
	}

	.theme-subtext-color {
		color: $card-color !important;
	}

	#content {
		background: $content-bg-color !important;
	}

	.jarviswidget>div {
		background-color: $content-bg-color !important;
		border: none
	}

	.bootstrapWizard li.active .title,
	.bootstrapWizard li.complete .title {
		color: $primary-color;
	}

	.bootstrapWizard li .step {
		background-color: $content-bg-color;
		border: 1px solid $header-bgcolor;
		color: #eee;
	}

	.textColor {
		color: $primary-color !important;
	}

	.headData {
		background-color: map.get($darktheme-colors, "background-color");
		color: $primary-color;
		margin-top: 10px;
		background-image: none;
	}

	.modal-body {
		background-color: $widget-bgcolor !important;
	}

	.modal-content {
		background-color: $widget-bgcolor !important;
		border: 1px solid $header-bgcolor;
		color: $primary-color;
		
	}

	.interConnContent {
		background-color: $widget-bgcolor !important;
	}

	.modal-footer {
		padding: 20px 0px 0px 0px;
		border-top: none;
	}

	.back-btn {
		background: $subscribe-bgcolor 0% 0% no-repeat padding-box;
		border-color: $subscribe-bgcolor;
		font-size: 13px;
	}

	.jarviswidget-color-darken>header {
		background: $content-bg-color !important;
		border-color: #303030 !important;
	}

	.buy-addon {
		.navigationList {
			.nav.nav-tabs {
				li {
					a {
						span {
							color: $primary-color !important;
						}

						&:hover {
							background-color: $commonbase-color !important;
							border: 1px solid $commonbase-color !important;

							span {
								color: $common-color !important;
							}
						}
					}
				}

				li.active {
					a {
						span {
							color: $common-color !important;
						}
					}
				}
			}
		}
	}

	.NextconfirmLabel {
		color: $primary-color;
		border: 1px solid $header-bgcolor;
	}

	.buy-addon {
		.checkout {
			background-color: $secondary-color;
		}
	}

	.table-headData {
		background-color: map.get($darktheme-colors, "background-color");
		color: $primary-color;
		margin-top: 10px;
	}

	.commoninputStyle {
		.form-control {
			height: 40px !important;
			border-radius: 6px !important;
			background-color: $content-bg-color !important;
			border: 1px solid $header-bgcolor;
			font-size: 14px;
			color: $primary-color !important;
		}

		textarea.form-control {
			height: unset !important;
		}
	}

	.child {
		.dtr-details {
			.dtr-title {
				color: $base-color !important;
			}

			.dtr-data {
				color: $base-color !important;
			}
		}
	}

	// BUY-ADDON end

	.btn-primary {
		&:not(:disabled) {
			&:not(.disabled) {
				&:active {
					color: $primary-color !important;
				}
			}
		}
	}

	// User dashboard
	.tileContainer {
		.commonTile {

			.userDash_tileHead,
			.clickLbl {
				color: $primary-color;
				font-weight: 500;
				font-size: 13px;
			}

			.userDash_tileHeadgreen,
			.clickLblgreen {
				color: $primary-color;
				font-weight: 500;
			}
		}
	}

	.lstTransatiosectionHead {
		color: $primary-color;
	}

	.fistTileNote,
	.secondTileNote,
	.ediSectionHead,
	.sampleediSectionHead {
		color: $primary-color;
		font-weight: 600;
	}

	.commonLblEdisection {
		color: $primary-color;
	}

	.commonTile,
	.leftRowSection,
	.rightediSetion,
	.tileFinal0,
	.tileFinal1,
	.rightFinalSection,
	.commonBorderColor,
	.dropSelect {
		border: 1px solid $header-bgcolor !important;
	}

	.dashimgDiv {
		background : {
			repeat: no-repeat !important;
			position: center !important;
		}

		height: 100px;
		width: 100px;
	}

	.previewImg {
		background: url("/assets/img/iconSvg/dashboardBlack.png");
	}

	.toolsimg {
		background: url("/assets/img/iconSvg/toolsBlack.png");
	}

	// .tile0 {

	// 	background-color: $common_base_color !important;
	//           }
	.tile-formbtn {
		background-color: unset !important;
		color: $primary-color !important;
		border: 2px solid $common_base_color !important;

		&:hover {
			background-color: $commonbase-color !important;
			// border: 1px solid $commonbase-color !important;
			color: $hovertext-color !important;
		}
	}

	.tile1-formbtn {

		background-color: #212529 !important;
		color: $primary-color !important;
		border: 2px solid $common_base_color !important;

		&:hover {
			background-color: $primary-color !important;
			border: 1px solid $commonbase-color !important;
			color: $common-color !important;
		}
	}

	.NextconfirmLabels {
		font: normal normal normal 13px/24px Open Sans;
		letter-spacing: 0px;
		color: $primary-color;
		margin-right: 15px;
		padding: 4px 10px;
		border: 1px solid #666666;
		border-radius: 20px;
		margin-top: -7px;
		margin-left: 10px;
	}

	.graphsvgIcons {
		filter: brightness(100);
	}

	.code {
		color: $primary-color !important;
		background-color: #0C1214 !important;
	}

	.bottom-formbtns {
		background-color: unset !important;
		border: 2px solid $common_base_color !important;
		color: $primary-color !important;

		&:hover {
			background: $common_base_color !important;
			color: $secondary-color !important;
		}

	}

	.dateHeadingLbl,
	.filterSpan {
		color: $primary-color;
	}

	.colorWhite {
		filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(202deg) brightness(105%) contrast(101%) !important;
	}

	.badge-disable-iom {
		color: $primary-color !important;
	}

	.sub-btn {
		border: 2px solid $common_base_color !important;
		color: $primary-color !important;
		font-weight: bold;
		&.freeButton {
			border-color: #333 !important;
			&:hover{
				background-color: transparent !important;
				border-color: #333 !important;
				color: #fff !important;
			}
		}
		&.futurePlanBtn{
			border-color: $common_base_color !important;
			background-color: $common_base_color !important;
			color: #000!important;
			&:hover{
				background-color: transparent !important;
				border-color: #333 !important;
				color: #fff !important;
			}
		}
		&.currentPlanBtn{
			border-color: $common_base_color !important;
			background-color: $common_base_color !important;
			color: #000!important;
			&:hover{
				background-color: transparent !important;
				border-color: #333 !important;
				color: #fff !important;
			}
		}
		&:hover {
			background: $commonbase-color !important;
			color: $secondaryFocus !important;
		}
	}

	.jarviswidget>header h2 {
		color: #fff !important;
		// OWL datepicker Inline starts here
	}

	.owl-dt-calendar-control {
		color: $primary-color !important;
	}

	.owl-dt-container {
		background: #202124 !important;
		border: #202124 !important;
		border-radius: 10px !important;
	}

	.owl-dt-calendar-table .owl-dt-calendar-header {
		color: #666666 !important;
	}

	.owl-dt-calendar-table .owl-dt-calendar-cell {
		color: #666666 !important;
	}

	.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
		background-color: #171819 !important;
		border: #666666 !important;
	}

	.filter-top {
		color: $primary-color !important;
	}

	.lightTextColor {
		color: #ddd;
	}

	.settings-list {
		.settings-list-items-contents {
			background-color: map.get($darktheme-colors, "background-color") !important;
			border: 1px solid map.get($darktheme-colors, "bordercolor") !important;
		}
	}

	.settings-list-row {
		color: $primary-color;

		.list-group-item {
			background-color: transparent !important;

			&:hover {
				background: $commonbase-color !important;
				color: $hovertext-color !important;
			}
		}
	}

	.spinnerClass {
		color: #fff;
	}

	.MessageBoxButtonSection button {
		color: $primary-color;
	}

	.modal-body {
		background-color: $widget-bgcolor !important;
	}

	.modal-content {
		background-color: $widget-bgcolor !important;
		border: 1px solid $header-bgcolor;
		color: $primary-color;
		border-radius: 10px;
		// width: 900px;
	}

	.modal-footer {
		padding: 20px 0px 0px 0px;
		border-top: none;
	}

	.border {
		border: 1px solid $header-bgcolor !important;
		border-radius: 10px;

		.editConfigSection {
			background-color: map.get($darktheme-colors, "popup-bg-color") !important;
		}
	}

	.joyride-step__holder {
		background-color: #171717 !important;
		box-shadow: 0 0 0px 1px #171717 !important;

		.joyride-step__container {
			background-color: #171717 !important;
			border: 1px solid $header-bgcolor !important;
			box-shadow: 0 0 0px 1px #171717 !important;
		}

		.joyride-arrow__top {
			border-bottom: 11px solid #666 !important;
		}

		.joyride-arrow__right {
			border-left: 11px solid #666 !important;
		}

		.joyride-arrow__left {
			border-right: 11px solid #666 !important;
		}

		.joyride-arrow__bottom {
			border-top: 11px solid #666 !important;
		}

		.joyride-button {
			color: #fff !important;

			&:hover {
				background-color: $common_base_color !important;
				color: #111 !important;
			}
		}

		.joyride-step__title,
		.joyride-step__body {
			color: $primary-color !important;
		}
	}

	.manageHeader,
	.comapnyHeaderLbl {
		color: $primary-color !important;
	}

	.iom_btn {
		color: $primary-color;
	}

	.error_tab {
		color: $primary-color !important;
	}

	.error_text {
		color: $primary-color !important;
	}

	textarea.in {
		color: $primary-color;
		background-color: $secondaryFocus;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
	}

	textarea.modal-in {
		color: $primary-color;
		background-color: $secondaryFocus;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
	}

	.tour-title-input {
		color: $primary-color;
		background-clip: padding-box;
		background-color: $secondaryFocus;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
	}

	.modal-title-input {
		color: $primary-color;
		background-clip: padding-box;
		background-color: $secondaryFocus;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
	}

	.successcmpimgDiv {
		background-color: $secondaryFocus;
	}

	.defaultDot {
		background-color: white;
	}

	.statusSection tr td:not(:last-child) {
		border-right: 2px solid #ffff;
	}

	.successDiv {
		background-color: map.get($darktheme-colors, "bgcolor");
	}

	.successDiv-status {
		background-color: map.get($darktheme-colors, "bgcolor");
	}

	.successdeleteDiv {
		background-color: map.get($darktheme-colors, "bgcolor");
	}

	.theme-content-color {
		color: map.get($darktheme-colors, "textcolor");
	}

	#wizard {
		background-color: map.get($darktheme-colors, "commonbgcolor") !important;
		// border: 1px solid #666666;
		color: map.get($darktheme-colors, "textcolor");

		.line {
			.line-1 {
				background: #666666;
			}
		}

		.line11 {
			.line-1 {
				background: transparent;
			}
		}

		.wizard-items {
			border: 1px solid $header-bgcolor;
			background: #202124 0% 0% no-repeat padding-box;

			&:hover {
				background: #171819 0% 0% no-repeat padding-box;
			}

			.wizardDiv {
				border: 1px solid #404040;
				background: #404040 0% 0% no-repeat padding-box;
			}
		}

		b.badges {
			border: 1px solid #202124;
			box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
		}

		.wizard-billeritem {
			border: 1px solid $header-bgcolor;
			background: #202124 0% 0% no-repeat padding-box;

			.wizardDiv {
				border: 1px solid #404040;
				background: #404040 0% 0% no-repeat padding-box;
			}
		}
	}

	/* #wizard1{
		background-color: map.get($darktheme-colors, "commonbgcolor") !important;
		// border: 1px solid #666666;
		color: map.get($darktheme-colors, "textcolor");
		.line-1{
			background: #666666;
		}
		.wizard-items{
			border: 1px solid #3B424C;
			background: #202124 0% 0% no-repeat padding-box;
			&:hover{
				background: #171819 0% 0% no-repeat padding-box;
			}
			.wizardDiv{
				border: 1px solid #404040;
				background: #404040 0% 0% no-repeat padding-box;
			}
		}
		b.badges{
			border: 1px solid #202124;
			box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
		}
		.wizard-billeritem{
			border: 1px solid #3B424C;
			background: #202124 0% 0% no-repeat padding-box;
			.wizardDiv{
				border: 1px solid #404040;
				background: #404040 0% 0% no-repeat padding-box;
			}
		}
	} */

	.accordianClass {
		color: map.get($darktheme-colors, "textcolor");
	}

	b.badges {
		background-color: rgba(0, 0, 0, 0.5);
	}

	.warning-text {
		color: $primary-color !important;
	}

	nav ul ul {
		li {
			a {
				color: $primary-color;
				font-size: 14px !important;
				text-shadow: none !important;

				&:focus,
				&:hover {
					color: #202427 !important;
					background: $common-base-color !important;
				}
			}
		}
	}

	#left-panel nav ul.edi li:hover {
		background: $common_base_color !important;
		color: $hovertext-color !important;
		border: 1px solid #3B3B3B33;
		border-bottom: 1px solid #3B3B3B33 !important;
	}

	#left-panel nav ul.ebill li:hover {
		background: $common_base_color !important;
		color: $hovertext-color !important;
		border: 1px solid #3B3B3B33;
		border-bottom: 1px solid #3B3B3B33 !important;
	}

	nav ul li a {
		color: $primary-color !important;
		font-size: 16px !important;

		&:focus,
		&:hover {
			color: $hovertext-color !important;
			background: $common-base-color !important;
		}
	}

	nav ul {
		li.open {
			a {
				color: $primary-color !important;
				font-weight: 400;
			}

			&:focus,
			&:hover {
				color: $hovertext-color !important;
				background: $common-base-color !important;
			}
		}

		li.open a b {
			color: $primary-color !important;
		}

		li.open.active a b {
			color: #202427 !important;
		}

		li.open:hover>a {
			color: #202427 !important;
		}

		li.open:hover>a b {
			color: #202427 !important;
		}
	}

	nav ul ul {
		li {
			a {
				color: $primary-color;
				font-size: 16px !important;
				text-shadow: none !important;
				padding-left: 0px !important;
				padding-top: 7px !important;
				padding-bottom: 14px !important;

				&:focus,
				&:hover {
					color: $hovertext-color !important;
					background: $common-base-color !important;
				}
			}
		}
	}

	nav ul.ebill li a:hover,
	nav ul.ebill ul li a:hover,
	nav ul.ebill li.open a:hover {
		background: $common_base_color !important;
	}

	nav ul li.open:hover>a {
		color: #202427 !important;
	}

	// #left-panel nav ul.ebill li.active a,
	// #left-panel nav ul.edi li.active a {
	// 	color: #202427 !important;
	// }

	.dropzonbox {
		background-color: #171717 !important;
		border: 1px solid #3B424C !important;

	}

	.checkmark {
		border: 2px solid #666666;
	}

	// .payContent{
	// 	background-color: #000 !important;
	// }
	.interConnPopupForm {
		label {
			font-size: 14px !important;
		}

		.form-control {
			border: 1px solid #666666;
			background-color: #202124 !important;
			border-radius: 5px !important;
		}

		.invalid-feedback {
			font-size: 12px !important;
		}
	}

	.interConnContent {
		background-color: #171717 !important;
	}

	.ebillFormFormly,
	.disableForm,
	.ebillForm {
		label {
			color: #fff !important;
		}

		.form-control {
			background: transparent !important;
			border: 1px solid $header-bgcolor;
			color: #fff;
			height: 40px !important;
			border-radius: 6px !important;
			font-size: 13px;
		}
	}

	.companySelect .ng-select-container,
	.company-select .ng-select-container {
		// background-color:  #202124 !important;
		color: #fff;
		height: 42px !important;
		border: none;

		input {
			color: #fff !important;
		}
	}

	.sectorLbl {
		color: #fff !important;
	}

	.biller_tabSection {
		.nav-item {

			// border-right: unset !important;
			a {
				margin-right: unset !important;
				background-color: unset !important;
				color: #fff !important;
				border-bottom: unset !important;
				border-left-color: #666 !important;
				border-right-color: #666 !important;
				border-top-color: #666 !important;
			}
		}

	}

	.ebiller_tabSection {
		.nav-item {
			a {
				background-color: unset !important;
				color: #fff !important;
				border-bottom: unset !important;
				border-color: #666 !important;
			}
		}

	}

	.billerDataSection {
		.nav-item a:hover {
			color: #fff !important;
		}
	}

	.docInput {
		width: 100%;
		margin-right: 10px;
		border-radius: 10px !important;
		background-color: #0C1214 !important;
		border: unset !important;
	}

	.docLbl {
		width: 100%;
		color: #fff;
		font-size: 14px;
	}

	.nav-tabs {
		border-bottom: 1px solid #666;
		border-color: 1px solid #666 !important;
	}

	.communicationPanel {
		.communicationBodyWrap {
			border: 1px solid #666;
		}

	}

	.filelabel:hover i,
	.filelabel:hover .title {
		color: #fff !important;
	}

	.panelSector .ng-select .ng-select-container {
		background-color: transparent !important;
		color: #000 !important;
		border: 1px solid $header-bgcolor !important;
	}

	.detail_save_btn {
		color: $primary-color;
	}

	.billTabSection {
		border: 1px solid $header-bgcolor;
	}

	.emailMessage,
	.ediEmailMessage {
		border: 1px solid #3B424C !important;
	}

	#header.colorChangeTest {
		.header-btn {
			background-color: transparent;
			box-shadow: none;

			&:hover {
				background-color: $table-bgcolor;
			}
		}

		.dropdown-icon-menu {
			&:hover {
				.header-btn {
					background-color: $table-bgcolor;
				}
			}
		}

	}

	.companySelect {
		.ng-select-container {
			background: #202124 !important;
			border: 1px solid #6666;

			.ng-placeholder {
				font-size: 13px;
			}
		}

		.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
			background-color: #666 !important;
			border: 1px solid #6666 !important;
			font-size: 13px !important;

		}

		.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
			background-color: #202124 !important;
			color: $primary-color;
			font-size: 13px !important;
			// border: 1px solid #6666 !important;	
		}

		.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
			background-color: #202124 !important;
		}

		.requiredSector {
			color: #dc3545;
		}

		.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
		.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
			color: #beeb20 !important;
			font-size: 13px !important;
		}
	}

	.internav-tabs>li>a {
		color: #fff !important;
		// background-color: #666 !important;
	}

	.nav-tabs>li.active>a.display-border,
	.nav-tabs>li.active>a.display-border:focus,
	.nav-tabs>li.active>a.display-border:hover {
		background-color: #171717 !important;
		border-top: 2px solid $common_base_color !important;
		border-left: 2px solid $common_base_color !important;
		border-right: 2px solid $common_base_color !important;
		color: #fff;
		box-shadow: none;
	}

	.nav-tabs.invoice-tab {
		>li {
			border: 1px solid $header-bgcolor !important;
			border-top: none !important;

			&:hover {
				border: 1px solid #7c7b7b !important;
				border-top: none !important;
				background-color: #7c7b7b !important;
				box-shadow: 0 -2px 0 #7c7b7b !important;
			}

			>a.invoice-tabhead {
				color: $primary-color !important;
			}

			>a {
				&:hover {
					color: $primary-color !important;
					background-color: #7c7b7b !important;
				}

				&:focus {
					color: $primary-color !important;
					background-color: #7c7b7b !important;
				}
			}
		}

		>li.active {
			border: none !important;
			box-shadow: 0 -2px 0 $common_base_color !important;

			&:hover {
				background-color: transparent !important;
			}

			>a.invoice-tabhead {
				background-color: $content-bg-color !important;
				color: $primary-color !important;

				&:focus {
					background-color: $content-bg-color !important;
					color: $primary-color !important;
				}

				&:hover {
					background-color: $content-bg-color !important;
					color: $primary-color !important;
				}
			}
		}
	}

	.invoice-tab-header {
		border: 1px solid map.get($darktheme-colors, "bordercolor") !important;
		border-bottom: none !important;
		border-left: none !important;
		border-right: none !important;
		background-color: $content-bg-color !important;
		color: #fff !important;
	}

	.tooltipA .tooltiptext {
		background: $widget-color 0% 0% no-repeat padding-box;
		color: $base-color;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
	}

	table.invoice-details {
		tr {

			//   border-bottom: 1px solid $tabsborder-color !important;
			>td {

				// border-bottom: 1px solid $tabsborder-color !important;
				&:first-child {
					//   border-right: 1px solid $tabsborder-color !important;
				}
			}
		}
	}

	.connector-form {

		.inputSection,
		.selectSection {
			.form-control {
				background-color: #0C1214 !important;
				border: 1px solid #0C1214 !important;
			}
		}
	}

	.borderHead {
		border-radius: 8px;
		padding: 1rem;
		border: 1px solid $header-bgcolor !important;
	}

	.styleBackground {
		border: 1px solid $header-bgcolor !important;
	}

	.businessField,
	.bp_connections,
	.bp_connectorStatus {
		border: 1px solid $header-bgcolor !important;
	}

	.border-style {
		border: 1px solid $header-bgcolor !important;
	}

	.app-tut.active {
		background-color: #7c7b7b !important;
	}

	.app-tut {
		background-color: $content-bg-color;

		&:not(.nohover) {
			&:hover {
				background-color: #7c7b7b !important;
			}
		}


	}

	.dopzoneTxt {
		color: #fff;
	}

	.fuelux .wizard {
		background-color: transparent !important;
		border-color: #333333;
	}

	.approveBtn {
		color: $primary-color;
	}

	.fuelux .wizard ul li {
		position: relative;
		float: left;
		height: 46px;
		padding: 0 20px 0 30px;
		margin: 0;
		font-size: 16px;
		line-height: 46px;
		color: #fff;
		cursor: default;
		background: #7c7b7b;
	}

	.fuelux .wizard ul li.active {
		position: relative;
		float: left;
		height: 46px;
		padding: 0 20px 0 30px;
		margin: 0;
		font-size: 16px;
		line-height: 46px;
		color: #fff;
		cursor: default;
		background: #171717;
	}

	.fuelux .wizard .actions {
		position: absolute;
		right: 0;
		z-index: 2;
		float: right;
		padding-right: 15px;
		padding-left: 15px;
		line-height: 46px;
		vertical-align: middle;
		background-color: #171717;
		border-left: 1px solid #3B424C;
	}

	.fuelux .wizard ul li .chevron:before {
		position: absolute;
		top: -24px;
		right: 1px;
		display: block;
		border: 24px solid transparent;
		border-right: 0;
		border-left: 14px solid #171717;
		content: "";

	}

	//           .fuelux .wizard ul li .chevron:hover {

	// 	border-left: 14px solid #171717;
	// 	content: "";
	//           }
	.fuelux .wizard ul li {
		position: relative;
		float: left;
		height: 46px;
		padding: 0 20px 0 30px;
		margin: 0;
		font-size: 16px;
		line-height: 46px;
		color: #fff;
		;
		cursor: default;
		background: #171717;
	}

	.fuelux .wizard ul li.active {
		color: #000;
		background: $common_base_color;
	}

	.fuelux .wizard ul li.active .chevron:before {
		border-left: 14px solid $common_base_color;
	}

	.snd-rcv-div.active {
		background-color: $commonbase-color !important;

		.snd-rcv-img {
			filter: brightness(0) invert(0) !important;
			img {
				filter: brightness(0) invert(1) !important;
			}
		}

		.snd-rcv-title {
			color: $fontcommon-color !important;
		}
	}

	.snd-rcv-div {
		// background-color: $content-bg-color;
		border: 1px solid $common_base_color !important;
		.snd-rcv-img {
			filter: brightness(0) invert(1) !important;
			img {
				filter: brightness(0) invert(1) !important;
			}
		}
		&:hover {
			background-color: $commonbase-color !important;

			.snd-rcv-img {
				filter: brightness(0) invert(0) !important;

				img {
					filter: brightness(0) invert(0) !important;
				}
			}

			.snd-rcv-title {
				color: $fontcommon-color !important;
			}
		}
	}

	#left-panel {
		nav {
			ul {
				li {
					border-bottom: 1px solid $commonbase-color;

					&:hover {
						background: $commonbase-color !important;
						color: $common-color !important;
						// border-top: 1px solid #666666;
						// border-bottom:1px solid #3B3B3B33;
						border: 1px solid #3B3B3B33;
						border-bottom: 1px solid #3B3B3B33 !important;

						a {
							color: $common-color !important;
						}

						ul {
							li {
								a {
									color: $primary-color !important;
								}

								&:hover {
									color: $common-color !important;
								}
							}
						}
					}
				}

				li.active {
					background-color: $commonbase-color !important;

					// margin-right: -1px !important;
					a {
						color: $hovertext-color !important;
					}

					ul {
						li {
							color: #202427 !important;

							&:hover {

								a {
									color: $hovertext-color !important;
									background: transparent !important;
								}
							}

							a {
								color: $primary-color !important;
								font-weight: 400 !important;
							}

						}

						li.active {
							a {
								color: #202427 !important;
							}
						}
					}
				}

				li.open {
					&:hover {
						a b {
							color: $common-color !important;
						}
					}

					li:hover {
						a {
							color: #202427 !important;
						}
					}

					a b {
						color: #ffffff !important;
					}

					li.open.active a b {
						color: #202427 !important;
					}

					li.open:hover>a {
						color: #202427 !important;
					}

					li.open:hover>a b {
						color: #202427 !important;
					}
				}

				li.open.active {
					a b {
						color: #202427 !important;
					}
				}
			}
		}
	}

	table.bulkTable {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		border: 1px solid #666666 !important;
		border-radius: 5px;
	}

	table.bulkTable {
		margin-top: 6px !important;
		margin-bottom: 0px !important;
		border: 1px solid $header-bgcolor !important;
		border-radius: 5px;
		color: #fff;
	}

	.archiveImport {
		background: #202124;
		color: #fff;
	}
	// .upgradebtn 
    // {
    //    color: #577000 !important;
    // }
	.filter-top{
		.link {
		color: $common_base_color !important;
		}
	}
	.subscriptions-pdf{
		.sub-items{
			border: 1px solid #333;
		}
	}

	/* price - calculation */

	.price_calcultation_form {
		.dropdown-list {
			background-color: $content-bg-color !important;
			box-shadow: 0 1px 5px $widget-bgcolor !important;
			border: 1px solid $tabsborder-color !important;
		}

		.multiselect-item-checkbox {
			input[type=checkbox] {
				+ {
					div {
						color: $primary-color !important;
					}
				}
			}
		}

		.item1 {
			.multiselect-item-checkbox {
				&:hover {
					background-color: #7c7b7b !important;

					input[type=checkbox] {
						+ {
							div {
								color: #111 !important;
							}
						}
					}
				}
			}
		}

		.item2 {
			.multiselect-item-checkbox {
				&:hover {
					background-color: #7c7b7b !important;
				}
			}
		}

		.multiselect-dropdown {
			.dropdown-btn {
				border: 1px solid #333 !important;
				background-color: #202124 !important;
			}
		}
	}
	/* price - calculation */
	

	.userEditTextBox {
		background: $content-bg-color 0% 0% no-repeat padding-box !important;
		color: $primary-color;
		border-radius: 10px!important;
	}
	.plan-title{
		color: $common_base_color !important;
	}
	.target-highlight,.target-highlightPdf {
		// background-color: #FFD700;
		// -webkit-animation: target-fade-dark 3s forwards;
		// -moz-animation: target-fade-dark 3s forwards;
		border-color: $common_base_color !important;
	}
	.invoice-div{
		.paymentSectionDetails {
			-ms-overflow-style: none;
			/* IE and Edge */
			scrollbar-width: none;
			/* Firefox */
			background-color: $widget-color !important;
			border-radius: 10px;
			margin-left: -1px;
		}
	
		.paymentOptions {
			display: none;
			// background-color: $secondaryFocus !important;
			border-radius: 10px;
			-ms-overflow-style: none;
			/* IE and Edge */
			scrollbar-width: none;
			/* Firefox */
			background-color: map.get($darktheme-colors, "bgcolor") !important;
			border-radius: 10px;
			margin-left: -1px;
		}
		.paymentOptions {
			display: none;
			// background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
			border-radius: 10px;
		}
		.currentButton {
			background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
			color: $common-color !important;
			border-color: map.get($whitetheme-colors, "bordercolor") !important;
			cursor: not-allowed;
			z-index: 1;
		}
	
		.currentPlan {
			background-color: map.get($whitetheme-colors, "bordercolor") !important;
			color: $secondary-color !important;
	
			&:hover {
				background: map.get($whitetheme-colors, "bordercolor") !important;
			}
		}
	}
	.inv-img{
		content: url('../img/invoice_orange.svg') !important;
	}
	.payment-detail {
		width: 100%;
		max-width: 1010px;
		background-color: $widget-bgcolor;
		margin: auto;
		padding: 10px 10px 6px 10px;
	}
	.underline {
		border-color: $header-bgcolor;
	}

	.plan-stat {
		border-left: 1px solid map.get($darktheme-colors, "bordercolor");
		&:first-of-type {
			border-bottom: 1px solid map.get($darktheme-colors, "bordercolor");
		}
	}
	.cursor-pointer{
		cursor: pointer !important;
	}
	.fieldForm{
		.form-control{
			// background-color: $content-bg-color;
			border: 1px solid $header-bgcolor;
			color: $primary-color !important;
		}
	}
	.durationDetailFields{
		background-color: $widget-bgcolor !important;
		.fieldForm{
			.commonFormField{
				input{
					background-color: $widget-bgcolor !important;
				}
				textarea#exampleFormControlTextarea1{
					background-color: $widget-bgcolor !important;
				}
			}
		}
	}
	.module{
		.module-name {
			color: #fff !important;
		}
	}
	.company-label{
		color: $primary-color !important;
	}
	.iom_btn {
		&:hover {
			color: $hovertext-color !important;
		}
	}
	.crossBtn{
		color: $commonbase-color !important;
	}
	.ac_ConnectionSection{
		.fillGreen{
			color: $common-color !important;
		}
	}
	.footer-menu {
		color: $primary-color !important
	}
	.ac_ConnectionSection{
		.ac_div {
			color: $primary-color !important;
		}
		.ac_typeDiv{
			color: $commonbase-color !important;
			.common_detail{
				color: $commonbase-color !important;
			}
		}
	}
	.chatIcon{
		filter: brightness(0) invert(1) !important;
	}
	.fillGreen {
		color: #212529 !important;
	}
	#overlay {
		background-color: rgba(0, 0, 0, 0.5);
	}
	.ng-select .ng-select-container .ng-value-container .ng-placeholder {
		color: #fff !important;
	}
	#transaction-header .form-control::placeholder{
		color: #fff !important;
	}
	.companyTooltip {
		background: $widget-bgcolor 0% 0% no-repeat padding-box;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
		color: $base-color;
	}
	.checkmark {
		border: 2px solid $primary-color;
	}
	.checkbox-container .checkmark:after {
		left: 7px;
		top: 1px;
		width: 8px;
		height: 16px;
		border: solid $primary-color;
		border-width: 0 3px 3px 0;
		transform: rotate(45deg);
	}
	.packageplanBtn {
		border-radius: 2px !important;
		
		transition: background-color 0.3s ease;
		padding: 8px 35px;
		font-size: 13px;
		color:$primary-color ;
		&.active {
		  color: $primary-color ;
		  background: $common_base_color;
		  border: solid $common_base_color;
		}
	            }

}

/*
---------------------------------------------------------------------------------------
    End  Dark theme style
---------------------------------------------------------------------------------------
*/


/*
---------------------------------------------------------------------------------------
    Default White theme style
---------------------------------------------------------------------------------------
*/

.smart-style-2 {
	//------------------------responsive view---------------------------------------------
.setgreenFont
{
    color: #474d22 !important;
}
.spinner-circle-swish {
	color: #252424 !important;
}
// .green-label {
//     color: #28a745 !important;
// }
//------------------------responsive view end ----------------------------------------
.upgradebtn 
    {
       color: #577000 !important;
    }

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
		-webkit-text-fill-color: $secondaryFocus !important;
		-webkit-box-shadow: 0 0 0 30px map.get($whitetheme-colors, "commonbgcolor") inset !important;
	}

	.iconData {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.setCircleactive {
		color: #00eba9;
		font-size: 10px !important;
	}

	.setCirclerej {
		color: #eb0042;
		font-size: 10px !important;
	}

	.footerTextColor {
		color: #CCCCCC !important;
	}

	.login-info {
		span.borderRightClr {
			border-right: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		}
	}

	#left-panel {
		nav {
			ul.edi {
				li {
					border-bottom: 1px solid $common_base_color;

					&:hover {
						background: $common_base_color !important;
						color: $hovertext-color !important;
						// border-top: 1px solid #666666;
						border: 1px solid #3B3B3B33;
						// border-bottom: none;
					}
				}

				li.active {
					background-color: $common_base_color !important;

					// margin-right: -1px !important;
					a:before {
						content: unset !important;
					}
				}
			}

			ul.ebill {
				li {
					border-bottom: 1px solid $common_base_color;

					&:hover {
						background: $common_base_color !important;
						color: $hovertext-color !important;
						// border-top: 1px solid #666666;
						border: 1px solid #3B3B3B33;
						// border-bottom: none;
						a{
							color: $primary-color !important;
						}
					}
				}

				li.active {
					background-color: $common_base_color !important;
					color: $primary-color !important;
					// margin-right: -1px !important;
					a:before {
						content: unset !important;
					}
				}
			}
			ul{
				.active>a{
					color: $primary-color !important;
				}
			}
		}

		// nav ul li.active>a:before {
		//     content: unset !important;
		// }
	}

	nav ul li.active>a:before {
		content: unset !important;
	}

	.setArrowBlue {
		color: #535353 !important;
	}

	table.borderline {
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
	}

	.headClr {
		color: $secondaryFocus !important;
	}

	.inboxWidget {
		background: $primary-color 0% 0% no-repeat padding-box !important;
		border: none;
	}

	.detailDiv {
		background: $primary-color 0% 0% no-repeat padding-box !important;
		border: none;
	}

	table.positionTable {
		border: 1px solid $light-border !important;
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		border-radius: 5px;
	}

	.button-class {
		background-color: $primary-color !important;
		color: $subtext-color !important;
	}

	.wid_content {
		border: none !important;
	}

	.font-14 {
		color: $common-color !important;
	}

	.lstTrncnTbl {
		.dt-toolbar {
			background: none !important;
			border-bottom: none;
		}
	}

	.dt-toolbar {
		.dataTables_filter {
			display: none !important;
		}

		.dataTables_length {
			display: none !important;
		}
	}

	.dt-toolbar-footer {
		background: none !important;
		font-size: none !important;
		overflow: hidden;
		padding: none !important;
		border-top: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		-ms-box-shadow: none !important;
		box-shadow: none !important;
		width: 100%;
	}

	.commonProgress {
		background-color: $common_base_color !important;
		// background-color: map.get($whitetheme-colors, "bordercolor") !important;
		color: $table-bgcolor !important;
	}

	.btn.btn-ribbon {
		background: #9498A0;
		margin-left: 4px;
	}

	.line1 {
		border: 1px solid map.get($whitetheme-colors, "commonbgcolor") !important;
	}

	.setFontlabel {
		color: $tablehover-color;
		font: normal normal bold 16px/22px Open Sans;
	}

	.setFonts {
		color: $fontColor;
	}

	.box-border1 {
		background-color: $primary-color !important;
		border: 1px solid map.get($whitetheme-colors, "commonbgcolor") !important;
		height: auto;
		width: 430px !important;
	}

	.goButtons {
		top: 189px;
		left: 1808px;
		width: 86px;
		height: 40px;
		background-color: $buttoncolor !important;
		text-align: center;
		letter-spacing: 0px;
		color: $primary-color !important;
		font: normal normal normal 13px/18px Open Sans;
	}

	.tblBsnsConnectionHead {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.labelColor {
		color: $secondaryFocus !important;
	}

	.stepsinout-form.formly {
		.form-control {
			color: $secondaryFocus !important;
			background-color: #EEEEEE !important;
			border-color: #EEEEEE;
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
			// border-radius: 10px !important;
			-webkit-transition: border 0s ease-in-out 0s;
			-moz-transition: border 0s ease-in-out 0s;
			-o-transition: border 0s ease-in-out 0s;
			-ms-transition: border 0s ease-in-out 0s;
			transition: border 0s ease-in-out 0s;
		}
	}

	.businessconnect-form.formly {
		.form-control {
			color: $secondaryFocus !important;
			background-color: #EEEEEE !important;
			border-color: #EEEEEE !important;
			// border-radius: 10px !important;
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			// border-color: map.get($whitetheme-colors, "commonbgcolor") !important;
			// border-color:none;
			-webkit-transition: border 0s ease-in-out 0s;
			-moz-transition: border 0s ease-in-out 0s;
			-o-transition: border 0s ease-in-out 0s;
			-ms-transition: border 0s ease-in-out 0s;
			transition: border 0s ease-in-out 0s;

		}
	}
	.fileuploadform-form.formly {
		.form-control {
			color: $secondaryFocus !important;
			background-color: #EEEEEE !important;
			border-color: #EEEEEE !important;
			// border-radius: 10px !important;
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			// border-color: map.get($whitetheme-colors, "commonbgcolor") !important;
			// border-color:none;
			-webkit-transition: border 0s ease-in-out 0s;
			-moz-transition: border 0s ease-in-out 0s;
			-o-transition: border 0s ease-in-out 0s;
			-ms-transition: border 0s ease-in-out 0s;
			transition: border 0s ease-in-out 0s;

		}
	}

	.header-search-form.formly.horizontal {
		.form-control {
			background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		}
	}

	.header-search-form.formly {
		.input-group-append {
			.input-group-text {
				background-color: map.get($whitetheme-colors, "commonbgcolor");
				border: unset !important;
			}
		}
	}

	.stepsinout-form {
		.input-group-append {
			.input-group-text {
				background-color: #EEEEEE;
				// border: 1px solid $header-bgcolor !important;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;

				&::before {
					border-left: 1px solid #A2A2A2;
				}
			}
		}
	}

	.businessconnect-form {
		.input-group-append {
			.input-group-text {
				background-color: #EEEEEE;
				// border: 1px solid $primary-color;
				border-top-right-radius: 10px !important;
				border-bottom-right-radius: 10px !important;

				&::before {
					border-left: 1px solid #A2A2A2;
				}
			}
		}
	}

	.borderClr {
		border: unset !important;
	}

	.tableHeadBGclr {
		background: map.get($whitetheme-colors, "bgcolor") !important;
		border: none;
	}

	#inputState, .text-field {
		color: $subtext-color !important;
	}

	.dashbaordlabel {
		color: map.get($whitetheme-colors, "color") !important;
		display: block;
		width: 100%;
		height: 36px;
		font-size: 13px;
		color: $secondaryFocus !important;
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		background-clip: padding-box;
		border-color: $secondaryFocus;
	}

	.hoverTable {
		tbody {
			tr {
				&:hover {
					color: $primary-color !important;
					background-color: $tablehover-color !important;
				}
			}
		}
	}

	.tranhoverTable {
		tbody {
			tr {
				&:hover {
					color: map.get($whitetheme-colors, "color") !important;
					background-color: map.get($whitetheme-colors, "bgcolor") !important;
					cursor: pointer;
				}
			}

			tr.selected {
				&:hover {
					// color: $primary-color !important;
				}
			}
		}
	}

	#ribbon {
		.breadcrumb {
			li {
				color: $common-color !important;

				&:last-child {
					color: $common-color !important;
				}

				&:before {
					color: $common-color !important;
				}
			}
		}
	}

	.intrTiles {
		background-color: $primary-color !important;
		border: unset !important;
	}

	.gridhead {
		color: #212529 !important;
	}

	table.dataTable {
		tbody {
			tr.selected {
				// color: $primary-color;
			}
		}
	}

	nav {
		ul {
			li.active {
				a {
					&:before {
						color: #202427;
					}
				}
			}
		}

		ul {
			li {
				a {
					padding: 12px 17px 12px 16px !important;

					i {
						font-size: 17px !important;
						vertical-align: 0 !important;
					}
				}
			}
		}
	}

	.blacktooltip {
		background: map.get($whitetheme-colors, "commonbgcolor") 0% 0% no-repeat padding-box;
		border: 1px solid map.get($whitetheme-colors, "bgcolor");
		color: map.get($whitetheme-colors, "color");

		.insideDivUl {
			li {
				&:hover {
					background: map.get($whitetheme-colors, "bgcolor");
				}
			}
		}
	}
	.tooltip-text{
		background: map.get($whitetheme-colors, "commonbgcolor") 0% 0% no-repeat padding-box;
		border: 1px solid map.get($whitetheme-colors, "bgcolor");
		color: map.get($whitetheme-colors, "color");
	}

	.idTooltip,
	.btnTooltip,
	.errorTooltip {
		background: map.get($whitetheme-colors, "commonbgcolor") 0% 0% no-repeat padding-box;
		border: 1px solid map.get($whitetheme-colors, "bgcolor");
		color: map.get($whitetheme-colors, "color");

	}

	.progress {
		border-radius: 0px !important;
	}

	#left-panel {
		padding-right: 0px !important;
		padding-left: 0px !important;
		padding-bottom: 0px !important;
		// padding-top: 49px !important;
	}

	.InputCheck {
		color: map.get($whitetheme-colors, "color") !important;
		font-size: 14px;
		margin-left: 21px;
	}

	.detailLbls {
		color: map.get($whitetheme-colors, "color") !important;
		font-size: 18px;
	}

	.gridBGclr {
		background: map.get($whitetheme-colors, "commonbgcolor") !important;
	}

	.documentLbl {
		background: map.get($whitetheme-colors, "color") !important;
		text-align: center;
		font: normal normal normal 13px/18px Open Sans;
		color: $primary-color !important;
		padding-left: 5px !important;
		padding-right: 5px !important;
	}

	.inboxtra {
		background-color: $primary-color !important;
	}

	.nav-tabs {
		li {
			float: left;
			margin-bottom: -1px;
			width: 34%;
			text-align: center;
			border-bottom: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		}
	}



	// .box-borders {
	// 	border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
	// }

	.invoiceOverview {
		.nav-tabs {
			li.active {
				// border-top: 3px solid map.get($whitetheme-colors, "color") !important;
				// border-top: 1px solid #707070 !important;
				// border-radius: 10%;
			}
		}
	}

	.table.dataTable {
		thead {
			tr {
				th.sorting_disabled {
					padding-right: 9px !important;
					border-color: map.get($whitetheme-colors, "bordercolor");
					vertical-align: top;
				}
			}
		}
	}

	.InterData {
		color: $secondaryFocus !important;
		background-color: $primary-color !important;
		color: $secondaryFocus !important;
	}

	.headData {
		background-color: map.get($whitetheme-colors, "bgcolor") !important;
		color: map.get($whitetheme-colors, "color") !important;
		border-color: map.get($whitetheme-colors, "bordercolor") !important;
	}

	.header-dropdown-list {
		.dropdown-menu {
			li {
				a {
					&:hover {
						background-color: map.get($whitetheme-colors, "bgcolor") !important;
					}
				}
			}

			.active {
				a {
					background-color: map.get($whitetheme-colors, "bgcolor") !important;
				}
			}
		}

		a.dropdown-toggle {
			color: map.get($whitetheme-colors, "color") !important;
		}
	}

	.notification-body {
		.msg-body {
			color: $subtext-color !important;
		}

		.activityTime {
			color: $subtext-color !important;
		}

		li {
			span {
				background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
			}
		}

		.unread {
			background-color: map.get($whitetheme-colors, "bgcolor");
		}

		.from {
			color: map.get($whitetheme-colors, "color") !important;
		}
	}

	.ajax-dropdown {
		border: 1px solid #d9d9d9 !important;
	}

	#fullscreen {
		.svg-icon {
			background-image: url("../img/iconSvg/light-theme/Path222.svg");
		}
	}

	#header {
		.notification-svg {
			background-image: url("../img/iconSvg/light-theme/39-Notification.svg");
		}

		#activity.activity-svg {
			background-image: url("../img/iconSvg/light-theme/Page-1.svg");
		}

		.collapseMenu {
			border: unset !important;

			.collaspe-menu-svg {
				background-image: url("../img/iconSvg/light-theme/Path 224.svg");
			}

			.collaspe-menu-sub-svg {
				background-image: url("../img/iconSvg/Group224.svg");
				cursor: pointer;
			}

			&:hover {
				background-color: map.get($whitetheme-colors, "bordercolor");
			}
		}

		.header-btn {
			box-shadow: none;
		}

		.header-btn-list {
			a {
				box-shadow: none;
			}
		}

		.menu-item {
			&:not(:last-child) {
				// border-right: 1px solid map.get($whitetheme-colors, "bordercolor");
				border: unset !important;
			}

			.webComponetsBorder {
				// border-right: 1px solid map.get($whitetheme-colors, "bordercolor");
				border: none;
			}

			&:hover {
				background-color: map.get($whitetheme-colors, "bordercolor");
			}

			.webComponetsHoverColor {
				&:hover {
					background-color: map.get($whitetheme-colors, "bordercolor");
				}
			}
		}

		.menu-item.no-hover {
			&:hover {
				background-color: map.get($whitetheme-colors, "bgcolor");
			}
		}

		.menu-left-item {
			border-left: 1px solid map.get($whitetheme-colors, "bordercolor");
		}
	}

	// #main {
	// 	background-color: map.get($whitetheme-colors, "bgcolor") !important;
	// }

	.theme-icon {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.theme-text-color {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.theme-reverse-text-color {
		color: $primary-color !important;
	}

	.theme-header-text-color {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.theme-bg-color {
		background-color: map.get($whitetheme-colors, "bgcolor") !important;
	}

	.theme-subtext-color {
		color: $subtext-color !important;
	}

	.dashboard {
		.jarviswidget {
			div {
				background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
				border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
			}
		}
	}

	.profileBackground {
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
	}

	.widBody {
		.alignIconitems {
			background-color: map.get($whitetheme-colors, "bgcolor") !important;
			border: 8px solid map.get($whitetheme-colors, "commonbgcolor") !important;

			&:hover {
				border: 8px solid map.get($whitetheme-colors, "commonbgcolor") !important;
			}
		}
	}

	.jarviswidget {
		header {
			border: none !important;
		}
	}

	.body-image {
		box-shadow: none !important;
	}

	header.subscriptionHead {
		background-color: map.get($whitetheme-colors, "bordercolor") !important;
	}

	.bs-example {
		color: $secondaryFocus !important;
	}

	.headtabData {
		color: map.get($whitetheme-colors, "color") !important;
		border: none;
	}

	.intertabData {
		color: $secondaryFocus !important;
		background-color: none;
	}

	.inboxHeads {
		background-color: map.get($whitetheme-colors, "bgcolor") !important;
		font: normal normal bold 13px/18px Open Sans;
		color: $secondaryFocus !important;
		border: none;
		border-radius: 5px 5px 0px 0px;
	}

	.transactionHeads {
		font: normal normal bold 13px/18px Open Sans;
		color: map.get($whitetheme-colors, "color") !important;
	}

	.detailLbl {
		color: $secondaryFocus !important;
	}

	.lstLbl {
		color: $secondaryFocus !important;
	}

	.lblWhite {
		color: $secondaryFocus !important;
	}

	.edilabel {
		background: map.get($whitetheme-colors, "commonbgcolor") !important;
		color: $secondaryFocus !important;
		font: normal normal normal 13px/18px Open Sans;
	}

	.goButton {
		background: $buttoncolor !important;
		color: $primary-color !important;
		font: normal normal normal 13px/18px Open Sans;
	}

	.setBackground {
		background-color: $primary-color !important;
	}

	.periodlabel {
		background: map.get($whitetheme-colors, "commonbgcolor") !important;
		color: $secondaryFocus !important;
		font: normal normal normal 13px/18px Open Sans;
	}

	.commonHead {
		background: $primary-color !important;
		color: $secondaryFocus !important;
		font: normal normal normal 13px/18px Open Sans;
	}

	.headGreen {
		background-color: $fontbackground-color !important;
		color: $primary-color;
	}

	.commonBackground {
		background-color: map.get($whitetheme-colors, "commonbgcolor");
		color: map.get($whitetheme-colors, "color");
		height: 40px;
		border-color: $secondaryFocus;
		font: normal normal normal 13px/18px Open Sans;

		option {
			background-color: map.get($whitetheme-colors, "commonbgcolor");
			;
			color: map.get($whitetheme-colors, "color");
			height: 40px;
			border-color: $secondaryFocus;
			font: normal normal normal 13px/18px Open Sans;
		}
	}

	.setFontsub {
		color: $fontColor;
		font: normal normal bold 13px/18px Open Sans;
	}

	.notification-body {
		.subject {
			color: $subtext-color !important;
		}
	}

	.dashboardHead {
		.jarviswidget-ctrls {
			.button-icon {
				border-left: unset !important;
			}
		}
	}

	.icon {
		padding: 10px;
		color: $secondaryFocus;
		min-width: 50px;
		text-align: end;
		right: 15px;
		top: 8px;
		font-size: 14px;
	}

	nav ul li a {
		color: $primary-color !important;
		font-size: 14px !important;

		&:focus,
		&:hover {
			background: $common_base_color !important;
			//         border-bottom: 2px solid #666666;
			//         border-top: 2px solid #666666;
		}
	}

	.internav-tabs>li.active>a {
		color: $primary-color !important;
		font-size: 14px !important;
		border-bottom: 2px solid $common_base_color;
		border-top: 2px solid $common_base_color;
	}

	tbody {
		tr.child {
			td {
				background-color: map.get($whitetheme-colors, "bgcolor");
				color: map.get($whitetheme-colors, "color") !important;
			}
		}
	}

	.ui-datepicker {
		td {
			.ui-state-default {
				color: $tabsborder-color;
			}
		}

		.ui-datepicker-header {
			background-color: $primary-color;
		}
	}

	.big-spin-loader {
		background: url(../img/spin-loader.svg) no-repeat !important;
	}

	.big-spin-page-loader {
		background: url(../img/spin-loader.svg) no-repeat !important;
	}

	.dataTables_processing {
		background: url(../img/spin-loader.svg) no-repeat !important;
	}

	.lstposTbale {
		.dataTables_processing {
			background: url(../img/spin-loader_small.svg) no-repeat !important;
		}
	}

	.ui-datepicker {
		td {
			.ui-state-highlight {
				color: $primary-color;
			}

			.ui-state-hover {
				color: $primary-color;
			}
		}
	}

	.card-header {
		background-color: #EDEDED;
	}

	.panel {
		background: #cecece;
	}

	.card {
		background-color: #f7f7f7;
		border: 1px solid #e1e1e1;
	}

	.card-box {
		background-color: #eee !important;
	}

	.box-header {
		color: rgb(33, 37, 41);
		border-bottom: 1px solid $tabsborder !important;
	}

	.panel-tag {
		border-left: 3px solid darkgray;
		background: $whiteborder-color;
		color: $common-color;
	}

	.commonWidgetText {
		color: inherit;
		font-size: 13px;
	}

	.userWidgetText {
		color: inherit;
		font-size: 16px;

	}

	.serviceTheme {
		background-color: map.get($darktheme-colors, "textcolor") !important;
		// width: 1200px !important;
		border-radius: 10px;

		.ui-dialog-titlebar {
			background-color: map.get($darktheme-colors, "textcolor") !important;
			border-bottom: none !important;
			width: 100% !important;

			.ui-dialog-title {
				width: 100% !important;

				.widget-header {
					// display: flex;
					// justify-content: center;
					text-align: left;
				}

				.shortDescription {
					text-align: center;
				}
			}

			.ui-dialog-titlebar-close {
				position: absolute !important;
				top: 25px;
				right: 5px;
			}

			h5 {
				color: $common-color !important;
			}

			h1 {
				color: $common-color !important;
				padding: 0 25px;
				text-align: left;
				font: normal normal bold 16px/22px Open Sans;
				letter-spacing: 0px;
				opacity: 1;
			}

			.ui-button {
				color: $common_base_color;
				opacity: 1;
			}
		}

		.ui-dialog-content {
			background-color: map.get($darktheme-colors, "textcolor") !important;

			.service_modal {
				background-color: map.get($darktheme-colors, "textcolor") !important;

				.modal-content {
					box-shadow: unset !important;
					-webkit-box-shadow: unset !important;
					border: unset !important;
					background-color: map.get($darktheme-colors, "textcolor") !important;
				}
			}
		}

	}

	.chip_Section {
		background-color: $primary-color !important;
		border: 1px solid $light-border !important;
		color: map.get($whitetheme-colors, "color") !important;
		margin-left: 0px !important;
	}

	.serviceSection:hover {
		// border: 1px solid $base-color !important;
		background-color: #f3f1f1 !important;
	}

	.toggleMore {
		background-color: $tabsborder !important;
	}

	.menuIconsOption {
		img {
			filter: brightness(100) invert(1);
		}
	}

	.chip {
		background-color: transparent !important;
		color: $secondaryFocus !important
	}

	.menuSelected {
		background-color: map.get($whitetheme-colors, "bordercolor");
	}

	.dropdown-icon-menu>ul>li .btn,
	.header-btn {
		background: none;
	}

	.theme-bg-colors-border {
		background-color: $primary-color !important;
		box-shadow: unset !important;
		// border-bottom: 2px solid $common_base_color;
	}

	.headerContent {
		border-radius: 8px;
		border: 1px solid $light-border;
		;
		// box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important; 
	}

	.pagination>.active>a {
		background-color: $common_base_color !important;
		border-color: $common_base_color !important;
		color: $hovertext-color;
	}

	.pagination>li>a {
		background-color: $primary-color !important;
		color: $common-color;
	}

	.pagination>li:first-child>a,
	.pagination>li:first-child>span {
		margin-left: 0;
		border-bottom-left-radius: 5px;
		border-top-left-radius: 5px;
		background-color: $primary-color !important;
		// color: $common_base_color;
	}

	.pagination>li:last-child>a,
	.pagination>li:last-child>span {
		border-bottom-right-radius: 5px;
		border-top-right-radius: 5px;
		background-color: $primary-color !important;
		// color: $common_base_color;

	}

	.algntblTranscation {
		padding: 0 0 0 7px;
		border-radius: 8px;
		border: 1px solid $light-border !important;
		margin: 0 3px !important;
		overflow: hidden;
	}

	.headerContents {
		// padding: 0 0 0 7px;
		border-radius: 8px;
		border: 1px solid $light-border !important;
		// margin-left: 0 3px !important;
		overflow: hidden;
	}

	#content {
		background-color: $primary-color !important;
	}

	.widgetGray {
		background-color: $primary-color !important;
	}

	.edipartnerLbl {
		color: $common-color !important;
	}


	.header-formbtn,
	.advace-header-formbtn {
		background-color: $primary-color !important;
		border: 2px solid $common_base_color !important;
		color: $common-color !important;
		padding: 3px 15px;

		&:hover {
			background: $common_base_color !important;
			color: $hovertext-color !important;
		}
	}

	.select-label {
		color: $common-color !important;
	}

	.dashbaordlabel {
		background-color: unset !important;
		border: 1px solid $light-border !important;
		border-radius: 5px !important;
	}

	.modalBodyBgColor {
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
	}

	.theme-header-color {
		color: map.get($whitetheme-colors, "color") !important;
	}

	// chain class selctors
	.setting-menu {
		#header {
			.settingsMenu {
				background: #CCCCCC !important;
			}
		}
	}

	.btn-primary {
		background: $primary-color !important;
		border: 2px solid $common_base_color;
		color: $content-bg-color !important;
		padding: 3px 15px;
		border-radius: 15px;
		font-size: 13px;

		&:hover {
			background: $common_base_color !important;
			color: $hovertext-color !important;
			border: 2px solid $common_base_color;
		}

		&:focus {
			border-color: $common_base_color !important;
		}
	}

	.ebill_btn {
		background: $primary-color !important;
		border: 2px solid $common_base_color;
		color: $content-bg-color !important;
		padding: 3px 15px;
		border-radius: 15px;
		font-size: 13px;

		&:hover {
			background: $common_base_color !important;
			color: $hovertext-color !important;
			border: 2px solid $common_base_color;
		}

		&:focus {
			border-color: $common_base_color !important;
		}
	}

	.nav-pills>li.active>a {
		background: $primary-color !important;
		border: 2px solid $common_base_color;
		color: $content-bg-color;
		margin-top: 5px;

		&:hover {
			background: $common_base_color !important;
			color: $hovertext-color !important;
			border: 2px solid $common_base_color;
		}
	}

	.btnModalSave {
		float: right;
		background-color: $primary-color;
		border: none;
		color: $content-bg-color !important;
		border-radius: 15px;
		border: 2px solid $common_base_color;
	}

	.btnModalClose {
		float: left;
		background-color: $primary-color;
		border: none;
		color: $content-bg-color !important;
		border-radius: 15px;
		border: 2px solid $common_base_color !important;
	}

	// BUY-ADDON start

	.theme-header-text-color {
		color: #000 !important;
	}

	.theme-header-color {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.cancel {
		text-align: left;
		width: auto;
		// min-width: 50px !important;
		font: normal normal normal 13px/24px Open Sans;
		border-radius: 15px;
		background: $primary-color 0% 0% no-repeat padding-box !important;
		border: 2px solid $commonbase-color !important;
		letter-spacing: 0px;
		color: $widget-bgcolor !important;
		padding: 3px 15px;
	}

	.TotalLabel {
		top: 275px;
		left: 560px;
		height: 49px;
		text-align: left;
		font: normal normal bold 31px/23px Open Sans;
		letter-spacing: 0px;
		color: $secondary-color;
		opacity: 1;
		margin-left: -16px;
	}

	.paymentSectionDetails {
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
		background-color: $primary-color !important;
	}

	.paymentSectionDetails {
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
		background-color: $primary-color !important;
		border-radius: 10px;
		margin-left: -1px;
	}

	.paymentOptions {
		display: none;
		background-color: $primary-color !important;
		border-radius: 10px;
	}

	// .table-bordered>tbody>tr>td {
	// 	border: 1px solid map.get($whitetheme-colors, "bordercolor");
	// }

	.currentButton {
		background-color: #F7F7F7 !important;
		color: $common-color !important;
		border-color: map.get($whitetheme-colors, "bordercolor") !important;
		cursor: not-allowed;
		z-index: -1;
	}

	.currentPlan {
		background-color: map.get($whitetheme-colors, "bordercolor") !important;
		color: $secondary-color !important;

		&:hover {
			background: map.get($whitetheme-colors, "bordercolor") !important;
		}
	}

	.emptyCol {
		background-color: $primary-color !important;

		border : {
			top: 1px solid transparent !important;
			left: 1px solid transparent !important;
			right: 1px solid transparent !important;
			bottom: 1px solid transparent !important;
		}

	}

	.selectButton {
		border-radius: 0%;
		padding: 5px 20px;
		background-color: $primary-color;
		border: 2px solid $commonbase-color;
		border-radius: 15px;
		color: $secondary-color;
		font-size: 14px;
		outline: none;

		&:hover {
			background: $commonbase-color !important;
			color: $hovertext-color !important;
			border: 1px solid #3B3B3B33;
		}
	}

	.selected {
		background-color: $commonbase-color !important;
		border-color: $commonbase-color !important;

		&:hover {
			color: $hovertext-color !important;
		}
	}

	.rightBorder {
		border-right: 2px solid $commonbase-color !important;
		border-left: 2px solid $commonbase-color !important;
	}

	.topBorder {
		border-top: 2px solid $commonbase-color !important;
	}

	.bottomBorder {
		border-bottom: 2px solid $commonbase-color !important;
	}

	.nextBtn {
		font: normal normal normal 13px/24px Open Sans;
		// width: 178px;
		background: $primary-color 0% 0% no-repeat padding-box !important;
		border-color: $commonbase-color !important;
		letter-spacing: 0px;
		border: 2px solid $commonbase-color !important;
		border-radius: 15px;
		color: $widget-bgcolor !important;
		padding: 3px 15px;

		&:hover {
			background: $commonbase-color !important;
			border: 2px solid $commonbase-color;
			color: $hovertext-color !important;
		}
	}

	.btnAction,
	.btnFile,
	.btnSendinvit {
		font: normal normal normal 13px/24px Open Sans;
		// width: 178px;
		background: $primary-color 0% 0% no-repeat padding-box !important;
		border-color: $commonbase-color !important;
		letter-spacing: 0px;
		border: 2px solid $commonbase-color !important;
		border-radius: 15px;
		color: $widget-bgcolor !important;
		padding: 0px 15px;

		&:hover {
			background: $commonbase-color !important;
			border: 2px solid $commonbase-color;
			color: $hovertext-color !important;
		}
	}

	.btnUpload {
		font: normal normal normal 13px/24px Open Sans;
		// width: 178px;
		background: $primary-color 0% 0% no-repeat padding-box !important;
		border-color: $commonbase-color !important;
		letter-spacing: 0px;
		border: 2px solid $commonbase-color !important;
		border-radius: 15px;
		color: $widget-bgcolor !important;
		padding: 0px 15px;

		&:hover {
			background: $commonbase-color !important;
			border: 2px solid $commonbase-color;
			color: $hovertext-color !important;
		}
	}

	.cancelBtn {
		// top: 728px;
		// left: 560px;
		// width: 100px;
		// height: 38px;
		border: 2px solid $commonbase-color !important;
		border-radius: 15px;
		background: $primary-color 0% 0% no-repeat padding-box !important;
		color: $common-color !important;
		font: normal normal normal 13px/24px Open Sans;
		padding: 3px 15px !important;

		&:hover {
			background: $commonbase-color !important;
			border: 2px solid $commonbase-color;
			color: $hovertext-color !important;
		}

	}

	.jarviswidget>div {
		border: none
	}

	.pricingTable table {
		color: $common-color !important;
	}


	.bootstrapWizard li .step {
		background-color: $primary-color;
		border: 1px solid map.get($whitetheme-colors, "bordercolor");
		color: $secondary-color;
	}

	.lightBackgroundColor {
		background-color: $primary-color !important;
		border: 1px solid map.get($whitetheme-colors, "bordercolor");
		border-radius: 10px;
		margin-left: 13px;
	}

	.textColor {
		color: #4C4F53 !important;

		&:hover {
			background: transparent !important;

		}
	}

	.NextconfirmLabel {
		color: $widget-bgcolor;
		border: 1px solid #CCCCCC;
	}

	.headData {
		background-color: $primary-color;
		color: $common-color;
		margin-top: 10px;
		background-image: none;
	}



	.back-btn {
		background: $subscribe-bgcolor 0% 0% no-repeat padding-box;
		border-color: $subscribe-bgcolor;
		font-size: 13px;
	}

	.buy-addon {
		.navigationList {
			.nav.nav-tabs {
				li {
					a {
						span {
							color: $common-color !important;
						}

						&:hover {
							background-color: $commonbase-color !important;
							border: 1px solid $commonbase-color !important;
						}
					}
				}
			}
		}
	}

	.package-name {
		.packageHeader {
			color: $secondary-color !important;
		}
	}

	.yearlyData {
		span.price {
			color: $secondary-color !important;
		}
	}

	.buy-addon {
		.checkout {
			background-color: $primary-color;
		}
	}

	.table-headData {
		background-color: $primary-color;
		color: $common-color;
		margin-top: 10px;
	}

	// BUY-ADDON end
	.editPaymentHeader {
		color: $common-color !important;
		font-size: 16px !important;
	}

	.payContent {
		background: #F7F7F7 !important;
	}

	.commoninputStyle {
		.form-control {
			height: 40px !important;
			border-radius: 6px !important;
			background-color: $primary-color;
			border: 1px solid map.get($whitetheme-colors, "bordercolor");
			font-size: 14px;
			color: $common-color;
		}
	}

	.tileContainer {
		.commonTile {

			.userDash_tileHead,
			.clickLbl {
				// color: $primary-color;
				font-weight: 500;
				font-size: 13px;
			}

			.userDash_tileHeadgreen,
			.clickLblgreen {
				// color: $common-color;
				font-weight: 500;
			}
		}
	}

	.commonTile:not(.tile0),
	.tileFinal0,
	.tileFinal1 {
		background-color: $primary-color !important;
	}

	.commonTile,
	.leftRowSection,
	.rightediSetion,
	.tileFinal0,
	.tileFinal1,
	.rightFinalSection,
	.commonBorderColor {
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
	}

	.dopzoneTxt {
		color: $common-color !important;
	}

	.dashimgDiv {
		background : {
			repeat: no-repeat !important;
			position: center !important;
		}

		height: 100px;
		width: 100px;
	}

	.previewImg {
		background: url("/assets/img/iconSvg/dashboard.png");
	}

	.toolsimg {
		background: url("/assets/img/iconSvg/tools.png");
	}

	.ediLbl,
	.editoLbl {
		color: $common-color !important;
	}

	.iconForm,
	.iconToForm {
		color: $common-color !important;
	}

	.tileContainer.tile0 {
		background-color: $primary-color !important;
	}

	.tile-formbtn {
		background-color: unset !important;
		color: $common-color !important;
		border: 2px solid $common_base_color !important;

		&:hover {
			background-color: $commonbase-color !important;
			color: $hovertext-color !important;
			// border: 1px solid $commonbase-color !important;
		}
	}

	.tile1-formbtn {
		background-color: unset !important;
		color: $common-color !important;
		border: 2px solid $common_base_color !important;

		&:hover {
			background-color: $common_base_color !important;
			border: 1px solid $common_base_color !important;
		}
	}

	.NextconfirmLabels {
		font: normal normal normal 13px/24px Open Sans;
		letter-spacing: 0px;
		color: $common-color;
		margin-right: 15px;
		padding: 4px 10px;
		border: 1px solid $light-border !important;
		border-radius: 20px;
		margin-top: -7px;
		margin-left: 10px;
	}

	.bottom-formbtns {
		background-color: unset !important;
		border: 2px solid $common_base_color !important;
		color: $common-color !important;

		&:hover {
			background: $common_base_color !important;
			color: $hovertext-color !important;
		}
	}

	.darkHeaderIcon,
	.lightHeaderIcon {
		color: $common-color !important;
	}

	.errorOccured {
		border-color: #dc3545 !important;
	}

	.stepsinout-form.formly {
		#accordionExpand {
			.form-control {
				background-color: $primary-color !important;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
			}
		}
	}

	.form-control:disabled {
		background-color: #EEEEEE !important;
	}

	.fileFormat_toolTip {
		background-color: $primary-color !important;
		color: $secondary-color !important;
	}

	.tooltipHeader {
		color: $secondary-color !important;
	}

	.commonAchor {
		color: $secondary-color !important;
	}

	.serviceHead {
		color: $secondary-color !important;
	}

	.badge-disable-iom {
		color: $darkTextColor !important;
	}

	.sub-btn {
		border: 2px solid $common_base_color !important;
		color: #000 !important;
		font-weight: bold;
		&.freeButton {
			border-color: #333 !important;
			&:hover{
				background-color: transparent !important;
				border-color: #333 !important;
				color: $hovertext-color !important;
			}
		}
		&.futurePlanBtn{
			border-color: $common_base_color !important;
			background-color: $common_base_color !important;
			color: #000!important;
			&:hover{
				background-color: transparent !important;
				border-color: #333 !important;
				color: $hovertext-color !important;
			}
		}
		&.currentPlanBtn{
			border-color: $common_base_color !important;
			background-color: $common_base_color !important;
			color: #000!important;
			&:hover{
				background-color: transparent !important;
				border-color: #333 !important;
				color: $hovertext-color !important;
			}
		}

		&:hover {
			background: $commonbase-color !important;
			color: $secondaryFocus !important;
		}
	}

	#userChoiceSelect {

		.container,
		.durationDetailFields,
		.successNote {
			background-color: #eee !important;
		}

		.commonTimeData {
			background-color: $primary-color !important;
			border: 1px solid #EEEEEE !important;
		}

		.fieldHeader {
			color: $common-color !important;
		}

		// .durationrow,.durationEndRow{
		// 	background-color: $primary-color !important;
		// }
		.commonFormField {

			input,
			textarea {
				background-color: $primary-color !important;
				border: 1px solid #EEEEEE;
				color: $common-color !important;
			}
		}

		textarea {
			background-color: $primary-color !important;
			border: 1px solid #EEEEEE;
			color: $common-color !important;
		}
	}

	.filter-top {
		color: #0C1214 !important;
	}

	.settings-list {
		.settings-list-items-contents {
			background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
			border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		}
	}

	.settings-list-row {
		color: $darkTextColor;

		.list-group-item {
			background-color: transparent !important;

			&:hover {
				background: $commonbase-color !important;
				color: $hovertext-color !important;
			}
		}
	}

	.amount-div {
		color: #4C4F53 !important;
		background-color: #EDEDED !important;
	}

	.iom_btn {
		color: $common-color !important;
	}

	.modal-content {
		background: #F7F7F7 !important;
		border-radius: 10px;
	}

	.border {
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		border-radius: 10px;

		.editConfigSection {
			background-color: #F7F7F7 !important;
		}
	}

	.joyride-step__holder {
		background-color: map.get($whitetheme-colors, "bgcolor") !important;
		box-shadow: 0 0 0px 1px map.get($whitetheme-colors, "bgcolor") !important;

		.joyride-step__container {
			background-color: map.get($whitetheme-colors, "bgcolor") !important;
			box-shadow: 0 0 0px 1px map.get($whitetheme-colors, "bgcolor") !important;
		}

		.joyride-arrow__top {
			border-bottom: 11px solid map.get($whitetheme-colors, "bgcolor") !important;
		}

		.joyride-button {
			color: $darkTextColor !important;

			&:hover {
				background-color: $common_base_color !important;
				color: $darkTextColor !important;
			}
		}

		.joyride-step__title,
		.joyride-step__body {
			color: map.get($whitetheme-colors, "color") !important;
		}
	}

	.error_tab {
		color: $darkTextColor !important;
	}

	.error_text {
		color: $darkTextColor !important;
	}

	.bpHeader,
	.bc_sectionHead,
	.invitationLbl,
	.searchLbl,
	.netComID,
	.businessLbl,
	.comapnyLbl {
		color: $common-color !important;
	}

	.square,
	.bp_connectorStatus {
		background-color: #eeee !important;
	}

	.statusSection tr td {
		color: $common-color !important;
	}

	textarea.in,
	textarea.modal-in {
		color: $common-color !important;
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		border: 1px solid map.get($whitetheme-colors, "bordercolor");
	}

	.tour-title-input {
		color: $common-color !important;
		background-clip: padding-box;
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		border: 1px solid map.get($whitetheme-colors, "bordercolor");
	}

	.customIdSection,
	.payeeLabel,
	.arrowColor,
	.selectedHeadLbl {
		color: $common-color !important;
	}

	.modal-title-input {
		color: $common-color !important;
		background-clip: padding-box;
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		border: 1px solid map.get($whitetheme-colors, "bordercolor");
	}

	.successcmpimgDiv {
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
	}

	.defaultDot {
		background-color: #666;
	}

	.bp_connectorStatus {
		.fa-refresh {
			color: black;
		}
	}

	.statusSection tr td:not(:last-child) {
		border-right: 2px solid #666;
	}

	.greyDot {
		background-color: black !important;
		opacity: 0.5;
	}

	.successDiv {
		background-color: #F7F7F7 !important;
	}

	.successDiv-status {
		background-color: #F7F7F7 !important;
	}

	.successdeleteDiv {
		background-color: #F7F7F7 !important;
	}

	.theme-content-color {
		color: map.get($whitetheme-colors, "textcolor");
	}

	#wizard {
		// background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		// border: 1px solid #666666;
		color: map.get($whitetheme-colors, "textcolor");

		.line {
			.line-1 {
				background: #CCCCCC;
			}
		}

		.line11 {
			.line-1 {
				background: transparent;
			}
		}

		.wizard-items {
			border: 1px solid #CCCCCC;
			background: #fff 0% 0% no-repeat padding-box;

			&:hover {
				background: #ededed 0% 0% no-repeat padding-box;
			}

			.wizardDiv {
				border: 1px solid transparent;
				background: #cccccc 0% 0% no-repeat padding-box;

				span {
					color: #111;
				}
			}
		}

		.wizard-billeritem {
			border: 1px solid #CCCCCC;
			background: #fff 0% 0% no-repeat padding-box;

			.wizardDiv {
				border: 1px solid transparent;
				background: #cccccc 0% 0% no-repeat padding-box;

				span {
					color: #111;
				}
			}
		}

		b.badges {
			// border: 1px solid $common_base_color;
			box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
		}
	}

	/* #wizard1{
		// background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		// border: 1px solid #666666;
		color: map.get($whitetheme-colors, "textcolor");
		.line-1{
			background: #CCCCCC;
		}
		.wizard-items{
			border: 1px solid #CCCCCC;
			background: #fff 0% 0% no-repeat padding-box;
			&:hover{
				background: #ededed 0% 0% no-repeat padding-box;
			}
			.wizardDiv{
				border: 1px solid transparent;
				background: #cccccc 0% 0% no-repeat padding-box;
				span{
					color: #111;
				}
			}
		}
		.wizard-billeritem{
			border: 1px solid #CCCCCC;
			background: #fff 0% 0% no-repeat padding-box;

			.wizardDiv{
				border: 1px solid transparent;
				background: #cccccc 0% 0% no-repeat padding-box;
				span{
					color: #111;
				}
			}
		}
		b.badges{
			border: 1px solid #fff;
			box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
		}
	} */
	.wizardDiv {
		border: 1px solid #666666;
	}

	.accordianClass {
		color: map.get($whitetheme-colors, "textcolor");
	}

	b.badges {
		background-color: #EDEDED;
	}

	.warning-text {
		color: map.get($whitetheme-colors, "color") !important;
	}

	nav ul ul {
		li {
			background-color: $header-bgcolor;

			a {
				font-size: 16px !important;
				text-shadow: none !important;
				padding-left: 0px !important;
				padding-top: 7px !important;
				padding-bottom: 14px !important;

				&:focus,
				&:hover {
					color: #202427 !important;
					background: $common-base-color !important;
				}
			}
		}
	}

	#left-panel {
		padding-right: 0px !important;
		padding-left: 0px !important;
		padding-bottom: 0px !important;

		// padding-top: 10px !important;
		nav {
			ul.edi {
				li {
					border-bottom: 1px solid $common-base-color;

					&:hover {
						background: $common-base-color !important;
						color: $common-color !important;
						border: 1px solid #3B3B3B33;
						
						a{
							color: $primary-color !important;
						}
					}
				}

				li.active {
					background-color: $common-base-color !important;

					a {
						color: $primary-color !important;
					}

					ul {
						li {
							color: $primary-color !important;

							&:hover {
								a {
									color: $hovertext-color !important;
									background: $common-base-color !important;
								}
							}

							a {
								color: $primary-color !important;
							}

						}

						li.active {
							a {
								color: $primary-color !important;
							}
						}
					}
				}
			}
		}
	}

	nav {
		ul {
			li.active {
				a {
					font-weight: 400;

					&:before {
						color: #202427;
					}
				}
			}
		}

		ul {
			li {
				a {
					color: #4C4F53 !important;
					padding: 12px 17px 12px 16px !important;
					font-size: 16px !important;

					i {
						font-size: 17px !important;
						vertical-align: 0 !important;
					}
				}
			}

			li.open {
				a {
					color: $primary-color !important;
					font-weight: 400;
					;


				}

				&:focus,
				&:hover {
					color: $hovertext-color !important;
					background: $common-base-color !important;
				}
			}

			li.open a b {
				color: $primary-color !important;
			}

			li.open.active a b {
				color: #202427 !important;
			}

			li.open:hover>a {
				color: #202427 !important;
			}

			li.open:hover>a b {
				color: #202427 !important;
			}
		}
	}

	.businessField {
		color: #495057 !important;
	}

	.dropzonbox {
		background-color: #F7F7F7 !important;
		border: 1px solid #dadce0;
	}

	.checkmark {
		border: 2px solid #666666;

	}

	.interConnPopupForm {
		label {
			font-size: 14px !important;
		}

		.form-control {
			// border: 1px solid #666666;
			background-color: #fff !important;
			border-radius: 5px !important;
		}

		.invalid-feedback {
			font-size: 12px !important;
		}
	}

	.ediAccountSection {
		border-radius: 10px;
		margin-bottom: 20px;

		.userDash_tileText {
			font-size: 13px;
			font-weight: 400px;
		}

		.userDash_tileHeadgreen {
			font-size: 13px;
			color: #212529 !important;
		}

		.userDash_tileCount {
			font-size: 17px;
			color: #212529 !important;
			.from-text{
				color: #212529 !important;
			}
		}

		.tile-formbtn {
			border-radius: 20px;
			padding: 3px 15px;
			font-size: 13px;
		}

		.userDash_tileHead {
			color: #212529 !important;
		}
	}

	.billerCommonDetails:hover {
		background-color: unset !important;
	}

	.ebillFormFormly,
	.disableForm,
	.ebillForm {
		formly-field-select select option {
			background-color: unset !important;
		}

		.form-control {
			background: #fff !important;
			border: 1px solid #6666 !important;
			color: #000;
			height: 40px !important;
			border-radius: 6px !important;
			font-size: 13px;
		}

	}

	.sectorLbl {
		color: #000 !important;
	}

	.companySelect,
	.company-select {
		.ng-select-container {
			border-radius: 10px !important;
			// background-color: #fff !important;
			color: #000 !important;
			height: 42px !important;
			border: none;

			.ng-value-container {
				.ng-placeholder {
					font-size: 13px;
				}

				.ng-value {
					font-size: 13px;
				}
			}
		}

		.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
			font-size: 13px !important;
		}
	}

	.biller_tabSection {
		.nav-item {
			width: auto !important;

			a {
				margin-right: unset !important;
				background-color: unset !important;
				color: #000 !important;
				border-bottom: unset !important;
				border-left-color: #6666 !important;
				border-right-color: #6666 !important;
				border-top-color: #6666 !important;
			}
		}

	}

	.ebiller_tabSection {

		// width: 100% !important;
		.nav-item {

			// width: 10% !important;
			a {
				color: #000 !important;
				border-color: #dee2e6 !important;
				border-left-color: #dee2e6 !important;
				border-right-color: #dee2e6 !important;
				border-top-color: #dee2e6 !important;
			}
		}
	}

	.communicationPanel {
		.card-header {
			color: #000;
		}
	}

	.docLbl {
		width: 100%;
		color: #000;
		font-size: 14px;
	}

	.docInput {
		width: 100%;
		margin-right: 10px;
		border-radius: 10px !important;
		background-color: #EEEEEE !important // border: unset !important;
	}

	.invoiceTbl,
	.subscribersTbl {
		.sorting_disabled {
			background-color: #CCCCCC !important;
		}
	}

	.nav-tabs {
		border-bottom: 1px solid #dee2e6;
		border-color: 1px solid #dee2e6 !important;
	}

	//  .nav-tabs .nav-link.active{
	// 	border-color: 1px solid #dee2e6 !important; 
	//  }

	.communicationPanel {
		.communicationBodyWrap {
			border: 1px solid #dee2e6;
		}

	}

	.filelabel:hover i,
	.filelabel:hover .title {
		color: #000 !important;
	}

	.panelSector .ng-select .ng-select-container {
		background-color: transparent !important;
		color: #000 !important;
		border: 1px solid #6666 !important;

	}

	.detail_save_btn {
		color: #000
	}

	.card-body {
		background-color: #fff;
	}

	.billTabSection {
		border: 1px solid $light-border;
	}

	.emailMessage,
	.ediEmailMessage {
		border: 1px solid #CCCCCC !important;
	}

	// .fieldSection{
	// 	// background-color: #CCCCCC !important;
	// }
	#header.colorChangeTest .upgradebtn {
		color: #000 !important;
	}

	.internav-tabs>li>a {
		color: #000 !important;
		// background-color: #CCCCCC !important;
	}

	.nav-tabs>li.active>a.display-border,
	.nav-tabs>li.active>a.display-border:focus,
	.nav-tabs>li.active>a.display-border:hover {
		background-color: #fff !important;
		border-top: 2px solid $common_base_color !important;
		border-left: 2px solid $common_base_color !important;
		border-right: 2px solid $common_base_color !important;
		color: #000;
	}

	.nav-tabs.invoice-tab {
		>li.active {
			border-bottom: none !important;
			box-shadow: 0 -2px 0 $common_base_color !important;

			&:hover {
				background-color: transparent !important;
			}

			>a.invoice-tabhead {
				background-color: $primary-color !important;
				color: $common-color !important;

				&:focus {
					background-color: $primary-color !important;
					color: $common-color !important;
				}

				&:hover {
					background-color: $primary-color !important;
					color: $hovertext-color !important;
				}
			}
		}

		>li {
			border-bottom: 1px solid $light-border !important;
			border-right: 1px solid $light-border !important;

			&:last-child {
				border-right: none !important;
			}

			&:hover {
				border-bottom: 1px solid $light-border !important;
				border-top: none !important;
				background-color: $light-border !important;
			}
		}

		>li {
			>a {

				// box-shadow: 0 -2px 0 map.get($whitetheme-colors, "bordercolor") !important;
				&:hover {
					color: $hovertext-color !important;
				}
			}
		}
	}

	.invoice-tab-header {
		border: 1px solid map.get($whitetheme-colors, "bgcolor") !important;
		border-bottom: none !important;
		border-left: none !important;
		border-right: none !important;
	}

	.setCircleTest {
		color: #F1DA91;
		font-size: 10px !important;
	}

	.tooltipA .tooltiptext {
		background: map.get($whitetheme-colors, "commonbgcolor") 0% 0% no-repeat padding-box;
		color: map.get($whitetheme-colors, "color");
		border: 1px solid map.get($whitetheme-colors, "bgcolor");
	}

	.nav_btn {
		color: #4c4f53 !important;

		&:hover {
			color: $hovertext-color !important;
		}
	}

	.fieldSection {
		border: 1px solid #CCCCCC !important;

	}

	.descriptionSection {
		border: 1px solid #CCCCCC !important;

		.ds-1 {
			border-bottom: 1px solid #CCCCCC !important;
		}

		.connectorlbl {
			color: #000 !important;
		}

		.businessFields {
			label {
				color: #000 !important;
			}
		}
	}

	table.invoice-details {
		tr {

			//   border-bottom: 1px solid $tabsborder !important;
			>td {

				// border-bottom: 1px solid $tabsborder !important;
				&:first-child {
					//   border-right: 1px solid $tabsborder !important;
				}
			}
		}
	}

	.serviceForm {

		formly-field,
		formly-wrapper-form-field {
			label {
				color: #000 !important;
			}

			input {
				border-top-right-radius: 0px !important;
				border-bottom-right-radius: 0px !important;
			}
		}
	}

	.connectorContainer {
		.serviceLbl {
			color: #000 !important;
		}
	}

	.filePattern {
		color: #000 !important;
	}

	.serviceBody {
		label {
			color: #000;
		}

		.inputSection,
		.selectSection {
			label {
				color: #000 !important;
			}
		}
	}

	.addonItem {
		color: #000 !important;
	}


	.connector-form {

		.inputSection,
		.selectSection {
			.form-control {
				background-color: #EEEEEE !important;
				border: 1px solid #EEEEEE !important;
			}
		}
	}

	.searchcompanyName {
		color: #000 !important;
	}

	.connectorlblleft,
	.connectorlbl {
		color: #000 !important;
	}

	.borderHead {
		border-radius: 8px;
		padding: 1rem;
		border: 1px solid $light-border !important;
	}

	.styleBackground {
		border: 1px solid $light-border !important;
	}

	.businessField,
	.bp_connections,
	.bp_connectorStatus {
		border: 1px solid $light-border !important;
	}

	.border-style {
		border: 1px solid $light-border !important;
	}

	.app-tut {
		background-color: #ededed !important;
	}

	.app-tut.active {
		background-color: $whiteborder-color !important;
	}

	.app-tut {
		background-color: $content-bg-color;

		&:not(.nohover) {
			&:hover {
				background-color: $whiteborder-color !important;
			}
		}
	}

	.modal-footer {
		border-top: none !important;
	}

	#statusChangeModal {
		.fieldSection {
			border: none !important;
		}
	}

	.approveBtn {
		color: $common-color;
	}

	.company-select {
		border: none;
		border-radius: 10px;
		background-color: #EEEEEE !important;
	}

	.fuelux .wizard ul li .chevron:before {
		position: absolute;
		top: -24px;
		right: 1px;
		display: block;
		border: 24px solid transparent;
		border-right: 0;
		border-left: 14px solid #ededed;
		content: "";
		/* background: #fff; */

	}

	.fuelux .wizard ul li {
		position: relative;
		float: left;
		height: 46px;
		padding: 0 20px 0 30px;
		margin: 0;
		font-size: 16px;
		line-height: 46px;
		color: #000;
		;
		cursor: default;
		background: #ededed;
	}

	.fuelux .wizard ul li.active {
		color: #000;
		background: $common_base_color;
	}

	.fuelux .wizard ul li.active .chevron:before {
		border-left: 14px solid $common_base_color;
	}

	.snd-rcv-div.active {
		background-color: $common_base_color !important;
	}

	.snd-rcv-div {
		background-color: #fff;
		border: 1px solid$common_base_color;

		&:hover {
			background-color: $common_base_color !important;
		}
	}

	table.bulkTable {
		border: 1px solid $whiteborder-color !important;
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		border-radius: 5px;
		margin-top: 6px !important;
	}

	.trapezium {
		border-bottom: 34px solid $whiteborder-color;
	}

	.step {
		border: 2px solid $whiteborder-color !important;
		background: $whiteborder-color !important;
	}

	.step.active {
		border: 2px solid $common_base_color !important;
		background: $common_base_color !important;
	}

	.bulk-popup {
		background: #fff !important;
	}

	.interConnPopupForm .form-control.is-invalid {
		border: 1px solid #ced4da;
	}

	.archiveImport {
		background: #ffffff;
	}
	/* button#bot2-Msg1 {
		color: #000 !important;
	} */
	.subscriptions-pdf{
		.sub-items{
			border: 1px solid $whiteborder-color;
		}
	}
   
	/* price - calculation */

	.price_calcultation_form {
		.multiselect-dropdown {
			.dropdown-btn{
				background-color: $primary-color !important;
				border: 1px solid $whiteborder-color !important;
			}
			.filter-textbox {
				input {
					background: $primary-color !important;
				}
			}

			.multiselect-item-checkbox {
				border-bottom: none !important;
				input {
					+ {
						div {
							color: map.get($whitetheme-colors, "color");
							&:before {
								color: map.get($whitetheme-colors, "color") !important;
								border: 2px solid map.get($whitetheme-colors, "color") !important;
							}

							&:focus {
								color: map.get($whitetheme-colors, "color") !important;
								border: 2px solid map.get($whitetheme-colors, "color") !important;
							}

							&:after {
								border-color: map.get($whitetheme-colors, "color") !important;
							}
						}
					}
				}
			}
		}
	}
	/* price - calculation */

	.plan-title{
		color: #212529 !important;
	}
	.target-highlight,.target-highlightPdf {
		// -webkit-animation: target-fade-light 3s 1;
		// -moz-animation: target-fade-light 3s 1;
		border-color: $common_base_color !important;
	}
	.cardimg{
		filter: brightness(100) invert(1);
	}
	.inv-img{
		content: url('../img/invoice.svg') !important;
	}
	.payment-detail {
		width: 100%;
		max-width: 1010px;
		background-color: map.get($whitetheme-colors, "commonbgcolor");
		margin: auto;
		padding: 10px 10px 6px 10px;
	}
	.underline {
		border-color: map.get($whitetheme-colors, "bordercolor");
	}

	.plan-stat {
		border-left: 1px solid map.get($whitetheme-colors, "bordercolor");
		&:first-of-type {
			border-bottom: 1px solid map.get($whitetheme-colors, "bordercolor");
		}
	}
	.durationDetailFields{
		background-color: transparent !important;
	}
	.fileuploadform-form {
		.input-group-append {
			.input-group-text {
				background-color:#fff;
				color: #495057;
				border: 1px solid #ced4da;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;

				
			}
		}
	}
	.module{
		.module-name {
			color: #202427 !important;
		}
	}
	.company-label{
		color: #4C4F53 !important;
	}
	.iom_btn {
		&:hover {
			color: $hovertext-color !important;
		}
	}
	.crossBtn{
		color: $commonbase-color !important;
	}
	.footer-menu {
		color: $common-color !important
	}
	.ac_ConnectionSection{
		.ac_div {
			color: $common-color !important;
		}
		.ac_typeDiv{
			color: $common-color !important;
			.common_detail{
				color: $common-color !important;
			}
		}
		.fillGreen{
			color: $primary-color !important;
		}
	}
	.chatIcon{
		filter: brightness(0) invert(0) !important;
	}
	table.dataTable tbody tr.selected {
		color: $primary-color !important;
	}
	.fillGreen{
		color: $primary-color !important;
	}
	#overlay {
		background-color: rgba(200, 200, 200, 0.4);
	}
	.ng-select .ng-select-container .ng-value-container .ng-placeholder {
		color: #202124 !important;
	}
	#transaction-header .form-control::placeholder{
		color: #202124 !important;
	}
	.companyTooltip {
		background: map.get($whitetheme-colors, "commonbgcolor") 0% 0% no-repeat padding-box;
		border: 1px solid map.get($whitetheme-colors, "bgcolor");
		color: map.get($whitetheme-colors, "color");
	}
	.checkmark {
		border: 2px solid #666666
	}
	.checkbox-container .checkmark:after {
		left: 7px;
		top: 1px;
		width: 8px;
		height: 16px;
		border: solid #959595;
		border-width: 0 3px 3px 0;
		transform: rotate(45deg);
	}
	.packageplanBtn {
		border-radius: 2px !important;
		
		transition: background-color 0.3s ease;
		padding: 8px 35px;
		font-size: 13px;
		color: $common-color;
		&.active {
		  color: #fff;
		  background: $common_base_color;
		  border: solid $common_base_color;
		}
	}
}


/*
---------------------------------------------------------------------------------------
    End   White theme style
---------------------------------------------------------------------------------------
*/
@media (max-width: 767px) {
	.jarviswidget header h2 {
		width: auto !important;
	}

	.right-side {
		flex: 0 0 0% !important;
	}
}

.tabgrid {
	margin-bottom: 1px !important
}

@media (max-width: 767px) {
	body:not(.menu-on-top).desktop-detected {
		min-height: 1000px !important;
		overflow: unset !important;
	}
}

@media (max-width: 384px) {
	#left-panel {
		padding-top: 198px !important;
		top: 0px !important;
	}
}

@media (min-width: 385px) and (max-width: 706px) {
	#left-panel {
		padding-top: 153px !important;
		top: 0px !important;
	}
}

@media (min-width: 1131px) {
	#left-panel {
		padding-top: 0px !important;
		top: 49px !important;
	}

}
// @media (min-width: 707px) and (max-width: 1130px) {
// 	#left-panel {
// 		top: 0px !important;
// 		padding-top: 99px !important;
// 	}
// }

@media (min-width: 989px) and (max-width: 1440px) {
	#content {
		overflow-y: scroll !important;
	}
}

//   @media (min-width: 579px) and (max-width: 859px)   {
// 	#left-panel {
// 	  padding-top: 190px !important;
// 	}
//   }
//   @media (min-width: 796px) and (max-width: 1218px) {
// 	#left-panel {
// 	  padding-top: 49px !important;
// 	}
//   }
//   @media (min-width: 980px) and (max-width: 1240px) {
// 	#left-panel {
// 	  position: absolute;
// 	  top: 105px;
// 	  z-index: 904;
// 	  padding-top: 10px;
// 	}
//   }
//   @media(min-width: 980px) and (max-width: 1108px) {
// 	#left-panel {
// 		padding-top: 108px !important;
// 	}
// }
//   @media (min-width: 1052px){
// 	#header {
// 	  height: 49px !important;
// 	}
// 	#left-panel{
// 	  padding-top: 0px !important;
// 	}
//   }


//   @media(min-width: 1196px) and (max-width: 1364px) {
// 	#left-panel {
// 		top: 98px !important;
// 	}
//   }
//   @media(min-width: 1108px) and (max-width: 1201px) {
// 	#left-panel {
// 		padding-top: 54px !important;
// 	}
// }

.field_without_addon {
    .form-control {
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }
}
// for file format copy
.selectedFormats {
	position: relative;
	border-radius: 10px !important;
	height: 40px;
	padding: 0 44px 0 10px;
  }
  
  .formatRow {
	margin: 0px 10px 0px 0px;
  }
  
  .copyIcon {
	position: absolute;
	font-size: 16px;
	right: 20px;
	top: 20px;
  }
  .fileFormat_toolTip {
	//   visibility:hidden;
	width: 100%;
	//   max-width: 270px;
	//   height: 130px;
	//   background-color: black;
	color: #fff;
	//   text-align: center;
	padding: 5px 35px;
	//   border-radius: 20px;
	//   position: absolute;
	//   z-index: 1;
	//   top: -65px;
	//   right: -295px;
	overflow-y: scroll;
  }
  
  .fileFormat_toolTip::-webkit-scrollbar {
	display: none !important;
  }
  
  .fileFormat_toolTip {
	-ms-overflow-style: none !important;
	/* IE and Edge */
	scrollbar-width: none !important;
	/* Firefox */
  }
  
  .fileFormatContent {
	border-radius: 10px !important;
	border: none !important;
  
	.modal-header {
	  border-bottom: unset !important;
	}
  
	.modal-footer {
	  border-top: unset !important;
	  padding: 10px 12px 10px 12px;
	}
  }
  
  .fileFormatfooter {
	border-top: unset !important;
  }

  .commonSeperator {
	padding: 0 10px 0 10px;
  }
  
  .commonAchor {
	width: 30%;
	color: $common_base_color !important;
  }
  
  .foramatDetail {
	width: 64%;
  }
  
.commonFileFormat {
	font-size: 14px;
  }
  #main {
    margin-left: 220px;
    padding: 0;
    min-height: 500px;
    position: relative !important;
}
//----------------responsive view------------------------------------
@media (max-width: 979px) {
	#main {
		margin-left: 0px !important;
		padding: 0;
		padding-bottom: 52px;
		min-height: 500px;
		position: absolute !important;
	}
}
@media (max-width: 796px) {
	span.language-text {
		display: none !important;
	}
	.menu-item.languageCustom {
		width: 30px !important;
		text-align: center !important;
	}
}
li.interconnectBtnAdd.active {
    border-top-left-radius: 8px !important;
}
@media (max-width: 576px) {
	#content {
		max-height: fit-content !important;
	}
}

@media (max-width: 992px) {
	#content {
		max-height: fit-content !important;
	}
}
@media (max-width: 505px) {
	.btnAlign {
		margin-bottom: 10px !important;
		width: 100% !important;
	}
}

@media (max-height: 650px) {
 ul.navList {
    height: 180px !important;
    // overflow: auto !important;
 }
}
.filter-top .link  {
    color: #000000 !important;
}
@media (max-width: 450px) {
	.amount-div {
	height: 150px;
	}
}
@media (max-width: 1323px) {
	span.header_icon_text_custom {
		display: none;
	}
}
@media (min-width: 374px) {
	.settings-list {
		right: -5px;
	}
}
.btnediCheck.align-items-end {
    margin-top: 10px;
}
/* .ng-select .ng-clear-wrapper {
    display: none !important;
} */
.joyride-backdrop.backdrop-left {
    width: 0px !important;
	background-color: #0d0e0e !important;
}
.backdrop-target {
	width: 0px  !important;
}
p.errorTooltip {
    z-index: 1 !important;
}

// .infoIcon:active {
//     background-color: #101112 !important;
// }
//-------------------------------------------------------------------



@-webkit-keyframes target-fade-dark {
    0% { background-color: rgba(255,255,153,0); }
    100% { background-color: rgba(182,235,0,1); }
}
@-moz-keyframes target-fade-dark {
    0% { background-color: rgba(255,255,153,0); }
    100% { background-color: rgba(182,235,0,1); }
}
.setCirclehold {
    color: orange;
    font-size: 10px !important;
}
.smart-style-2 .MessageBoxButtonSection button {
    border-radius: 15px;
    background: transparent;
    border: 2px solid $common_base_color !important;
    font-size: 13px;
    padding: 3px 15px;
    color: #050505 !important;
    font-weight: 400 !important;
}
.accounts-repeat-section{
	display: flex !important;
    align-items: flex-start !important;
}
.bulk-form label {
    font-size: 14px !important;
}
.smart-style-1 .form-control {
    font-size: 14px !important;
}
.smart-style-2 .form-control {
    font-size: 14px !important;
}
.jarviswidget{
	position: static !important;
}

.pdfxm-service-div::-webkit-scrollbar {
	display: none;
}

.pdfxm-service-div {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}
.smart-style-1 #logo-group {
	background-color: $logo_color !important;
}
.smart-style-2 #logo-group {
	background-color: $logo_color !important;
}

.epost
	{
		.epostlogoBackGround{
			background-repeat: no-repeat;
			background-size: 100%;
			height: 140px !important;
			width: 62px !important;
			display: block;
			margin-top: 1px !important;
			margin-left: 82px !important;
		}	
	
	.green-label{
		color: $common_base_color !important;
	}
	.bg-color-red{
		background-color: $common_base_color !important;
	}
	// .infoFieldIcon, .infoServiceFieldIcon {    
	// 	filter: sepia(6) saturate(9) brightness(.9) hue-rotate(345deg);
	// }
	// .upgrade-image {    
	// 	filter: sepia(6) saturate(5) brightness(1.0) hue-rotate(358deg);
	// }
	.smart-style-2 #left-panel nav ul.edi li.active a {
		color: #000 !important;
	}
	
}

.sender_ellipsis_col {
    white-space: nowrap;
    width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
.ellipsis_col {
    white-space: nowrap; 
	width: calc(100% - 30px); 
	overflow: hidden;
	text-overflow: ellipsis; 
	display: block;
}
.transaction_ellipse{
    white-space: nowrap; 
	width: calc(100% - 50px); 
	overflow: hidden;
	text-overflow: ellipsis; 
	display: block;
}
	
